<template>
    <div>
        <!-- Generator: Adobe Illustrator 27.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 90 300 300"
            style="enable-background: new 0 90 300 300"
            xml:space="preserve"
        >
            <path
                class="st6"
                d="M0,256c0-8.8,7.2-16,16-16h288c8.8,0,16,7.2,16,16c0,8.8-7.2,16-16,16H16C7.2,272,0,264.8,0,256z"
            />
        </svg>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'HyphenIcon',
    });
</script>

<style type="text/css">
    .st6 {
        fill: #bcbec0;
    }
</style>
