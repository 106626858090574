// <!-- UTILITIES -->
import {
    Metric,
    Measure,
    MeasuresOfCenter,
    MeasuresOfDistribution,
} from '@/utils/measures';

// <!-- TYPES -->
/**
 * @typedef {Pick<MeasuresOfCenter<'DC'>, 'min' | 'max'>
 * & Pick<MeasuresOfDistribution<'DC'>, 'span'>
 * } DimensionalChangeMeasureRecord
 */

/** @implements {DimensionalChangeMeasureRecord} */
export class MeasuresOfDimensionalChange {
    /**
     * Define resource measure class instance.
     * @param {Partial<DimensionalChangeMeasureRecord>} [attrs]
     */
    constructor(attrs) {
        this.metric = Metric.DC;
        this.min = attrs?.min?.clone() ?? new Measure({ type: 'min' });
        this.max = attrs?.max?.clone() ?? new Measure({ type: 'max' });
        this.span = attrs?.span?.clone() ?? new Measure({ type: 'span' });
    }
}
