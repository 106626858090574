// <!-- COMPOSABLES -->
import {
    useAgGridAPI,
    useAgGridColumnDefs,
    useAgGridRowData,
    useAgGridDOMLayout,
    useAgGridPagination,
    useGridReadyEvent,
    useColumnResizedEvent,
    useRowDataChangedEvent,
} from './grid';

// <!-- TYPES -->

/**
 * @template [TData=any]
 * @template {string} [TFields=string]
 * @template {{ [K in TFields]: { field: K } & Omit<AgGrid.ColumnDef<TData>, 'field'> }} [TSchema={ [K in TFields]: { field: K } & Omit<AgGrid.ColumnDef<TData>, 'field'> }]
 * @typedef UseAgGridOptions
 * @prop {TData[] | Vue.Ref<TData[]>} [data] Possibly reactive initial data.
 * @prop {import('./grid/useAgGridPagination').UseAgGridPaginationOptions} [pagination]
 * @prop {string | Vue.Ref<string>} [domLayout] Possibly reactive value controlling DOM layout of the grid.
 * @prop {AgGrid.ColumnDef<TData>} [defaultColumnDef] Default column definition.
 * @prop {Readonly<TSchema>} [columnSchema] Column schema that will provide column definitions, by fieldname.
 */

/**
 * @template [TData=any]
 * @param {UseAgGridOptions<TData>} [props]
 */
export const useAgGrid = (props = {}) => {
    // STATE

    const state = useAgGridRowData(props);
    const api = useAgGridAPI(state);
    const { domLayout } = useAgGridDOMLayout(props);
    const { pagination, paginationPageSize } = useAgGridPagination(
        props.pagination
    );

    // METHODS

    const { onGridReady, readyGrid } = useGridReadyEvent();
    const { onColumnResized, resizeColumn } = useColumnResizedEvent();
    const { onRowDataChanged, changeRowData } = useRowDataChangedEvent();
    const { getColumnDefs, getDefaultColumnDef } = useAgGridColumnDefs({
        columnSchema: props.columnSchema ?? {},
        defaultColumnDef: props.defaultColumnDef ?? {
            lockPosition: true,
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            minWidth: 150,
            flex: 1,
            cellClass: 'break-normal',
        },
    });

    // LIFECYCLE

    // Handle the grid ready event.
    onGridReady((params) => {
        console.log(`[grid::ready]`, params);
        // Assign grid api.
        api.grid.value = params.api;
        api.column.value = params.columnApi;
    });

    // Handle column resized event.
    onColumnResized((params) => {
        console.log(`[grid::column-resized]`, params);
        params.api.refreshCells();
    });

    // Handle row data change event.
    onRowDataChanged((params) => {
        console.log(`[grid::row-data-changed]`, params);
        if (!!params.data) {
            state.set(params.data);
        } else {
            state.clear();
        }
    });

    // EXPOSE

    return {
        // STATE
        api,
        pagination,
        paginationPageSize,
        domLayout,
        size: state.size,
        rowData: state.rowData,
        isEmpty: state.isEmpty,
        // QUERIES
        getColumnDefs,
        getDefaultColumnDef,
        mapRowData: state.map,
        sortRowData: state.sort,
        // COMMANDS
        setRowData: state.set,
        clearRowData: state.clear,
        // TRIGGERS
        readyGrid,
        resizeColumn,
        changeRowData,
        // LIFECYCLE
        onGridReady,
        onColumnResized,
        onRowDataChanged,
    };
};

// <!-- DEFAULT -->
export default useAgGrid;
