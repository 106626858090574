// <!-- API -->
import { computed, ref, watch } from 'vue';
import {
    computedEager,
    createEventHook,
    makeDestructurable,
} from '@vueuse/core';
import locations, {
    fetchLocationById,
    fetchLocations,
} from '@/api/v1/accounts/locations';
import {
    fetchWeatherStationById,
    fetchWeatherStations,
} from '@/api/v1/accounts/weather';
import { fetchLocationStats, fetchWeatherStationStats } from '@/api/v1/legacy';
import { fetchNARAStandardMetrics } from '@/api/v1/accounts';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useNARAFeature } from '@/utils/features/';
import { useQueries, useQuery, useQueryClient } from '@tanstack/vue-query';

// // <!-- QUERIES -->
// import useAccountLocations from '@/query/useAccountLocations';
// import useAccountLocationsMetrics from '@/query/useAccountLocationsMetrics';
// import useLocationStatistics from '@/query/useLocationStatistics';
// import useAccountWeatherStations from '@/query/useAccountWeatherStations';
// import useWeatherStationStatistics from '@/query/useWeatherStationStatistics';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';
import { Node, NodeSelector, NodeState } from '@/utils/tree';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
    useLocationSort,
    useCaseInsensitiveLocaleCompare,
} from '@/utils/sorters';

// <!-- TYPES -->
/** @typedef {import('vuex').Store<ECNBState>} Store */
import { ECNBState } from '@/store/types/ECNBStore';
import { LocationResource } from '@/models/v1/locations/Location';
/** @typedef {import('@/models/v1/locations/LocationStats').LocationStatsResource} LocationStatisticsResource */
/** @typedef {import('@/models/v1/locations/LocationStats').LocationStatsResource} WeatherStationStatisticsResource */

/**
 * Resource statistics data structure.
 *
 * @typedef {Object} LocationStatistics
 * @prop {number} id
 * @prop {'Location'} type
 * @prop {LocationResource} resource
 * @prop {LocationStatisticsResource} statistics
 *
 * @typedef {Object} WeatherStationStatistics
 * @prop {string} id
 * @prop {'Weather Station'} type
 * @prop {import('./useAnalysisChart').WeatherStationResource} resource
 * @prop {WeatherStationStatisticsResource} statistics
 *
 * @typedef {LocationStatistics | WeatherStationStatistics} ResourceStatistics
 */

/**
 * @typedef UseStatisticsOptions
 * @prop {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [store] Track an existing store reference, if it was already provisioned.
 * @prop {import('@tanstack/vue-query').QueryClient} [queryClient] Track an existing store reference, if it was already provisioned.
 */

/**
 * Describes the internal event hooks, that may or may not be exposed from this composable.
 * @typedef {import('@vueuse/core').EventHook<ResourceStatistics[]>} ResourceStatisticsCollectionEventHook
 * @typedef {import('@vueuse/core').EventHook<unknown>} ErrorEventHook
 */

/**
 * @typedef UseStatisticsReturn
 * @prop {Vue.ComputedRef<ResourceStatistics[]>} data Computed reference collection of data.
 * @prop {Vue.Ref<unknown>} error Current stored error.
 * @prop {Vue.ComputedRef<boolean>} isIdle Is the user selection empty?
 * @prop {Vue.ComputedRef<boolean>} isEmpty Is computed statistics collection empty?
 * @prop {Vue.ComputedRef<boolean>} isLoading Is the resource data loading?
 * @prop {Vue.ComputedRef<boolean>} isError Has there been an error?
 * @prop {ResourceStatisticsCollectionEventHook['on']} onResult Fires when all queries resolve, returning the resulting statistics data.
 * @prop {ErrorEventHook['on']} onError Fires when an error occurs.
 */

/**
 * Define the composable.
 * @param {UseStatisticsOptions} [props] @see {@link UseStatisticsOptions}
 * returns {UseStatisticsReturn} @see {@link UseStatisticsReturn}
 */
export const useStatistics = (props = {}) => {
    // EVENTS
    const handleError = createEventHook();

    // SERVICES
    const store = props?.store ?? useStore();
    const queryClient = useQueryClient();
    const { isNARAEnabled } = useNARAFeature();

    // METHODS

    // Define sorting methods.
    const sortByLocaleName = useCaseInsensitiveLocaleCompare();
    const sortByLocationPath = useLocationSort();

    /**
     * Check if the location is 'unassigned'.
     * @param {LocationStatistics['resource']} resource
     */
    const isLocationResourceUnassigned = (resource) => {
        const hasHierarchyID =
            resource.hierarchyId != null &&
            resource.hierarchyId > 0 &&
            Number.isFinite(resource.hierarchyId);
        const isUnassigned = !hasHierarchyID;
        return isUnassigned == true;
    };

    /**
     * Check if location is within the current date range.
     * @param {LocationStatistics['resource']} resource
     */
    const isLocationResourceWithinDateRange = (resource) => {
        const filterRange = daterange.value;

        const normalizeStartDate = (value) => {
            return is.nullOrUndefined(value) || is.nan(value)
                ? NaN
                : startOfDay(value);
        };
        const normalizeEndDate = (value) => {
            return is.nullOrUndefined(value) || is.nan(value)
                ? NaN
                : endOfDay(value);
        };
        const parseDate = (value) =>
            is.nullOrUndefined(value) || is.emptyStringOrWhitespace(value)
                ? NaN
                : DateTimeISO.parse(value);

        const start = normalizeStartDate(filterRange.start);
        const end = normalizeEndDate(filterRange.end);
        const min = normalizeStartDate(parseDate(resource?.minDate));
        const max = normalizeEndDate(parseDate(resource?.maxDate));

        // FILTER
        const isMinDateAfterEndBound = isAfter(min, end);
        const isMaxDateBeforeStartBound = isBefore(max, start);

        if (is.nan(min) || is.nan(max)) {
            return true;
        }

        return (
            (is.nan(start) || !isMaxDateBeforeStartBound) &&
            (is.nan(end) || !isMinDateAfterEndBound)
        );
    };

    /**
     * Check if the weather station is within the current date range. Always returns `true`.
     * @param {WeatherStationStatistics['resource']} resource
     */
    const isWeatherStationResourceWithinDateRange = (resource) => true;

    /** Get the checked location tree nodes. */
    const getCheckedLocationNodes = () => {
        const filter = store.state.analysis.filters.locations.tree;
        const nodes = Object.values(filter.nodes);
        return nodes
            .filter(Node.isLocationNode)
            .filter((n) => NodeState.isChecked(n.state));
    };

    /** Get the checked weather station tree nodes. */
    const getCheckedWeatherStationNodes = () => {
        const filter = store.state.analysis.filters.stations.tree;
        const nodes = Object.values(filter.nodes);
        return nodes
            .filter(Node.isWeatherStationNode)
            .filter((n) => NodeState.isChecked(n.state));
    };

    /** Get the location resource collection within a particular date range. */
    const getLocationsWithinDateRange = () => {
        const data = indexLocations.value;
        const filtered = data.filter((r) =>
            isLocationResourceWithinDateRange(r)
        );
        return filtered;
    };

    /** Get the weather station resource collection within a particular date range. */
    const getWeatherStationsWithinDateRange = () => {
        const data = indexWeatherStations.value;
        const filtered = data.filter((r) =>
            isWeatherStationResourceWithinDateRange(r)
        );
        return filtered;
    };

    /** Get the checked location ids. */
    const getCheckedLocationIDs = () => {
        const nodes = checkedLocationNodes.value;
        return nodes
            .map((n) => n.id)
            .map(NodeSelector.readResourceID)
            .map(Number);
    };

    /** Get the checked weather station ids. */
    const getCheckedWeatherStationIDs = () => {
        const nodes = checkedWeatherStationNodes.value;
        return nodes
            .map((n) => n.id)
            .map(NodeSelector.readResourceID)
            .map(String);
    };

    /** Get the filtered locations. */
    const getFilteredLocations = () => {
        const data = indexLocations.value;
        const selectedIDs = checkedLocationIDs.value;
        const withinDateRangeIDs = datedLocations.value.map((r) => r.id);
        const filtered = data.filter((r) => {
            return (
                selectedIDs.includes(r.id) && withinDateRangeIDs.includes(r.id)
            );
        });

        return filtered;
    };

    /** Get the filtered weather stations. */
    const getFilteredWeatherStations = () => {
        const data = indexWeatherStations.value;
        const selectedIDs = checkedWeatherStationIDs.value;
        const withinDateRangeIDs = datedWeatherStations.value.map((r) =>
            String(r.id)
        );
        const filtered = data.filter((r) => {
            return (
                selectedIDs.includes(String(r.id)) &&
                withinDateRangeIDs.includes(String(r.id))
            );
        });

        return filtered;
    };

    /**
     * Calculate valid start and end dates.
     * @param {LocationResource} resource
     */
    const getLocationDateRange = (resource) => {
        const defaultStart = Math.trunc(
            startOfDay(subYears(Date.now(), 1)).valueOf() / 1000
        );
        const defaultEnd = Math.trunc(endOfDay(Date.now()).valueOf() / 1000);
        return {
            minDate: defaultStart,
            maxDate: defaultEnd,
        };
    };

    /**
     * Calculate valid start and end dates.
     * @param {Interval} range
     */
    const getNormalizedDateRange = (range) => {
        // VALIDATE
        const defaultStart = startOfDay(subYears(Date.now(), 1)).valueOf();
        const defaultEnd = endOfDay(Date.now()).valueOf();
        const _start = range?.start?.valueOf() ?? NaN;
        const _end = range?.end?.valueOf() ?? NaN;

        // CLAMP
        const SAFE_MAX_DATE = Date.UTC(3000, 0, 1, 0, 0, 0, 0);
        const SAFE_MIN_DATE = Date.UTC(1, 0, 1, 0, 0, 0, 0);
        const minDate =
            is.nan(_start) || isBefore(_start, SAFE_MIN_DATE)
                ? defaultStart
                : _start;
        const maxDate =
            is.nan(_end) || isAfter(_end, SAFE_MAX_DATE) ? defaultEnd : _end;
        return {
            minDate: Math.round(minDate.valueOf()),
            maxDate: Math.round(maxDate.valueOf()),
        };
    };

    const getLimits = (limits, resource) => {
        if (isNARAEnabled.value) {
            return {
                tll: resource?.naraStandard?.min_temp,
                tul: resource?.naraStandard?.max_temp,
                rhll: resource?.naraStandard?.min_rh,
                rhul: resource?.naraStandard?.max_rh,
            };
        }

        const params = {};

        if (limits.temp.lower !== -Infinity) {
            params.tll = limits.temp.lower;
        }
        if (limits.temp.upper !== Infinity) {
            params.tul = limits.temp.upper;
        }

        if (limits.rh.lower !== -Infinity) {
            params.rhll = limits.rh.lower;
        }
        if (limits.rh.upper !== Infinity) {
            params.rhul = limits.rh.upper;
        }

        if (limits.dp.lower !== -Infinity) {
            params.dpll = limits.dp.lower;
        }
        if (limits.dp.upper !== Infinity) {
            params.dpul = limits.dp.upper;
        }

        return params;
    };

    // STATE

    /** @type {Vue.Ref<globalThis.Account.Model>} */
    const selectedAccount = ref(store?.state?.accounts?.account ?? null);

    /** @type {Vue.Ref<LocationResource[]>} */
    const indexLocations = ref([]);

    /** @type {Vue.Ref<import('@/models/v1/weather/WeatherStation').WeatherStationResource[]>} */
    const indexWeatherStations = ref([]);

    /** @type {Vue.Ref<Record<number, import('@/utils/standards').IStandardMetrics>>} */
    const metricsByLocationID = ref({});

    /** @type {Vue.Ref<Record<number, LocationStatisticsResource>>} */
    const statisticsByLocationID = ref({});

    /** @type {Vue.Ref<Record<string, WeatherStationStatisticsResource>>} */
    const statisticsByWeatherStationID = ref({});

    // COMPUTED

    const selectedAccountID = computed(() => selectedAccount.value?.id ?? -1);
    const isAccountSelected = computed(
        () => !!store.state.accounts.account?.id && selectedAccountID.value > 0
    );

    const accountTimezone = computedEager(
        () => store.state.accounts?.account?.timezone ?? 'UTC'
    );
    const customTimezone = computedEager(
        () => store.state.analysis?.filters?.timezone?.timezone ?? 'UTC'
    );
    const useAccountTimezone = computedEager(
        () =>
            store.state.analysis?.filters?.timezone?.useAccountTimezone === true
    );
    const displayTimezone = computedEager(() => {
        return useAccountTimezone.value === true
            ? accountTimezone.value
            : customTimezone.value;
    });

    const daterange = computed(
        () => {
            // const { start, end } = store.state.analysis.filters.daterange;
            const { start, end } =
                store.state.analysis.filters.daterange.toFormModel();
            // VALIDATE
            const filterStart =
                is.nullOrUndefined(start) || is.emptyStringOrWhitespace(start)
                    ? Date.UTC(1970, 0, 1, 0, 0, 0, 0)
                    : zonedTimeToUtc(
                          `${start} 00:00:00`,
                          displayTimezone.value
                      );
            const filterEnd =
                is.nullOrUndefined(end) || is.emptyStringOrWhitespace(end)
                    ? Date.UTC(1970, 0, 1, 0, 0, 0, 0)
                    : zonedTimeToUtc(`${end} 23:59:59`, displayTimezone.value);
            // EXPOSE
            return {
                start: filterStart,
                end: filterEnd,
            };
        },
        {
            // onTrigger: (e) => console.log(daterange.value),
        }
    );
    const hasDateRange = computedEager(() => !!daterange.value);
    const datedLocations = computed(getLocationsWithinDateRange);
    const datedWeatherStations = computed(getWeatherStationsWithinDateRange);
    const checkedLocationNodes = computed(getCheckedLocationNodes);
    const checkedWeatherStationNodes = computed(getCheckedWeatherStationNodes);
    const checkedLocationIDs = computed(getCheckedLocationIDs);
    const checkedWeatherStationIDs = computed(getCheckedWeatherStationIDs);
    const filteredLocations = computed(getFilteredLocations);
    const hasFilteredLocations = computed(
        () => filteredLocations.value.length === 0
    );
    const filteredWeatherStations = computed(getFilteredWeatherStations);
    const hasFilteredWeatherStations = computed(
        () => filteredWeatherStations.value.length === 0
    );

    const QueryKeys = Object.freeze({
        all: /** @type {const} */ (['accounts']),
        /** @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account */
        locations: (account) =>
            /** @type {const} */ ([...QueryKeys.all, account, 'locations']),
        /** @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account */
        weatherStations: (account) =>
            /** @type {const} */ ([...QueryKeys.all, account, 'stations']),
        /**
         * @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account
         * @param {import('@vueuse/core').MaybeRef<Interval>} daterange
         */
        metrics: (account, daterange) =>
            /** @type {const} */ ([
                ...QueryKeys.all,
                account,
                'locations',
                'metrics',
                daterange,
            ]),
        /**
         * @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account
         * @param {import('@vueuse/core').MaybeRef<LocationResource['id']>} location
         * @param {import('@vueuse/core').MaybeRef<Interval>} daterange
         */
        locationStatistics: (account, location, daterange) =>
            /** @type {const} */ ([
                ...QueryKeys.all,
                account,
                'locations',
                location,
                'statistics',
                daterange,
            ]),

        /**
         * @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account
         * @param {import('@vueuse/core').MaybeRef<import('@/models/v1/weather/WeatherStation').WeatherStationResource['id']>} station
         * @param {import('@vueuse/core').MaybeRef<Interval>} daterange
         */
        weatherStationStatistics: (account, station, daterange) =>
            /** @type {const} */ ([
                ...QueryKeys.all,
                account,
                'stations',
                station,
                'statistics',
                daterange,
            ]),
    });

    const normalizedDates = computed(() => {
        const { minDate, maxDate } = getNormalizedDateRange(daterange.value);
        //
        return { start: minDate, end: maxDate };
    });

    /** Create query for account locations. */
    const accountLocations = useQuery({
        queryKey: QueryKeys.locations(selectedAccountID),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::locations`, queryKey);
            return fetchLocations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexLocations.value = data;
            // console.log(`HIT sn::debug::locations`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Create query for account weather stations. */
    const accountWeatherStations = useQuery({
        queryKey: QueryKeys.weatherStations(selectedAccountID),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::stations`, queryKey);
            return fetchWeatherStations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexWeatherStations.value = data;
            // console.log(`HIT sn::debug::stations`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Create query for NARA Standard metrics for all account locations. */
    const accountMetrics = useQuery({
        queryKey: QueryKeys.metrics(selectedAccountID, normalizedDates),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::metrics`, queryKey);
            //
            return fetchNARAStandardMetrics({ id: queryKey[1] }, queryKey[4], {
                signal,
            });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            const payload = {};
            data.metrics.forEach((item) => {
                payload[item?.location?.id] = item?.metrics;
            });
            metricsByLocationID.value = { ...payload };
            // console.log(`HIT sn::debug::metrics`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Define query options for each location. */
    const locationStatisticsOptions = computed(() =>
        filteredLocations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.locationStatistics(
                    selectedAccountID,
                    resource.id,
                    daterange
                ),
                queryFn: ({ queryKey, signal }) => {
                    // const { minDate, maxDate } = getLocationDateRange(resource);
                    const { minDate, maxDate } = getNormalizedDateRange(
                        daterange.value
                    );
                    // console.log(`SEND sn::debug::statistics`, {
                    //     ...resource,
                    //     minDate,
                    //     maxDate,
                    // });
                    return fetchLocationStats(
                        {
                            id: resource?.id,
                            name: resource?.name,
                            minDate: Math.trunc(minDate / 1000),
                            maxDate: Math.trunc(maxDate / 1000),
                            ...getLimits(
                                store.state.analysis.filters.limits,
                                resource
                            ),
                        },
                        { signal }
                    );
                },
                onError: (e) => console.error(e),
                /** @param {import('@/models/v1/locations/LocationStats').LocationStatsResource} data */
                onSuccess: (data) => {
                    statisticsByLocationID.value = Object.assign(
                        statisticsByLocationID.value,
                        {
                            [resource.id]: data,
                        }
                    );
                    // console.log(`HIT sn::debug::statistics`, data);
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel location statistics queries. */
    const locationStatistics = useQueries({
        queries: locationStatisticsOptions,
    });

    /** Define query options for each weather station. */
    const weatherStationStatisticsOptions = computed(() =>
        filteredWeatherStations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.weatherStationStatistics(
                    selectedAccountID,
                    resource.id,
                    daterange
                ),
                queryFn: ({ queryKey, signal }) => {
                    const { minDate, maxDate } = getNormalizedDateRange(
                        daterange.value
                    );
                    // console.log(`SEND sn::debug::statistics`, {
                    //     ...resource,
                    //     minDate,
                    //     maxDate,
                    // });
                    return fetchWeatherStationStats(
                        {
                            id: Number(resource?.id),
                            name: resource?.name,
                            minDate: Math.trunc(minDate / 1000),
                            maxDate: Math.trunc(maxDate / 1000),
                            ...getLimits(
                                store.state.analysis.filters.limits,
                                resource
                            ),
                        },
                        { signal }
                    );
                },
                onError: (e) => console.error(e),
                /** @param {import('@/models/v1/locations/LocationStats').LocationStatsResource} data */
                onSuccess: (data) => {
                    statisticsByWeatherStationID.value = Object.assign(
                        statisticsByWeatherStationID.value,
                        {
                            [resource.id]: data,
                        }
                    );
                    // console.log(`HIT sn::debug::statistics`, data);
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel weather station statistics queries. */
    const weatherStationStatistics = useQueries({
        queries: weatherStationStatisticsOptions,
    });

    /** Is the component loading? */
    const isLoading = computed(() => {
        if (
            accountLocations.isLoading.value ||
            accountLocations.isFetching.value
        ) {
            return true;
        }
        if (
            accountWeatherStations.isLoading.value ||
            accountWeatherStations.isFetching.value
        ) {
            return true;
        }
        if (accountMetrics.isLoading.value || accountMetrics.isFetching.value) {
            return true;
        }
        if (locationStatistics.some((q) => !!q.isLoading || !!q.isFetching)) {
            return true;
        }
        if (
            weatherStationStatistics.some(
                (q) => !!q.isLoading || !!q.isFetching
            )
        ) {
            return true;
        }
        return false;
    });

    /** Is the component ready? */
    const isReady = computed(() => !isLoading.value);

    /**
     * Resolved resource statistics data. Empty to start.
     * @type {Vue.Ref<ResourceStatistics[]>}
     */
    const data = computedEager(() => {
        // LOCATIONS
        const _locations = {
            get filtered() {
                return filteredLocations.value;
            },
            get statistics() {
                return statisticsByLocationID.value;
            },
            compute() {
                // COMPUTE location resource data.
                const { statistics } = this;
                return this.filtered
                    .map((location) => {
                        const itemStatistics = statistics[location.id];

                        /** @type {ResourceStatistics} */
                        const mapped = {
                            id: location.id,
                            type: 'Location',
                            resource: location,
                            statistics: itemStatistics,
                        };

                        // Mapped object.
                        return mapped;
                    })
                    .sort((a, b) => {
                        const _a = { value: null, unassigned: true };
                        _a.unassigned = a?.resource?.hierarchyId == null;
                        _a.value = _a.unassigned
                            ? a?.resource?.name
                            : a?.resource?.path;

                        const _b = { value: null, unassigned: true };
                        _b.unassigned = b?.resource?.hierarchyId == null;
                        _b.value = _b.unassigned
                            ? b?.resource?.name
                            : b?.resource?.path;

                        // Sort the locations, including hierarchy status.
                        return sortByLocationPath(_a, _b);
                    });
            },
        };
        // WEATHER STATIONS
        const _weatherStations = {
            get filtered() {
                return filteredWeatherStations.value;
            },
            get statistics() {
                return statisticsByWeatherStationID.value;
            },
            compute() {
                // COMPUTE weather station resource data.
                const { statistics } = this;
                return this.filtered
                    .map((station) => {
                        /** @type {ResourceStatistics} */
                        const mapped = {
                            id: String(station.id),
                            type: 'Weather Station',
                            resource: station,
                            statistics: statistics[station.id],
                        };
                        // Mapped object.
                        return mapped;
                    })
                    .sort((a, b) => {
                        const _a = { value: null };
                        _a.value = a?.resource?.name ?? '';

                        const _b = { value: null };
                        _b.value = b?.resource?.name ?? '';

                        // Sort the weather stations.
                        return sortByLocaleName(_a.value, _b.value);
                    });
            },
        };

        // COMPUTE merged resource data.
        return [..._locations.compute(), ..._weatherStations.compute()];
    });

    /** Is the treeview selection empty? */
    const isSelectionEmpty = computedEager(
        () =>
            checkedLocationIDs.value.length === 0 &&
            checkedWeatherStationIDs.value.length === 0
    );

    /** Is the resolved data collection empty? */
    const isResponseEmpty = computedEager(
        () => !!data && data?.value?.length === 0
    );

    // LIFECYCLE

    // onSelectionChange(() => {});
    // onDateRangeFilterUpdate(() => {});
    // onAccountLocationsFetched((locations) => {});
    // onLocationMetricsFetched((id, metrics) => {});
    // onLocationStatisticsFetched((id, statistics) => {});
    // onWeatherStationsFetched((stations) => {});

    // WATCHERS

    watch(
        () => store.state.accounts.account,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                selectedAccount.value = current;
                indexLocations.value = [];
                indexWeatherStations.value = [];
                statisticsByLocationID.value = {};
                metricsByLocationID.value = {};
                statisticsByWeatherStationID.value = {};
                // console.log(`sn::debug::watch-account?`, current);
            }
        },
        {
            deep: false,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.limits,
        (current, previous) => {
            if (is.nonEmptyObject(getLimits(current))) {
                queryClient.refetchQueries();
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.locations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.locations(selectedAccountID),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.stations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.weatherStations(selectedAccountID),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    // EXPOSE
    const exposed = makeDestructurable(
        {
            data,
            queryClient,
            isLoading,
            isReady,
            isSelectionEmpty,
            isResponseEmpty,
        },
        [
            data,
            filteredLocations,
            filteredWeatherStations,
            isLoading,
            isReady,
            isSelectionEmpty,
            isResponseEmpty,
        ]
    );
    return exposed;
};
