<template>
    <div>
        <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 448 512"
            style="enable-background: new 0 0 448 512"
            xml:space="preserve"
        >
            <path
                class="st4"
                d="M413,451H33.9c-6.6,0-12-5.4-12-12V59.8c0-6.6,5.4-12,12-12H413c6.6,0,12,5.4,12,12V439
            C425,445.6,419.6,451,413,451z"
            />
            <path
                class="st5"
                d="M203.3,331.3c-6.2,6.3-16.4,6.3-22.6,0l-64-64c-6.3-6.2-6.3-16.4,0-22.6c6.2-6.3,16.4-6.3,22.6,0l52.7,52.7
            l116.7-116.7c6.2-6.3,16.4-6.3,22.6,0c6.3,6.2,6.3,16.4,0,22.6L203.3,331.3z M0,96c0-35.3,28.6-64,64-64h320c35.3,0,64,28.7,64,64
            v320c0,35.3-28.7,64-64,64H64c-35.3,0-64-28.7-64-64V96z M32,96v320c0,17.7,14.3,32,32,32h320c17.7,0,32-14.3,32-32V96
            c0-17.7-14.3-32-32-32H64C46.3,64,32,78.3,32,96z"
            />
        </svg>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'GreenSquareIcon',
    });
</script>

<style type="text/css">
    .st4 {
        fill: #dcfce7;
    }
    .st5 {
        fill: #15803d;
    }
</style>
