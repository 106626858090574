// Composables used for various Sidebar related content.

// <!-- API -->
import { ref, computed } from 'vue';
import { useStore, Store } from 'vuex';
import { useRoute } from 'vue-router';

// <!-- COMPOSABLES -->
import { useNARAFeature } from '@/utils/features/';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { computedEager } from '@vueuse/core';
import { states } from '@/hooks/useStates';
/** @typedef {ReturnType<initializeLocalState>} SidebarState Local state. */
/** @typedef {ReturnType<prepareStaticProperties>} SidebarConstants Local constants. */
/** @typedef {ReturnType<prepareComputedProperties>} SidebarProperties Local computed properties. */
/** @typedef {ReturnType<prepareMethods>} SidebarMethods Local component methods. */
/** @typedef {ReturnType<prepareEventListeners>} SidebarHandlers Local event listeners. */

const FILTERS = {
    DATE_RANGE: 'Date Range',
    TIMEZONE: 'Display Time Zone',
    LIMITS: 'Limits',
    GRAPHING_TOOLS: 'Scale',
    LOCATIONS: 'Locations',
    WEATHER_STATIONS: 'Weather Stations',
};

/**
 * Initialize the local state.
 */
const initializeLocalState = () => {
    return {
        /** @type {Store<ECNBState>} */
        store: useStore(),

        /** @type {Vue.Ref<Router.RouteLocationNormalizedLoaded>} Current route reference. */
        route: ref(useRoute()),

        /** @type {Vue.Ref<boolean>} Boolean flag. */
        multiple: ref(true),

        /** @type {Vue.Ref} Filters map. Filters can have more than just a title. */
        filters: ref({
            dateRange: {
                title: FILTERS.DATE_RANGE,
                options: [
                    { value: '1M', label: '1 Month' },
                    { value: '3M', label: '3 Months' },
                    { value: '6M', label: '6 Months' },
                    { value: '1Y', label: '1 Year' },
                    { value: '3Y', label: '3 Years' },
                    // { value: 'all', label: 'All' },
                ],
            },
            timezone: {
                title: FILTERS.TIMEZONE,
                options: [
                    // TODO: Get the full set of timezone options.
                    'UTC',
                    'America/New_York',
                ],
            },
            limits: {
                title: FILTERS.LIMITS,
            },
            scales: {
                title: FILTERS.GRAPHING_TOOLS,
            },
            locations: {
                title: FILTERS.LOCATIONS,
            },
            stations: {
                title: FILTERS.WEATHER_STATIONS,
            },
        }),

        /** @type {Vue.Ref<String>} Preset selection. Defaults to '1M'. */
        preset: ref('1M'),
    };
};

/**
 * Prepare local static (unchanging) properties.
 */
const prepareStaticProperties = () => {
    const dateRangeUnits = { M: 'months', Y: 'years' };

    return {
        FILTERS,
        dateRangeUnits,
    };
};

/**
 * Initialize computed properties.
 * @param {SidebarState} state Local state.
 * @param {SidebarConstants} constants Local constants.
 */
// ts-ignore
const prepareComputedProperties = (state, constants) => {
    const { isNARADisabled } = useNARAFeature();

    /** @type {Vue.ComputedRef<Router.RouteRecordName>} Current route name. */
    const currentRouteName = computed(() => state.route.value.name ?? '');

    /** @type {Vue.ComputedRef<TimeZone.Identifier>} */
    const accountTimezone = computed(
        () => state.store?.state?.accounts?.account?.timezone ?? 'UTC'
    );

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const isDateRangeVisible = computed(() => true);

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const isTimezoneInputVisible = computed(() =>
        [
            'Analysis',
            'Home',
            'Statistics',
            'Compare Metrics',
            'Reports',
        ].includes(String(currentRouteName.value))
    );

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const isLimitsVisible = computed(
        () =>
            ['Analysis', 'Home'].includes(String(currentRouteName.value)) ||
            (isNARADisabled.value &&
                ['Statistics', 'Reports'].includes(
                    String(currentRouteName.value)
                ))
    );

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const showLimitsButtons = computed(
        () => !['Statistics'].includes(String(currentRouteName.value))
    );

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const isScalesVisible = computed(() =>
        ['Analysis', 'Home'].includes(String(currentRouteName.value))
    );

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const isLocationsVisible = computed(() => true);

    /** @type {Vue.ComputedRef<Boolean>} Controls visibility of the sidebar component. */
    const isWeatherStationsVisible = computed(() =>
        [
            'Analysis',
            'Home',
            'Statistics',
            'Compare Metrics',
            'Reports',
        ].includes(String(currentRouteName.value))
    );

    /** @type {Vue.ComputedRef<number>} Checked location limit based on tab. */
    const checkedLocationLimit = computed(() => {
        const value = ['Analysis', 'Home'].includes(
            String(currentRouteName.value)
        )
            ? 8
            : Infinity;
        return value;
    });

    /** @type {Vue.ComputedRef<number>} Checked weather station limit based on tab. */
    const checkedWeatherStationLimit = computed(() => {
        return Infinity;
    });

    return {
        currentRouteName,
        accountTimezone,
        isDateRangeVisible,
        isTimezoneInputVisible,
        isLimitsVisible,
        showLimitsButtons,
        isScalesVisible,
        isLocationsVisible,
        isWeatherStationsVisible,
        checkedLocationLimit,
        checkedWeatherStationLimit,
    };
};

/**
 *
 * @param {SidebarState} state Local state.
 * @param {SidebarConstants} constants Local constants.
 * @param {SidebarProperties} properties Local computed properties.
 */
// ts-ignore
const prepareEventListeners = (state, constants, properties) => {
    const onUpdateStartDate = async (event) => {
        state.store.commit('analysis/setStartDate', event);
    };

    const onUpdateEndDate = async (event) => {
        state.store.commit('analysis/setEndDate', event);
    };

    /**
     * Event listener that will fire when a timezone is selected.
     * @param {{ timezone: TimeZone.Identifier }} event
     */
    const onTimezoneSelected = async (event) => {
        //TODO: Perform some side-effect when the timezone is changed.
    };

    // EXPOSE
    return {
        onUpdateStartDate,
        onUpdateEndDate,
        onTimezoneSelected,
    };
};

/**
 * Initialize component methods.
 * @param {SidebarState} state Local state.
 * @param {SidebarConstants} constants Local constants.
 * @param {SidebarProperties} properties Local computed properties.
 * @param {SidebarHandlers} handlers Local event handlers.
 */
// ts-ignore
const prepareMethods = (state, constants, properties, handlers) => {
    /** Initialize the filters map. */
    const initializeFilters = () => {
        // Creates entries.
        console.log('Filters initialized.');
    };

    return {
        initializeFilters,
    };
};

/**
 * Generate and return local state, properties, and methods for the Compare metrics page.
 * @param {Object} [props] Props to pass into the Compare Metrics page.
 * @param {Partial<SidebarState>} [props.state] Local state overrides.
 */
export const useSidebarFilters = (props = {}) => {
    // Initialize the state.
    const constants = prepareStaticProperties();
    const state = Object.assign(initializeLocalState(), props?.state ?? {});
    const properties = prepareComputedProperties(state, constants);
    const handlers = prepareEventListeners(state, constants, properties);
    const methods = prepareMethods(state, constants, properties, handlers);

    // Initialization.
    methods.initializeFilters();

    // Expose state.
    return {
        constants,
        state,
        properties,
        handlers,
        methods,
    };
};

// <!-- EXPORTS -->
export default {
    useSidebarFilters,
};
