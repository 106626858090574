<template>
    <div
        v-if="isDebug"
        class="mt-2 p-2 w-full text-xs text-center rounded-lg"
        :class="{
            'bg-yellow-50 animate-pulse': online && available && busy,
            'bg-green-50': online && available && !busy,
            'bg-red-50': !online || !available,
        }"
    >
        <span v-if="online && available && busy">
            The graphing server is generating your reports.
        </span>
        <span v-else-if="online && available && !busy">
            The graphing server is currently operational.
        </span>
        <span v-else-if="online && !available && !busy">
            The graphing server is currently down. Please contact your system
            administrator.
        </span>
        <span v-else>
            You are not connected to the network. Please reconnect in order to
            download reports.
        </span>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- TYPES -->
    /** @template [T=any] @typedef {import('vue').PropType<T>} PropType<T> */
    /** @template [T=any] @typedef {import('vue').Ref<T>} Ref<T> */
    /** @template [T=any] @typedef {import('vue').ComputedRef<T>} ComputedRef<T> */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DownloadReportServerHealth',
        components: {},
        props: {
            /** If `true`, the client is online. */
            online: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, the graphing server is online and available. */
            available: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, the graphing server is busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props, context) {
            /** Debug environment flag. */
            const isDebug = process.env.NODE_ENV !== 'production';
            // HIDDEN ON PRODUCTION.
            return {
                isDebug,
            };
        },
    });
</script>
