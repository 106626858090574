export const ExpandEvent = 'expand';
export const CollapseEvent = 'collapse';
export const CheckAllEvent = 'check:all';
export const CheckNoneEvent = 'check:none';
export const ChangePresetEvent = 'change:preset';

export const DATERANGE = /** @type {const} */ ({
    UpdateStartDateEvent: 'update:start',
    UpdateEndDateEvent: 'update:end',
    ChangePresetEvent,
});

export const LIMITS = /** @type {const} */ ({
    UpdateTempEvent: 'update:temp',
    UpdateRHEvent: 'update:rh',
    UpdateDPEvent: 'update:dp',
});

export const SCALE = /** @type {const} */ ({
    UpdateTempScaleEvent: 'update:temp',
    UpdateRHScaleEvent: 'update:rh',
    UpdateDPScaleEvent: 'update:dp',
});

export const TREEVIEW = /** @type {const} */ ({
    ExpandEvent,
    CollapseEvent,
    CheckAllEvent,
    CheckNoneEvent,
    RefreshEvent: 'refresh:nodes',
    LoadEvent: 'load:nodes',
    SaveEvent: 'save:nodes',
    DebugEvent: 'debug:event',
    NodeOpenedEvent: 'nodeOpened',
    NodeClosedEvent: 'nodeClosed',
    NodeCheckedEvent: 'nodeChecked',
    NodeUncheckedEvent: 'nodeUnchecked',
    NodeToggleEvent: 'nodeToggle',
    NodeFocusEvent: 'nodeFocus',
    NodeBlurEvent: 'nodeBlur',
});

export default {
    ExpandEvent,
    CollapseEvent,
    CheckAllEvent,
    CheckNoneEvent,
    ChangePresetEvent,
    TREEVIEW,
    DATERANGE,
};
