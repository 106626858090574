// <!-- UTILITIES -->
import is from '@sindresorhus/is';

// <!-- MODELS -->
import { MeasuresOfTemperature } from './MeasuresOfTemperature';
import { MeasuresOfRelativeHumidity } from './MeasuresOfRelativeHumidity';
import { MeasuresOfDewpoint } from './MeasuresOfDewpoint';
import { MeasuresOfMoldRisk } from './MeasuresOfMoldRisk';
import { MeasuresOfLastTimeWeightedPreservationIndex } from './MeasuresOfLastTimeWeightedPreservationIndex';
import { MeasuresOfDimensionalChange } from './MeasuresOfDimensionalChange';
import { MeasuresOfEquilibriumMoistureContent } from './MeasuresOfEquilibriumMoistureContent';
import { Measure } from '@/utils/measures';

// <!-- ENUMS -->

/**
 * @typedef {typeof Risks[RiskCategory]} Risk
 * Indicates the risk.
 */
/**
 * @typedef {keyof typeof Risks} RiskCategory
 * Indicates the risk category.
 */
export const Risks = Object.freeze(
    /** @type {const} */ ({
        GOOD: {
            value: 1,
            label: 'GOOD',
            color: '#006500',
        },
        OK: {
            value: 2,
            label: 'OK',
            color: '#000000',
        },
        DANGER: {
            value: 3,
            label: 'RISK',
            color: '#FF0000',
        },
    })
);

// <!-- TYPES -->
/**
 * @typedef ResourceRisksPayload
 * @prop {string} start_date ISO-8601 formatted string representing the start date.
 * @prop {string} end_date ISO-8601 formatted string representing the end date.
 * @property {number} temp_mean Measure value.
 * @property {number} rh_mean Measure value.
 * @property {number} dp_mean Measure value.
 * @property {number} mrf Measure value.
 * @property {number} last_twpi Measure value.
 * @property {number} dc_min Measure value.
 * @property {number} dc_max Measure value.
 * @property {number} dc_span Measure value.
 * @property {number} emc_min Measure value.
 * @property {number} emc_max Measure value.
 * @property {number} emc_mean Measure value.
 */

/**
 * @class Represents the response data for the resource risk endpoints.
 * @implements {ResourceRisksPayload}
 */
export class ResourceRisksData {
    /**
     *
     * @param {Partial<ResourceRisksPayload>} attrs
     */
    constructor(attrs) {
        this.start_date = attrs?.start_date ?? null;
        this.end_date = attrs?.end_date ?? null;
        this.temp_mean = attrs?.temp_mean ?? NaN;
        this.rh_mean = attrs?.rh_mean ?? NaN;
        this.dp_mean = attrs?.dp_mean ?? NaN;
        this.mrf = attrs?.mrf ?? NaN;
        this.last_twpi = attrs?.last_twpi ?? NaN;
        this.dc_min = attrs?.dc_min ?? NaN;
        this.dc_max = attrs?.dc_max ?? NaN;
        this.dc_span = attrs?.dc_span ?? NaN;
        this.emc_min = attrs?.emc_min ?? NaN;
        this.emc_max = attrs?.emc_max ?? NaN;
        this.emc_mean = attrs?.emc_mean ?? NaN;
    }
}

/**
 * @typedef ResourceRisksByMetric
 * @prop {string} [start_date] ISO-8601 formatted string representing the start date.
 * @prop {string} [end_date] ISO-8601 formatted string representing the end date.
 * @prop {Pick<MeasuresOfTemperature, 'metric' | 'mean'>} [T] Temperaure measures.
 * @prop {Pick<MeasuresOfRelativeHumidity, 'metric' | 'mean'>} [RH] Relative humidity measures.
 * @prop {Pick<MeasuresOfDewpoint, 'metric' | 'mean'>} [DP] Dewpoint measures.
 * @prop {MeasuresOfMoldRisk} [MOLD] Mold measures.
 * @prop {MeasuresOfLastTimeWeightedPreservationIndex} [TWPI] Time-weighted preservation index measures.
 * @prop {MeasuresOfDimensionalChange} [DC] Dimensional change measures.
 * @prop {MeasuresOfEquilibriumMoistureContent} [EMC] Equilibrium moisture content measures.
 */

/**
 * @class Represents risks model with measures grouped by metric.
 * @implements {ResourceRisksByMetric}
 */
export class ResourceRisks {
    /**
     * Cast model instance into the corresponding data structure.
     * @param {Readonly<ResourceRisksByMetric>} instance
     * @returns {ResourceRisksData}
     */
    static toData(instance) {
        const model = new ResourceRisks(instance);
        return new ResourceRisksData({
            start_date: model.start_date,
            end_date: model.end_date,
            temp_mean: model.T.mean.value,
            rh_mean: model.RH.mean.value,
            dp_mean: model.DP.mean.value,
            mrf: model.MOLD.mrf.value,
            last_twpi: model.TWPI.last_twpi.value,
            dc_min: model.DC.min.value,
            dc_max: model.DC.max.value,
            dc_span: model.DC.span.value,
            emc_min: model.EMC.min.value,
            emc_max: model.EMC.max.value,
            emc_mean: model.EMC.mean.value,
        });
    }

    /**
     * Cast data into the corresponding model structure.
     * @param {Readonly<ResourceRisksPayload>} payload
     * @returns {ResourceRisks}
     */
    static fromData(payload) {
        const data = new ResourceRisksData(payload);
        return new ResourceRisks({
            start_date: data.start_date,
            end_date: data.end_date,
            T: new MeasuresOfTemperature({
                mean: new Measure({ type: 'mean', value: data.temp_mean }),
            }),
            RH: new MeasuresOfRelativeHumidity({
                mean: new Measure({ type: 'mean', value: data.rh_mean }),
            }),
            DP: new MeasuresOfDewpoint({
                mean: new Measure({ type: 'mean', value: data.dp_mean }),
            }),
            MOLD: new MeasuresOfMoldRisk({
                mrf: new Measure({ type: 'mrf', value: data.mrf }),
            }),
            TWPI: new MeasuresOfLastTimeWeightedPreservationIndex({
                last_twpi: new Measure({
                    type: 'last_twpi',
                    value: data.last_twpi,
                }),
            }),
            DC: new MeasuresOfDimensionalChange({
                min: new Measure({ type: 'min', value: data.dc_min }),
                max: new Measure({ type: 'max', value: data.dc_max }),
                span: new Measure({ type: 'span', value: data.dc_span }),
            }),
            EMC: new MeasuresOfEquilibriumMoistureContent({
                min: new Measure({ type: 'min', value: data.emc_min }),
                max: new Measure({ type: 'max', value: data.emc_max }),
                mean: new Measure({ type: 'mean', value: data.emc_mean }),
            }),
        });
    }

    /**
     * Get data with computed properties.
     * @param {ResourceRisksByMetric | ResourceRisksPayload} risks
     * @returns {ResourceRisksPayload & { age_risk: Risk, corr_risk: Risk, mech_risk: Risk, mold_risk: Risk }}
     */
    static withComputedRisks(risks) {
        const data =
            'T' in risks
                ? ResourceRisks.toData(risks)
                : new ResourceRisksData(risks);
        const age_risk = ResourceRisks.calculateNaturalAgingRisk(data);
        const mech_risk = ResourceRisks.calculateMechanicalDamageRisk(data);
        const mold_risk = ResourceRisks.calculateMoldGerminationRisk(data);
        const corr_risk = ResourceRisks.calculateMetalCorrosionRisk(data);
        return {
            ...data,
            age_risk,
            mech_risk,
            mold_risk,
            corr_risk,
        };
    }

    static isValidMeasure;

    /**
     * Calculates the natural aging risk, which measures the rate of chemical decay
     * as determined by the rate of spontaneous chemical change in organic materials.
     *
     * This is measured using:
     * - Time Weighted Preservation Index (TWPI)
     *
     * ```
     * TWPI > 75 => GOOD
     * 45 < TWPI <= 75 => OK
     * TWPI <= 45 => DANGER
     * ```
     *
     * @param {Pick<ResourceRisksData, 'last_twpi'>} data Provides access to the required metrics.
     * @returns {typeof Risks[keyof Risks]}
     */
    static calculateNaturalAgingRisk({ last_twpi }) {
        if (is.nullOrUndefined(last_twpi) || is.nan(last_twpi)) {
            return null;
        }

        // * `TWPI <= 45 => DANGER`
        if (last_twpi <= 45) {
            return Risks.DANGER;
        }

        // * `45 < TWPI <= 75 => OK`
        if (last_twpi > 45 && last_twpi <= 75) {
            return Risks.OK;
        }

        // * `TWPI > 75 => GOOD`
        return Risks.GOOD;
    }

    /**
     * Calculates the mechanical damage risk, which measures the
     * environmentally-induced physical or structural deterioration.
     *
     * This is measured using:
     * - Minimum % Equilibrium Moisture Content (% EMC Min)
     * - Maximum % Equilibrium Moisture Content (% EMC Max)
     * - % Dimensional Change (% DC)
     *
     * ```
     * (%EMC Min < 5%) OR (%EMC Max > 12.5%) OR (%DC > 1.5%) => DANGER
     * (%EMC Min >= 5%) AND (%EMC Max <= 12.5%) AND (%DC <= 0.5%) => GOOD
     * (%EMC Min >= 5%) AND (%EMC Max <= 12.5%) AND (0.5% < %DC <= 1.5%) => OK
     * ```
     *
     * @param {Pick<ResourceRisksData, 'emc_min' | 'emc_max' | 'dc_span'>} data Provides access to the required metrics.
     * @returns {typeof Risks[keyof Risks]}
     */
    static calculateMechanicalDamageRisk({ emc_min, emc_max, dc_span }) {
        if (
            is.nullOrUndefined(emc_min) ||
            is.nan(emc_min) ||
            is.nullOrUndefined(emc_max) ||
            is.nan(emc_max) ||
            is.nullOrUndefined(dc_span) ||
            is.nan(dc_span)
        ) {
            return null;
        }

        // * (%EMC Min < 5%) OR (%EMC Max > 12.5%) OR (%DC > 1.5%) => DANGER
        if (emc_min < 5.0 || emc_max > 12.5 || dc_span > 1.5) {
            return Risks.DANGER;
        }

        // * (%EMC Min >= 5%) AND (%EMC Max <= 12.5%) AND (%DC <= 0.5%) => GOOD
        if (emc_min >= 5.0 && emc_max <= 12.5 && dc_span <= 0.5) {
            return Risks.GOOD;
        }

        // * (%EMC Min >= 5%) AND (%EMC Max <= 12.5%) AND (0.5% < %DC <= 1.5%) => OK
        if (
            emc_min >= 5.0 &&
            emc_max <= 12.5 &&
            dc_span > 0.5 &&
            dc_span <= 1.5
        ) {
            return Risks.OK;
        }

        // * (%EMC Min < 5%) OR (%EMC Max > 12.5%) OR (%DC > 1.5%) => DANGER
        return Risks.DANGER;
    }

    /**
     * Calculates the mold risk, which measures the
     * risk of mold germination and the potential for mold growth.
     *
     * This is measured using:
     * - Mold Risk Factor (MRF)
     *
     * ```
     * MRF <= 0.5 => GOOD
     * MRF > 0.5 => DANGER
     * ```
     *
     * @param {Pick<ResourceRisksData, 'mrf'>} data Provides access to the required metrics.
     * @returns {typeof Risks[keyof Risks]}
     */
    static calculateMoldGerminationRisk({ mrf }) {
        if (is.nullOrUndefined(mrf) || is.nan(mrf)) {
            return null;
        }
        // * `MRF <= 0.5 => GOOD`, `MRF > 0.5 => DANGER`.
        return mrf <= 0.5 ? Risks.GOOD : Risks.DANGER;
    }

    /**
     * Calculates the metal corrosion risk, which measures the
     * risk of environmentally-induced corrosion of metals.
     *
     * This is measured using:
     * - Maximum Equilibrium Moisture Content (% EMC Max)
     *
     * ```
     * %EMC Max <= 7.0 => GOOD
     * 7.0 < %EMC Max <= 10.5 => OK
     * %EMC Max > 10.5 => DANGER
     * ```
     *
     * @param {Pick<ResourceRisksData, 'emc_max'>} data Provides access to the required metrics.
     * @returns {typeof Risks[keyof Risks]}
     */
    static calculateMetalCorrosionRisk({ emc_max }) {
        if (is.nullOrUndefined(emc_max) || is.nan(emc_max)) {
            return null;
        }

        // * `%EMC Max <= 7.0 => GOOD`
        if (emc_max <= 7.0) {
            return Risks.GOOD;
        }

        // * `7.0 < %EMC Max <= 10.5 => OK`
        if (emc_max > 7.0 && emc_max <= 10.5) {
            return Risks.OK;
        }

        // * `%EMC Max > 10.5 => DANGER`
        return Risks.DANGER;
    }

    /**
     * Define resource risks model.
     * @param {Partial<ResourceRisksByMetric>} [attrs]
     */
    constructor(attrs = {}) {
        this.start_date = attrs.start_date ?? null;
        this.end_date = attrs.end_date ?? null;
        this.T = attrs.T ?? new MeasuresOfTemperature({});
        this.RH = attrs.RH ?? new MeasuresOfRelativeHumidity({});
        this.DP = attrs.DP ?? new MeasuresOfDewpoint({});
        this.MOLD = attrs.MOLD ?? new MeasuresOfMoldRisk({});
        this.TWPI =
            attrs.TWPI ?? new MeasuresOfLastTimeWeightedPreservationIndex({});
        this.DC = attrs.DC ?? new MeasuresOfDimensionalChange({});
        this.EMC = attrs.EMC ?? new MeasuresOfEquilibriumMoistureContent({});
    }
}
