/**
 * Event IDs for the report download operation.
 */
export const ReportDownloadEvent = Object.freeze({
    clicked: 'download::clicked',
    success: 'download::success',
    failure: 'download::failure',
    finished: 'download::finished',
});

// <!-- DEFAULT -->
export default ReportDownloadEvent;
