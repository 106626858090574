// <!-- API -->
import { ref, computed } from 'vue';
import { useECNBCache, ECNBCache } from '@/hooks/store/useECNBCache';

// <!-- TYPES -->
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';

/**
 * Using a cache (or with a new instance), handle index operations.
 * @param {ECNBCache} [vuexCache]
 */
export const useLocationHierarchyIndex = (vuexCache) => {
    // STORE

    /** @type {ECNBCache} */
    const cache = vuexCache ?? useECNBCache();

    // STATE

    /** @type {V.Ref<Boolean>} */
    const isFetching = ref(false);

    // COMPUTED PROPERTIES

    /** @type {V.ComputedRef<LocationResource[]>} */
    const locations = computed(() => {
        const locationsCache = cache.locations.value;
        const isEmpty = locationsCache.is.empty;
        return isEmpty ? [] : locationsCache.all.all();
    });

    /** @type {V.ComputedRef<LocationHierarchyResource[]>} */
    const hierarchies = computed(() => {
        const hierarchiesCache = cache.hierarchies.value;
        const isEmpty = hierarchiesCache.is.empty;
        return isEmpty ? [] : hierarchiesCache.all.all();
    });

    /** @type {V.ComputedRef<Set<Number>>} */
    const unassigned = computed(() => {
        const hierarchiesCache = cache.hierarchies.value;
        /** @type {Set<Number>} */
        const ids = hierarchiesCache.attributes.get('unassigned') ?? new Set();
        return ids;
    });

    // METHODS

    /**
     * Refetch the cached index for the page.
     * @param {Boolean} forceReload
     */
    const refreshLocationHierarchyIndex = async (forceReload = false) => {
        try {
            isFetching.value = true;
            const ignoreCache = forceReload === true;
            await cache.fetch.hierarchies({ ignoreCache });
            return hierarchies.value;
        } catch (err) {
            console.error(err);
        } finally {
            isFetching.value = false;
        }
    };

    /**
     * Refetch the cached unassigned locations.
     * @param {Boolean} forceReload
     * @returns {Promise<LocationResource[]>}
     */
    const refreshUnassignedLocations = async (forceReload = false) => {
        try {
            isFetching.value = true;
            await cache.fetch.locations({ ignoreCache: forceReload });
            // Filter locations by unassigned.
            const filteredLocations = locations.value.filter((location) => {
                return (
                    unassigned.value.size > 0 &&
                    unassigned.value.has(location.id)
                );
            });
            return filteredLocations;
        } finally {
            isFetching.value = false;
        }
    };

    return {
        refreshLocationHierarchyIndex,
        refreshUnassignedLocations,
        isFetching,
        cache,
        hierarchies,
        unassigned,
    };
};
