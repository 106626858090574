<template>
    <DotsHorizontalIcon />
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import { DotsHorizontalIcon } from '@heroicons/vue/solid';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'LoadingDots',
        components: {
            DotsHorizontalIcon,
        },
    });
</script>
