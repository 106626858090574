/**
 * Status of the download operation.
 */
export const ReportDownloadStatus = Object.freeze({
    idle: null,
    requesting: 'requesting',
    downloading: 'downloading',
});

// <!-- DEFAULT -->
export default ReportDownloadStatus;
