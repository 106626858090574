// <!-- API -->
import { computed, ref, watch } from 'vue';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import debounce from 'just-debounce-it';

// <!-- TYPES -->

/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

// <!-- DEFINITION -->
/**
 * Define component interface.
 * @returns {ISidebarCollapseBehaviour}
 */
export const useSidebarCollapser = () => {
    // <!-- LOCAL TYPES -->
    /** @typedef {ReturnType<useState>} ISidebarCollapseState */
    /** @typedef {ReturnType<useConstants>} ISidebarCollapseConstants */
    /** @typedef {ReturnType<useProperties>} ISidebarCollapseProperties */
    /** @typedef {ReturnType<useMethods>} ISidebarCollapseMethods */

    /**
     * @typedef {Object} ISidebarCollapseBehaviour
     * @property {ISidebarCollapseState} state
     * @property {ISidebarCollapseConstants} constants
     * @property {ISidebarCollapseProperties} properties
     * @property {ISidebarCollapseMethods} methods
     */

    // <!-- COMPOSABLES -->
    /**
     * Define reactive state.
     */
    const useState = () => {
        /**
         * @type {V.Ref<HTMLDivElement>}
         * Sidebar `<div>` element. Available after mounting.
         */
        const sidebar = ref(null);
        /**
         * @type {V.Ref<HTMLButtonElement>}
         * Sidebar collapse arrow `<button>` element. Available after mounting.
         */
        const sidebarToggle = ref(null);
        /**
         * @type {V.Ref<NodeJS.Timer>}
         * Resize interval timer.
         */
        const resizeTimer = ref(undefined);
        /**
         * @type {V.Ref<Set<ISidebarCollapseConstants['Status'][keyof ISidebarCollapseConstants['Status']]>>}
         * Current status effects applied to the sidebar.
         */
        const conditions = ref(new Set());
        // <!-- EXPOSE -->
        return {
            sidebar,
            sidebarToggle,
            resizeTimer,
            conditions,
        };
    };

    /**
     * Define static constants.
     */
    const useConstants = () => {
        /** Enum containing Status */
        const Status = Object.freeze({
            collapse: 'collapse', // Forcibly collapse.
            expand: 'expand', // Forcibly expand.
            tiny: 'tiny', // Collapse if not forcibly expanded.
        });
        /** Enum containing Status */
        const Tooltips = Object.freeze({
            collapse: 'Click to collapse sidebar.',
            expand: 'Click to expand sidebar.',
        });
        /** @type {Number} Breakpoint at which sidebar should default to 'collapse' on initial load. */
        const ScreenBreakpoint = 640;
        /** @type {Number} Resize refresh rate. */
        const ResizeRate = 5;
        // <!-- EXPOSE -->
        return {
            Status,
            Tooltips,
            ScreenBreakpoint,
            ResizeRate,
        };
    };

    /**
     * Define computed properties.
     * @param {Pick<ISidebarCollapseBehaviour, 'state' | 'constants'>} context
     */
    const useProperties = (context) => {
        // <!-- DESTRUCTURE -->
        const { state, constants } = context;
        const { Status, Tooltips } = constants;
        /** Define conditionals. */
        const useConditionals = () => {
            /** @type {V.ComputedRef<Boolean>} */
            const isCollapsed = computed(() => {
                const status = state.conditions.value;
                const isCollapsedByUser = status.has(Status.collapse);
                const isCollapsedBySize = status.has(Status.tiny);
                const isExpandedByUser = status.has(Status.expand);
                // When screen is "tiny", sidebar is collapsed unless force expanded.
                return (
                    !isExpandedByUser &&
                    (isCollapsedByUser || isCollapsedBySize)
                );
            });

            /** @type {V.ComputedRef<Boolean>} */
            const isExpanded = computed(() => {
                const isNotCollapsed = isCollapsed.value !== true;
                return isNotCollapsed;
            });

            /** @type {V.ComputedRef<Boolean>} */
            const isScreenTiny = computed(() => {
                const status = state.conditions.value;
                return status.has(Status.tiny);
            });

            // <!-- EXPOSE -->
            return {
                isCollapsed,
                isExpanded,
                isScreenTiny,
            };
        };
        /** Define computed properties. */
        const useComputedProperties = () => {
            /** @type {V.ComputedRef<Tooltips[keyof Tooltips]>} */
            const sidebarTooltip = computed(() => {
                const isCollapsed = conditionals.isCollapsed.value;
                const useCollapsePrompt = isCollapsed !== true;
                if (useCollapsePrompt) {
                    return Tooltips.collapse;
                } else {
                    return Tooltips.expand;
                }
            });
            // <!-- EXPOSE -->
            return {
                sidebarTooltip,
            };
        };
        // <!-- DEFINE -->
        const conditionals = useConditionals();
        const properties = useComputedProperties();
        // <!-- EXPOSE -->
        return {
            ...conditionals,
            ...properties,
        };
    };

    /**
     * Define component methods.
     * @param {Pick<ISidebarCollapseBehaviour, 'state' | 'constants' | 'properties'>} context
     */
    const useMethods = (context) => {
        // <!-- DESTRUCTURE -->
        const { state, constants, properties } = context;
        const { Status, ResizeRate, ScreenBreakpoint } = constants;
        // <!-- HELPERS -->
        /** Define functional methods. */
        const useActions = () => {
            /**
             * Add or remove condition.
             *
             * @param {keyof Status} id
             * @param {Boolean} value
             */
            const setCondition = (id, value) => {
                if (value === true) {
                    addCondition(id);
                } else {
                    removeCondition(id);
                }
            };

            /** @type {(id: keyof Status) => void} Add the specified condition, if it is not present. */
            const addCondition = (id) => {
                const status = new Set(state.conditions.value);
                const update = status.add(id);
                state.conditions.value = update;
            };

            /** @type {(id: keyof Status) => void} Remove the specified condition, if it is present. */
            const removeCondition = (id) => {
                const status = new Set(state.conditions.value);
                status.delete(id);
                state.conditions.value = status;
            };

            /** Begin sidebar collapse. */
            const collapse = () => {
                // Remove the expand status.
                removeCondition('expand');
                // Add the collapse status.
                addCondition('collapse');
                // Call the toggled event handler.
                handlers.onSidebarConditionUpdated();
            };

            /** Begin sidebar expansion. */
            const expand = () => {
                // Remove the collapse status.
                removeCondition('collapse');
                // Add the expand status.
                addCondition('expand');
                // Call the toggled event handler.
                handlers.onSidebarConditionUpdated();
            };

            /** Clear the timer. */
            const clearTimer = () => {
                if (!isNil(state.resizeTimer.value)) {
                    // Clear timer.
                    const timer = state.resizeTimer.value;
                    clearInterval(timer);
                    state.resizeTimer.value = undefined;
                }
            };

            // <!-- EXPOSE -->
            return {
                collapse,
                expand,
                clearTimer,
                get condition() {
                    return {
                        set: setCondition,
                        add: addCondition,
                        delete: removeCondition,
                    };
                },
            };
        };
        /** Define event handlers. */
        const useEventHandlers = () => {
            /** @type {() => void} Cleanup function called when the sidebar is removed. */
            const onSidebarCleanup = () => {
                // console.log(`[cleanup::sidebar::div]`);
                actions.clearTimer();
            };

            /** @type {V.WatchCallback<HTMLDivElement>} Invoked when the sidebar element is mounted to the DOM. */
            const onSidebarReady = async (next, previous, onCleanup) => {
                if (!isNil(next) && next !== previous) {
                    // When sidebar is initialized...
                    // console.log(`[init::sidebar::div]`);
                    // Register cleanup callback, if sidebar changes while initializing...
                    onCleanup(onSidebarCleanup);
                    // Register `transitionend` callback event.
                    next.addEventListener(
                        'transitionend',
                        onSidebarTransitionEnd
                    );
                    // Detect the screen size.
                    onDetectTinyScreen(document?.documentElement?.clientWidth);
                }
            };

            /** @type {() => void} Cleanup function called when the sidebar button is removed. */
            const onSidebarToggleCleanup = () => {
                // console.log(`[cleanup::sidebar::toggle]`);
            };

            /** @type {V.WatchCallback<HTMLButtonElement>} Invoked when the sidebar button element is mounted to the DOM. */
            const onSidebarToggleReady = async (next, previous, onCleanup) => {
                if (!isNil(next) && next !== previous) {
                    // When sidebar is initialized...
                    // console.log(`[init::sidebar::toggle]`);
                    // Register cleanup callback, if sidebar changes while initializing...
                    onCleanup(onSidebarToggleCleanup);
                }
            };

            /** @type {() => void} Invoke the window `resize` event. */
            const onSidebarResized = debounce(
                () => {
                    window.dispatchEvent(new Event('resize'));
                },
                ResizeRate,
                true
            );

            /**
             * Event handler for when sidebar is collapsed or expanded.
             */
            const onSidebarConditionUpdated = () => {
                // Begin resizing.
                state.resizeTimer.value = setInterval(
                    () => handlers.onSidebarResized(),
                    ResizeRate
                );
            };

            /**
             * Event handler when collapse transition or expand transition ends.
             * @param {TransitionEvent} event
             */
            const onSidebarTransitionEnd = (event) => {
                // Clear timer.
                actions.clearTimer();
                // Fire resize event at least once.
                onSidebarResized();
            };

            /**
             * Event handler for when the toggle button is clicked.
             * @param {Event} event
             */
            const onSidebarToggleClicked = async (event) => {
                if (!isNil(state.sidebarToggle.value)) {
                    // Event only takes effect while sidebar toggle reference exists.
                    // const button = state.sidebarToggle.value;
                    console.log(`[click::sidebar::toggle]`, event);
                    if (properties.isCollapsed.value === true) {
                        actions.expand();
                    } else {
                        actions.collapse();
                    }
                }
            };

            /**
             * Event handler for when the sidebar is clicked.
             * @param {Event} event
             */
            const onSidebarClicked = async (event) => {
                if (!isNil(state.sidebar.value)) {
                    // Event only takes effect while sidebar reference exists.
                    // const sidebar = state.sidebar.value;
                    if (properties.isCollapsed.value === true) {
                        // If collapsed, swallow event propagation.
                        event.preventDefault();
                        event.stopPropagation();
                        // Only allow expansion on click, when already collapsed.
                        console.log(`[click::sidebar::div]`, event);
                        actions.expand();
                        return;
                    } else {
                        // alert("prevented");
                    }
                }
            };

            /**
             * Listen to the screen size.
             * @param {Number} clientWidth
             */
            const onDetectTinyScreen = async (clientWidth = undefined) => {
                if (!isNil(clientWidth)) {
                    // Store previous tiny status.
                    const wasTiny = state.conditions.value.has('tiny');
                    // Set the tiny status based on the client width.
                    const isTiny = clientWidth <= ScreenBreakpoint;
                    actions.condition.set('tiny', isTiny === true);
                    // If it was not tiny before, remove the expand and collapse flags.
                    if (!wasTiny && isTiny) {
                        actions.condition.delete('collapse');
                        actions.condition.delete('expand');
                    }
                    // When changed, log.
                    // console.log(`[clientwidth::update]`, {
                    //     clientWidth,
                    //     isScreenTiny: properties.isScreenTiny.value,
                    // });
                }
            };

            /** Debounced event for detecting small screens on resize. */
            const onClientWidthUpdate = debounce(
                /** @param {Number} clientWidth */
                (clientWidth) => {
                    // Update the tiny screen size.
                    onDetectTinyScreen(clientWidth);
                },
                200,
                true
            );

            /** @param {UIEvent} ev */
            const onWindowResize = (ev) => {
                // console.dir(ev.currentTarget);
                if ('document' in ev.currentTarget) {
                    const w = /** @type {Window} */ (ev.currentTarget);
                    const element = w?.document?.documentElement;
                    const clientWidth = element?.clientWidth;
                    onClientWidthUpdate(clientWidth);
                }
            };

            // <!-- EXPOSE -->
            return {
                onSidebarReady,
                onSidebarResized,
                onSidebarToggleReady,
                onSidebarConditionUpdated,
                onSidebarTransitionEnd,
                onSidebarToggleClicked,
                onSidebarClicked,
                onDetectTinyScreen,
                onClientWidthUpdate,
                onWindowResize,
            };
        };
        // <!-- DEFINE -->
        const actions = useActions();
        const handlers = useEventHandlers();
        // <!-- EXPOSE -->
        return {
            ...actions,
            ...handlers,
        };
    };

    // <!-- DEFINE -->
    const state = useState();
    const constants = useConstants();
    const properties = useProperties({ state, constants });
    const methods = useMethods({ state, constants, properties });

    // <!-- EXPOSE -->
    return {
        state,
        constants,
        properties,
        methods,
    };
};

// <!-- DEFAULT -->
export default useSidebarCollapser;
