<template>
    <div class="m-6">
        <slot></slot>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'PrimaryLargeButton',
    });
</script>
