// <!-- CONTROLLERS -->

import { ReportAlerts } from '../../utils/reports';

// <!-- COMPOSABLES -->

import { useAlerts } from '@/components/alerts/hooks/useAlerts';

// <!-- COMPOSABLE -->
/**
 * Use a report download instance.
 * @param {Combine<{ alerts?: ReturnType<useAlerts>, delay?: MaybeComputedRef<number> }>} options
 */
export const useReportAlerts = (options = {}) => {
    // Internal controller API.
    const controller = new ReportAlerts(options);

    /**
     * Manually dismiss alert on button click.
     * @param {import('../useReportAlerts').AlertDef} e
     */
    const forceDismiss = (e) => {
        controller.dismiss(String(e.id), false);
    };

    // Expose the api.
    return {
        ...controller.state,
        ...controller.properties,
        ...controller.lifecycle,
        /** @type {ReportAlerts['clear']} */
        reset: controller.clear.bind(controller),
        /** @type {ReportAlerts['clicked']} */
        clicked: controller.clicked.bind(controller),
        /** @type {ReportAlerts['success']} */
        success: controller.success.bind(controller),
        /** @type {ReportAlerts['failure']} */
        failure: controller.failure.bind(controller),
        /** @type {ReportAlerts['dismiss']} */
        dismiss: controller.dismiss.bind(controller),
        forceDismiss,
    };
};

// <!-- DEFAULT -->
export default useReportAlerts;
