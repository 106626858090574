// <!-- REACTIVITY API -->
import { ref, computed } from 'vue';
import { resolveUnref } from '@vueuse/core';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useNARAFeature } from '@/utils/features/';

// <!-- UTILITIES -->
import { Maybe } from 'true-myth/dist/maybe';

// <!-- TYPE DEFINITIONS -->
/** @typedef {'default' | 'commercial' | 'nara'} EnvironmentVisibilityRule */
/** @typedef {'super' | 'admin' | 'data-manager' | 'data-analyst' | 'guest'} RoleVisibilityRole */
/** @typedef {Tab & { environment: EnvironmentVisibilityRule, visibleFor: string[] }} TabWithVisibility */

/**
 * Create appropriate state and hooks for using tabs.
 * @param {{ commercial: Tab[] | Vue.Ref<Tab[]>, nara: Tab[] | Vue.Ref<Tab[]>}} defs Local state to use.
 */
export const useNARATabsIfEnabled = (defs) => {
    // Create reactive reference.
    const commercialTabs = ref(defs.commercial);
    const naraTabs = ref(defs.nara);

    const { isNARAEnabled } = useNARAFeature();

    const tabs = computed(() => {
        const areNARATabsVisible = isNARAEnabled.value;
        return [
            ...commercialTabs.value,
            ...(areNARATabsVisible ? naraTabs.value : []),
        ].sort((a, b) => {
            return (a?.position ?? Infinity) - (b?.position ?? Infinity);
        });
    });

    // Expose reactive state and hooks.
    return { tabs };
};

/**
 * Create appropriate state and hooks for using tabs.
 * @param {{ [key: string]: TabWithVisibility | Vue.Ref<TabWithVisibility> }} definitions Local state to use.
 */
export const useAdminTabs = (definitions) => {
    // Get all tab definitions as computed references.
    const tabReferences = Object.values(definitions).map((value) =>
        resolveUnref(value)
    );

    /** @type {{ [key: string]: Vue.Ref<TabWithVisibility[]> }} */
    const tabGroup = {
        commercial: ref(
            tabReferences.filter((tab) =>
                ['default', 'commercial'].includes(tab.environment)
            )
        ),
        nara: ref(
            tabReferences.filter((tab) =>
                ['default', 'nara'].includes(tab.environment)
            )
        ),
    };

    // COMPOSABLES

    // Get the environment flags.
    const { isNARAEnabled } = useNARAFeature();

    // Get the store composable.
    const store = useStore();

    // COMPUTED PROPERTIES

    /** @type {Vue.ComputedRef<User.Model>} */
    const user = computed(() => store.state.users.me);

    /** @type {Vue.ComputedRef<Organization.Model>} */
    const organization = computed(() => store.state.accounts.organization);

    /** @type {Vue.ComputedRef<'super' | 'admin' | 'data-manager' | 'data-analyst' | 'guest'>} */
    const userRole = computed(() => {
        const currentUser = user.value;
        const currentOrganization = organization.value;

        // Super users will always have the IPI admin role.
        if (currentUser && currentUser.isSuperUser) {
            return 'super';
        }

        // If not a super user, get the organization admin level.
        const role = Maybe.of(currentOrganization?.access?.userRole);
        return role.unwrapOr('guest');
    });

    /** @type {Vue.ComputedRef<'nara' | 'commercial'>} */
    const environment = computed(() => {
        return isNARAEnabled.value ? 'nara' : 'commercial';
    });

    /** @type {Vue.ComputedRef<TabWithVisibility[]>} */
    const environmentTabs = computed(() => {
        const key = environment.value;
        const tabReference = tabGroup[key];
        const tabArray = tabReference?.value ?? [];
        return tabArray;
    });

    /** @type {Vue.ComputedRef<TabWithVisibility[]>} */
    const tabs = computed(() => {
        const currentRole = userRole.value;
        const availableTabs = environmentTabs.value;
        const permittedTabs = availableTabs
            .filter((tab) => {
                return tab.visibleFor.includes(currentRole);
            })
            .sort((a, b) => {
                return (a?.position ?? Infinity) - (b?.position ?? Infinity);
            });
        return permittedTabs;
    });

    // Expose reactive state and hooks.
    return {
        tabs,
        user,
        organization,
        userRole,
    };
};
