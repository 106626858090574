// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import { promiseTimeout } from '@vueuse/core';

// <!-- MODELS -->
import { CustomReportTemplate } from '@/models/v1/reports';
import { mockFetchComponents } from '../components';

// <!-- ROUTES -->
/**
 * @private @class Report component API route resolver.
 */
class Routes {
    /**
     * Resolve the index route.
     * @type {(account: number) => string}
     */
    static index = (account) => `accounts/${account}/reports/templates`;
    /**
     * Resolve the show resource route.
     * @type {(account: number, template: number) => string}
     */
    static show = (account, template) =>
        `accounts/${account}/reports/templates/${template}`;
    /**
     * Resolve the create resource route.
     * @type {(account: number) => string}
     */
    static create = (account) => `accounts/${account}/reports/templates`;
    /**
     * Resolve the update resource route.
     * @type {(account: number, template: number) => string}
     */
    static update = (account, template) =>
        `accounts/${account}/reports/templates/${template}`;
    /**
     * Resolve the destroy resource route.
     * @type {(account: number, template: number) => string}
     */
    static destroy = (account, template) =>
        `accounts/${account}/reports/templates/${template}`;
}

/**
 * Fetch all available report templates by account.
 * @param {{ account: number }} params
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportTemplate[], error?: unknown }>}
 */
export const fetchAccountTemplates = async (params, config) => {
    try {
        /** @type {import('axios').AxiosResponse<{ templates: import('@/models/v1/reports/CustomReportTemplate').ReportTemplatePayload[] }>} */
        const response = await axios().get(
            Routes.index(params.account),
            config ?? {}
        );
        const collection = response.data.templates;
        const templates = collection.map(CustomReportTemplate.fromPayload);
        return {
            data: templates,
            error: null,
        };
    } catch (e) {
        console.error(e);
        return {
            data: [],
            error: e,
        };
    }
};

/**
 * Mock call to fetch all available report templates by account.
 * @param {{ account: number }} params
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportTemplate[], error?: unknown }>}
 */
export const mockFetchAccountTemplates = async (params, config) => {
    // GET mocked components.
    const components = (await mockFetchComponents(config)).data;

    // CALCULATE random delay between 1 and 5 seconds.
    const delay = { ms: 1000 * Math.floor(Math.random() * 5) + 1 };

    // MOCK network connection.
    await promiseTimeout(delay.ms);

    // RETURN mocked templates.
    const templates = [
        CustomReportTemplate.fromAttributes(
            1,
            'Template (1/4/6)',
            params.account,
            null,
            [
                components.find((c) => c.id === 1),
                components.find((c) => c.id === 4),
                components.find((c) => c.id === 6),
            ]
        ),
        CustomReportTemplate.fromAttributes(
            2,
            'Template (Empty)',
            params.account,
            null,
            []
        ),
        CustomReportTemplate.fromAttributes(
            3,
            'Template (All)',
            params.account,
            null,
            [...components]
        ),
    ];

    // RETURN mocked response.
    return {
        data: templates,
        error: null,
    };
};

/**
 * Fetch report template by id and account.
 * @param {{ account: number, id: number }} params Route parameters.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportTemplate, error?: unknown }>}
 */
export const fetchAccountTemplateById = async (params, config) => {
    try {
        /** @type {import('axios').AxiosResponse<{ template: import('@/models/v1/reports/CustomReportTemplate').ReportTemplatePayload }>} */
        const response = await axios().get(
            Routes.show(params.account, params.id),
            config ?? {}
        );
        const template = CustomReportTemplate.fromPayload(
            response.data.template
        );
        return {
            data: template,
            error: null,
        };
    } catch (e) {
        console.error(e);
        return {
            data: null,
            error: e,
        };
    }
};

/**
 * Mock call to fetch report template by id and account.
 * @param {{ account: number, id: number }} params Route parameters.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportTemplate, error?: unknown }>}
 */
export const mockFetchAccountTemplateById = async (params, config) => {
    const template = (await mockFetchAccountTemplates(params, config)).data[0];
    template.id = params.id;
    return {
        data: template,
        error: null,
    };
};

/**
 * Create new report template with a valid name and non-empty components array.
 * @param {{ account: number }} params Route parameters.
 * @param {{ name: string, components: number[] }} body Request body.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportTemplate, error?: unknown }>}
 */
export const createAccountTemplate = async (params, body, config) => {
    try {
        /** @type {import('axios').AxiosResponse<{ template: import('@/models/v1/reports/CustomReportTemplate').ReportTemplatePayload }, typeof body>} */
        const response = await axios().post(
            Routes.create(params.account),
            body,
            config ?? {}
        );
        const template = new CustomReportTemplate(response.data.template);
        return {
            data: template,
            error: null,
        };
    } catch (e) {
        console.error(e);
        return {
            data: null,
            error: e,
        };
    }
};

/**
 * Create new report template with a valid name and non-empty components array.
 * @param {{ account: number }} params Route parameters.
 * @param {{ name: string, components: number[] }} body Request body.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportTemplate, error?: unknown }>}
 */
export const mockCreateAccountTemplate = async (params, body, config) => {
    const template = (await mockFetchAccountTemplates(params, config)).data[0];
    return {
        data: template,
        error: null,
    };
};

/**
 * Delete report template by account and id.
 * @param {{ account: number, id: number }} params Route parameters.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ error?: unknown }>}
 */
export const deleteAccountTemplateById = async (params, config) => {
    try {
        /** @type {import('axios').AxiosResponse<never>} */
        const response = await axios().delete(
            Routes.destroy(params.account, params.id),
            config ?? {}
        );
        const status = response.status;
        switch (status) {
            case 200:
            case 201:
            case 204:
                return { error: null };
            default:
                throw new Error(
                    `${response.statusText} - Failed to delete the template.`
                );
        }
    } catch (e) {
        console.error(e);
        return {
            error: e,
        };
    }
};

// <!-- DEFAULT -->
export default {
    fetchAccountTemplates,
    fetchAccountTemplateById,
    createAccountTemplate,
    deleteAccountTemplateById,
    mockFetchAccountTemplates,
    mockFetchAccountTemplateById,
    mockCreateAccountTemplate,
};
