/**
 * @typedef {MeasureLabelByType[keyof typeof MeasureLabelByType]} MeasureLabel
 * Indicates the measure name.
 */
export const MeasureLabelByType = /** @type {const} */ ({
    min: 'Min',
    max: 'Max',
    mean: 'Mean',
    median: 'Median',
    stddev: 'Std. Dev.',
    above: '% Above',
    in: '% In',
    below: '% Below',
    above_count: 'Above (Count)',
    in_count: 'In (Count)',
    below_count: 'Below (Count)',
    mrf: 'Mold Risk Factor',
    last_twpi: 'Last TWPI',
    span: 'DC% Span',
});
