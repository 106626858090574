<template>
    <div class="flex flex-nowrap flex-row max-h-10">
        <component
            v-if="!!state.risk?.value"
            :is="state.icon.value.value"
            class="h-6 w-6"
            :style="[`stroke: ${state.color.value}`]"
            aria-hidden="true"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed, shallowRef } from 'vue';

    // <!-- UTILITIES -->
    import isNil from 'lodash-es/isNil';

    // <!-- COMPONENTS -->
    import RedTriangleIcon from '~Analysis/components/icons/RedTriangleIcon.vue';
    import YellowCircleIcon from '~Analysis/components/icons/YellowCircleIcon.vue';
    import GreenSquareIcon from '~Analysis/components/icons/GreenSquareIcon.vue';
    import HyphenIcon from '~Analysis/components/icons/HyphenIcon.vue';

    /**
     * @class
     * Risk structure.
     */
    export class Risk {
        /**
         * Instantiate a Risk.
         * @param {Object} [attributes] Attributes.
         * @param {1 | 2 | 3} [attributes.value] Risk value.
         * @param {String} [attributes.color] Risk color.
         */
        constructor(attributes) {
            // <!-- DESTRUCTURE -->
            const { value = null, color = null } = attributes ?? {};
            // <!-- ASSIGN -->
            /** @type {1 | 2 | 3 | null} Risk value. */
            this.value = value ?? null;
            /** @type {String} Risk color. */
            this.color = color ?? '#000000';
        }

        get label() {
            switch (this.value) {
                case 1:
                    return 'GOOD';
                case 2:
                    return 'OK';
                case 3:
                    return 'RISK';
                default:
                    return '';
            }
        }
    }

    /**
     * @typedef {AgGrid.ICellRendererParams} ColorIconCellParams
     */
    export default defineComponent({
        name: 'ColorIconCellRenderer',
        components: {
            RedTriangleIcon,
            YellowCircleIcon,
            GreenSquareIcon,
            HyphenIcon,
        },
        props: {
            params: {
                /** @type {V.PropType<ColorIconCellParams>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            /** Get the cell renderer parameters. */
            const { params } = toRefs(props);

            /** @type {V.ComputedRef<Risk>} */
            const risk = computed(() =>
                !!params.value ? new Risk(params.value.value) : null
            );

            /** @type {V.ComputedRef<V.ShallowRef<V.Component>>} */
            const icon = computed(() => {
                switch (risk?.value?.value ?? 0) {
                    case 1:
                        return shallowRef(GreenSquareIcon);
                    case 2:
                        return shallowRef(YellowCircleIcon);
                    case 3:
                        return shallowRef(RedTriangleIcon);
                    default:
                        return shallowRef(HyphenIcon);
                }
            });

            /** @type {V.ComputedRef<string>} */
            const color = computed(() => {
                return !!risk?.value?.color ? risk.value.color : '#000000';
            });

            return {
                state: {
                    risk,
                    icon,
                    color,
                },
            };
        },
    });
</script>

<style lang="scss"></style>
