// <!-- UTILITIES -->
import {
    Metric,
    Measure,
    MeasuresOfCenter,
    MeasuresOfDistribution,
} from '@/utils/measures';

// <!-- TYPES -->
/**
 * @typedef {MeasuresOfCenter<'T'>
 * & Pick<MeasuresOfDistribution<'T'>, 'above' | 'in' | 'below' | 'above_count' | 'in_count' | 'below_count'>
 * } TemperatureMeasureRecord
 */

/** @implements {TemperatureMeasureRecord} */
export class MeasuresOfTemperature {
    /**
     * Define resource measure class instance.
     * @param {Partial<TemperatureMeasureRecord>} [attrs]
     */
    constructor(attrs) {
        this.metric = Metric.T;
        this.min = attrs?.min?.clone() ?? new Measure({ type: 'min' });
        this.max = attrs?.max?.clone() ?? new Measure({ type: 'max' });
        this.mean = attrs?.mean?.clone() ?? new Measure({ type: 'mean' });
        this.median = attrs?.median?.clone() ?? new Measure({ type: 'median' });
        this.stddev = attrs?.stddev?.clone() ?? new Measure({ type: 'stddev' });
        this.above = attrs?.above?.clone() ?? new Measure({ type: 'above' });
        this.in = attrs?.in?.clone() ?? new Measure({ type: 'in' });
        this.below = attrs?.below?.clone() ?? new Measure({ type: 'below' });
        this.above_count =
            attrs?.above_count?.clone() ?? new Measure({ type: 'above_count' });
        this.in_count =
            attrs?.in_count?.clone() ?? new Measure({ type: 'in_count' });
        this.below_count =
            attrs?.below_count?.clone() ?? new Measure({ type: 'below_count' });
    }
}
