// <!-- CONTROLLERS -->
import { ReportDownload } from '../../utils/reports';

// <!-- COMPOSABLE -->
/**
 * Use a report download instance.
 * @param {Combine<{ tag?: MaybeComputedRef<string>, delay?: MaybeComputedRef<number> }>} options
 */
export const useReportDownload = (options = {}) => {
    // Internal controller API.
    const downloader = new ReportDownload(options);
    return {
        ...downloader.state,
        ...downloader.properties,
        ...downloader.lifecycle,
        /** @type {ReportDownload['download']} */
        download: downloader.download.bind(downloader),
        /** @type {ReportDownload['downloadAs']} */
        downloadAs: downloader.downloadAs.bind(downloader),
    };
};

// <!-- DEFAULT -->
export default useReportDownload;
