// <!-- UTILITIES -->
import { DateRangeFilter } from '@/utils/filters';
import isNil from 'lodash-es/isNil';

/**
 * Utility for formatting the graph title.
 * @param {String} metric Graph metric.
 * @param {String} [unit] Graph unit.
 * @param {Number} [count] Number of datasets loaded.
 * @param {string} [timezone] Timezone.
 * @param {Boolean} [isLoading]
 */
export const formatGraphTitle = (
    metric,
    unit = '',
    count = 0,
    timezone = 'UTC',
    isLoading = false
) => {
    const prefix = !!unit.length ? `${metric} (${unit}) ` : `${metric} `;
    // const suffix = !!count ? ` of ${count} Location(s)` : ``;
    const indicator = isLoading ? ` (Loading...)` : ``;
    return `${prefix}Graph${indicator} (${timezone})`;
};

/**
 * Get the start and end date range.
 * @param {Filters.DateRange.Form.Data} daterange
 */
export const formatGraphSubtitle = (daterange) => {
    const prefix = daterange?.start ?? null;
    const suffix = daterange?.end ?? null;
    const labels = {
        all: '(All Dates)',
        overlap: '(Overlapping Dates)',
        custom: '(Custom)',
    };

    // Select the appropriate tag label.
    let tags = labels.custom;
    if (daterange.overlap) tags = labels.overlap;
    if (daterange.all) tags = labels.all;

    // Compute the subtitle suffix.
    const segments = [prefix, `${suffix} ${tags}`].filter(
        (segment) => !!segment && segment.length > 0
    );

    // Return the subtitle.
    return segments.join(' - ');
};

/**
 * Generate filename.
 * @param {String[]} tags
 */
export const formatGraphFilename = (tags = []) => {
    const timestamp = new Date().toISOString();
    const prefix = `ecnb`;
    const suffixes = !!tags.length ? tags : [];
    const filename = [prefix, 'export', ...suffixes, timestamp]
        .filter((s) => !!s.length)
        .map((s) => s.replace(/[^a-z0-9/[/]]/gi, '_').toLowerCase())
        .join('-');
    return filename;
};

export default {
    formatGraphTitle,
    formatGraphSubtitle,
    formatGraphFilename,
};
