<template>
    <ConfirmDeleteModal
        :title="'Delete Template'"
        confirmationText="Are you sure you want to delete this report template?"
        :show="modals.ConfirmDeleteTemplate.isRevealed.value"
        @delete="modals.ConfirmDeleteTemplate.confirm"
        @cancel="modals.ConfirmDeleteTemplate.cancel"
    />
    <BasicModal
        :show="modals.SaveTemplateAs.isRevealed.value"
        @close="modals.SaveTemplateAs.cancel"
    >
        <PageHeader>Save Template As: </PageHeader>
        <div class="flex justify-start">
            <FormKit
                type="text"
                outer-class="flex-grow m-1"
                inner-class="max-w-full"
                v-model="data.saveTemplateAs.value"
            />
            <ModalButton
                class="ml-4"
                theme="primary"
                label="Save"
                @click="modals.SaveTemplateAs.confirm"
            />
        </div>
    </BasicModal>
    <AnalysisContainer>
        <template #sideBar></template>
        <template #mainContent>
            <RightContainer>
                <!-- <pre>{{ reportAlerts.notifications.value }}</pre> -->
                <!-- <pre>{{ reportAlerts.active.value }}</pre> -->
                <div class="-mt-4">
                    <!-- {{ reportAvailability }} -->
                    <DownloadReportServerHealth
                        :online="isOnline"
                        :available="isGraphingServerAvailable"
                        :busy="isDownloading"
                    />
                    <FormSection
                        class="pt-4"
                        title="Overview Report"
                        description="The overview report provides an informational summary of location data for a specific period of time."
                    >
                        <div class="flex space-x-4">
                            <DownloadReportButton
                                class="mb-4"
                                reportType="overview"
                                @download:click="download"
                                :busy="isDownloading"
                            />
                        </div>
                    </FormSection>
                    <FormSection
                        class="pt-4"
                        title="Performance Report"
                        description="The performance report provides limit analysis of location data."
                    >
                        <div class="flex space-x-4">
                            <DownloadReportButton
                                class="mb-4"
                                reportType="performance"
                                @download:click="download"
                                :busy="isDownloading"
                            />
                        </div>
                    </FormSection>
                    <FormSection
                        class="pt-4 lg:pr-44"
                        :class="{
                            'animate-pulse': data.isBusy.value,
                        }"
                        title="Custom Report"
                        description="Create a custom report by dragging and arranging the
                            desired components into the selected components box.
                            Hover over the dotted lines to the left of the
                            component name to drag a component. Use the Template
                            button to save selected components for future use,
                            or to select a previously created custom report
                            template."
                        :grid="[
                            'grid',
                            'grid-cols-1',
                            'mt-6',
                            'gap-y-4',
                            'lg:grid-cols-2',
                            'max-w-3xl',
                        ]"
                    >
                        <div class="col-span-1 lg:col-span-2">
                            <div class="flex justify-between pr-3">
                                <p class="text-gray-700 pt-2">
                                    <span class="font-bold">
                                        Current Template:
                                    </span>
                                    <span
                                        v-if="!!data.templates.isSelected.value"
                                        v-text="data.currentTemplate.value.name"
                                    />
                                    <span v-else>None selected.</span>
                                </p>
                                <ActionsMenu
                                    class="flex content-center mt-3 md:mt-0"
                                    :class="{
                                        'pointer-events-none cursor-not-allowed':
                                            data.isBusy.value,
                                    }"
                                    :label="'Templates'"
                                >
                                    <div
                                        v-for="template in data.templates.data
                                            .value"
                                        :key="template.id"
                                        class="py-1"
                                    >
                                        <MenuItem v-slot="{ active, disabled }">
                                            <div
                                                :class="{
                                                    'flex items-center justify-between text-sm w-full px-2 py-2': true,
                                                    'text-gray-900 focus:text-gray-800':
                                                        !!active && !disabled,
                                                    'text-gray-700':
                                                        !active || !!disabled,
                                                }"
                                            >
                                                <p class="font-normal">
                                                    {{ template.name }}
                                                </p>
                                                <div
                                                    class="flex flex-row items-center justify-end"
                                                >
                                                    <VariantButton
                                                        variant="icon"
                                                        :title="'Copy Template to Selection'"
                                                        @click="
                                                            trySelectReportTemplate(
                                                                template
                                                            )
                                                        "
                                                    >
                                                        <DocumentDuplicateIcon
                                                            class="h-4 w-4"
                                                            aria-hidden="true"
                                                        />
                                                    </VariantButton>
                                                    <VariantButton
                                                        variant="danger-icon"
                                                        :class="{
                                                            'cursor-not-allowed':
                                                                !isTemplateDeletionAllowed,
                                                        }"
                                                        :title="'Delete Template'"
                                                        :disabled="
                                                            !isTemplateDeletionAllowed
                                                        "
                                                        @click="
                                                            tryDestroyReportTemplate(
                                                                template
                                                            )
                                                        "
                                                    >
                                                        <TrashIcon
                                                            class="h-4 w-4"
                                                            aria-hidden="true"
                                                        />
                                                    </VariantButton>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    </div>
                                    <div
                                        v-if="data.templates.isMissing.value"
                                        class="py-1"
                                    >
                                        <MenuItem disabled>
                                            <div
                                                class="text-gray-700 group flex items-center px-4 py-2 text-sm"
                                            >
                                                <p class="font-bold">
                                                    No Templates Available
                                                </p>
                                            </div>
                                        </MenuItem>
                                    </div>
                                    <div class="py-1">
                                        <MenuItem
                                            :disabled="
                                                !isTemplateCreationAllowed
                                            "
                                            v-slot="{ active, disabled }"
                                        >
                                            <button
                                                :title="
                                                    !!disabled
                                                        ? reasonForTemplateCreationRejection
                                                        : 'Save selected components as a new template.'
                                                "
                                                :disabled="disabled"
                                                :class="{
                                                    'flex items-center justify-between text-sm w-full px-2 py-2': true,
                                                    'bg-gray-100':
                                                        !!active && !disabled,
                                                    'bg-gray-50 text-gray-900 hover:bg-gray-200 focus:bg-gray-100 focus:text-gray-800 cursor-pointer':
                                                        !disabled,
                                                    'bg-gray-100 text-gray-700 cursor-not-allowed':
                                                        !!disabled,
                                                }"
                                                @click="
                                                    tryCreateReportTemplate()
                                                "
                                            >
                                                <p class="font-bold">
                                                    Save As Template
                                                </p>
                                                <div class="p-2">
                                                    <SaveAsIcon
                                                        class="h-4 w-4"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </button>
                                        </MenuItem>
                                    </div>
                                </ActionsMenu>
                            </div>
                        </div>
                        <div class="col-span-2 lg:col-span-1">
                            <AgGridVue
                                id="customReportGrid"
                                class="ag-theme-alpine"
                                style="width: 370px; height: 470px"
                                :rowHeight="null"
                                :defaultColDef="grids.defaultColumnDef"
                                :columnDefs="grids.left.columnDefs"
                                :rowData="grids.left.rowData.value"
                                :rowClassRules="rowClassRules"
                                :animateRows="true"
                                :rowDragManaged="true"
                                :suppressMoveWhenRowDragging="true"
                                :getRowNodeId="grids.getRowNodeIdGetter"
                                @row-drag-end="
                                    grids.onDragEndEvent($event, 'left')
                                "
                                @grid-ready="
                                    grids.onDualListBoxReady($event, 'left')
                                "
                                :overlayNoRowsTemplate="
                                    isComponentListEmpty
                                        ? 'No components available.'
                                        : 'All components selected.'
                                "
                            ></AgGridVue>
                        </div>
                        <div class="col-span-2 lg:col-span-1">
                            <AgGridVue
                                class="ag-theme-alpine"
                                style="width: 370px; height: 470px"
                                :defaultColDef="grids.defaultColumnDef"
                                :columnDefs="grids.right.columnDefs"
                                :rowData="grids.right.rowData.value"
                                :rowClassRules="rowClassRules"
                                :animateRows="true"
                                :rowDragManaged="true"
                                :suppressMoveWhenRowDragging="true"
                                :getRowNodeId="grids.getRowNodeIdGetter"
                                @row-drag-end="
                                    grids.onDragEndEvent($event, 'right')
                                "
                                @grid-ready="
                                    grids.onDualListBoxReady($event, 'right')
                                "
                                :overlayNoRowsTemplate="'No components selected.'"
                            ></AgGridVue>
                        </div>
                        <div class="col-span-2 flex justify-between">
                            <DownloadReportButton
                                class="mb-4"
                                reportType="custom"
                                @download:click="download"
                                :busy="isDownloading"
                                :disabled="
                                    grids.right.isEmpty.value ||
                                    data.isBusy.value
                                "
                            />
                            <ModalButton
                                class="mr-4"
                                theme="white"
                                label="Reset Selection"
                                @click="resetSelection"
                                :disabled="
                                    grids.right.isEmpty.value ||
                                    data.isBusy.value
                                "
                            />
                        </div>
                    </FormSection>
                </div>
            </RightContainer>
        </template>
    </AnalysisContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="reportAlerts.alerts.value"
            @dismiss:alert="reportAlerts.forceDismiss"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        ref,
        computed,
        onBeforeMount,
        onBeforeUnmount,
    } from 'vue';

    // <!-- COMPONENTS -->
    import AnalysisContainer from '~Analysis/components/wrappers/AnalysisContainer.vue';
    import RightContainer from '@/components/RightContainer.vue';
    import PageHeader from '@components/PageHeader.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import ActionsMenu from '@/components/ActionsMenu.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import DownloadReportServerHealth from '../components/card/DownloadReportServerHealth.vue';
    import DownloadReportButton from '../components/buttons/DownloadReportButton.vue';
    import BasicModal from '@components/BasicModal.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import { MenuItem } from '@headlessui/vue';
    import {
        DocumentDuplicateIcon,
        TrashIcon,
        SaveAsIcon,
    } from '@heroicons/vue/outline';

    // <!-- COMPOSABLES -->
    import { useStore } from 'vuex';
    import { useReportTemplate } from '~Analysis/hooks/useReportTemplate';
    import { useReportAlerts } from '~Analysis/hooks/reports/useReportAlerts';
    import { useReportDownload } from '~Analysis/hooks/reports/useReportDownload';
    import { useGraphingServerHeartbeat } from '~Analysis/hooks/reports/useGraphingServerHeartbeat';

    // <!-- UTILITIES -->
    import { Size, Theme, ReportType } from '@/enums';
    import { Maybe } from 'true-myth/dist/maybe';

    // <!-- TYPES -->
    import { ECNBState } from '@/store/types/ECNBStore';
    import CustomReportTemplate from '@/models/v1/reports/CustomReportTemplate';

    /** @template [S=any] @typedef {import('./../../../../node_modules/vuex/types').Store<S>} Store<S> */
    /** @typedef {import('@/utils/datetime').IDate} IDate */
    /** @typedef {'T' | 'RH' |  'DP' | 'PI' | 'TWPI' | 'MOLD' | 'EMC' | 'DC' | 'NARA' | 'STATS'} ChartType */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Reports',
        components: {
            AnalysisContainer,
            RightContainer,
            FormSection,
            ModalButton,
            AgGridVue,
            ConfirmDeleteModal,
            ActionsMenu,
            MenuItem,
            DocumentDuplicateIcon,
            TrashIcon,
            SaveAsIcon,
            VariantButton,
            DownloadReportButton,
            BasicModal,
            BaseAlert,
            DownloadReportServerHealth,
            PageHeader,
        },
        setup() {
            // REPORT TEMPLATE BEHAVIOR

            // DEFINE state for report template functionality.
            const {
                data,
                grids,
                modals,
                isComponentListEmpty,
                isSelectedComponentListEmpty,
                resetSelection,
                initializeDualListBox,
                fetchCustomReportComponents,
                fetchCustomReportTemplates,
                createCustomReportTemplate,
                destroyCustomReportTemplate,
                selectCustomReportTemplate,
            } = useReportTemplate();

            const reportAlerts = useReportAlerts();
            const reportDownloader = useReportDownload();
            const reportAvailability = useGraphingServerHeartbeat();

            /** @type {Record<String, String>} */
            const rowClassRules = null;

            // DOWNLOAD PDF

            /**
             * Vuex store.
             * @type {Store<ECNBState>}
             */
            const store = useStore();

            /** @type {Vue.ComputedRef<User.Model>} */
            const user = computed(() => {
                return store.state.users.me;
            });

            /** @type {Vue.ComputedRef<Organization.Model>} */
            const organization = computed(() => {
                return store.state.accounts.organization;
            });

            /** @type {Vue.ComputedRef<number>} */
            const accountId = computed(() => {
                const id = store.state.accounts.account.id;
                return id;
            });

            /** @type {Vue.ComputedRef<boolean>} */
            const isSuperUser = computed(() => {
                return user.value?.isSuperUser === true;
            });

            /** @type {Vue.ComputedRef<Boolean>} */
            const isAdministrator = computed(() => {
                const role = Maybe.of(organization?.value?.access?.userRole);
                return isSuperUser.value || role.unwrapOr('guest') === 'admin';
            });

            /** @type {Vue.ComputedRef<Boolean>} */
            const isDataAnalyst = computed(() => {
                const role = Maybe.of(organization?.value?.access?.userRole);
                return role.unwrapOr('guest') === 'data-analyst';
            });

            /** @type {Vue.ComputedRef<boolean>} */
            const isTemplateDeletionAllowed = computed(() => {
                return isDataAnalyst.value === false;
            });

            /** @type {Vue.ComputedRef<boolean>} */
            const isTemplateCreationAllowed = computed(() => {
                return (
                    isSelectedComponentListEmpty.value === false &&
                    isDataAnalyst.value === false
                );
            });

            /** @type {Vue.ComputedRef<string>} */
            const reasonForTemplateCreationRejection = computed(() => {
                if (isSelectedComponentListEmpty.value) {
                    return 'One or more components must be selected in order to save a new template.';
                }

                if (isDataAnalyst.value) {
                    return 'You do not have the permission to save a new template.';
                }

                return 'Template creation is allowed.';
            });

            const downloadReport = async (
                /** @type {keyof typeof ReportType['_dictionary']} */ reportType
            ) => {
                reportAlerts.clicked(reportType);
                const charts = grids.right.rowData.value.map((item) => item.id);
                reportDownloader.download(reportType, charts);
            };

            // LIFECYCLE

            reportDownloader.onSuccess((e) => {
                reportAlerts.success(e.reportType);
            });

            reportDownloader.onFailure((e) => {
                reportAlerts.failure(e.reportType, String(e.error));
            });

            // METHODS

            /**
             * Try to select the specified report template.
             * @param {CustomReportTemplate} target
             */
            const trySelectReportTemplate = async (target) => {
                await selectCustomReportTemplate(target.id);
            };

            /**
             * Try to create the specified report template, with a confirmation dialog.
             */
            const tryCreateReportTemplate = async () => {
                await createCustomReportTemplate({ account: accountId.value });
            };

            /**
             * Try to destroy the specified report template, with a confirmation dialog.
             * @param {CustomReportTemplate} target
             */
            const tryDestroyReportTemplate = async (target) => {
                await destroyCustomReportTemplate({
                    account: accountId.value,
                    target,
                });
            };

            // LIFECYCLE

            onBeforeMount(async () => {
                reportAvailability.heartbeat.resume();
                await Promise.allSettled([
                    fetchCustomReportComponents(),
                    fetchCustomReportTemplates({ account: accountId.value }),
                ]);
                initializeDualListBox();
            });

            onBeforeUnmount(() => {
                reportAvailability.heartbeat.pause();
            });

            // EXPOSE

            return {
                Size,
                Theme,
                // STATE
                rowClassRules,
                // REPORT TEMPLATES
                data,
                grids,
                modals,
                isComponentListEmpty,
                isSelectedComponentListEmpty,
                isTemplateDeletionAllowed,
                isTemplateCreationAllowed,
                reasonForTemplateCreationRejection,
                isDownloading: reportDownloader.isBusy,
                isOnline: reportAvailability.isOnline,
                isGraphingServerAvailable: reportAvailability.isAvailable,
                resetSelection,
                trySelectReportTemplate,
                tryCreateReportTemplate,
                tryDestroyReportTemplate,
                // REPORT DOWNLOAD
                download: downloadReport,
                reportAlerts,
            };
        },
    });
</script>
