<template>
    <SidebarFilter
        :title="title"
        :border="border"
        :alerts="[...treeAlerts, ...alerts]"
        @dismiss:alert="onAlertDismissed"
    >
        <template #content>
            <span>
                <div
                    class="grid grid-cols-2 gap-2 mb-2"
                    v-if="showButtons"
                >
                    <VariantButton
                        variant="treeview"
                        v-for="button in buttons"
                        :key="button.label"
                        :label="button.label"
                        @click="button.onButtonClick"
                    />
                </div>
                <div
                    class="flex items-center bg-white max-w-full w-full rounded-md"
                >
                    <div
                        v-if="isBusy"
                        class="w-full p-2 text-center animate-pulse"
                    >
                        <LoadingDots class="animate-ping h-6 mx-auto" />
                        <span class="inline">
                            {{ `Loading ${title}` }}
                        </span>
                    </div>
                    <div
                        v-else-if="!isEmpty"
                        class="w-full p-2"
                    >
                        <!-- <div
                            v-if="debug"
                            class="whitespace-pre-line p-2 rounded-lg bg-gray-50"
                        >
                            {{
                                {
                                    selectedLocations: Object.keys(tree.nodes)
                                        .filter((id) => id.startsWith('l'))
                                        .map((id) => tree.nodes[id])
                                        .filter(
                                            (node) =>
                                                node.state?.checked === true
                                        )
                                        .map((node) => node.id),
                                    buttons: buttons?.length,
                                    config: tree.config,
                                    nodes: Object.keys(tree.nodes).length,
                                    locations: locations.length,
                                    hierarchies: hierarchies.length,
                                    unassigned: unassigned.size,
                                }
                            }}
                        </div> -->
                        <Treeview
                            id="tree-locations"
                            class="w-full min-h-10 overflow-x-auto overflow-y-auto flex-shrink-0"
                            :config="tree.config"
                            :nodes="tree.nodes"
                            :class="menuHeight"
                            @nodeOpened="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeOpenedEvent
                                    )(e)
                            "
                            @nodeClosed="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeClosedEvent
                                    )(e)
                            "
                            @nodeChecked="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeCheckedEvent
                                    )(e)
                            "
                            @nodeUnchecked="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeUncheckedEvent
                                    )(e)
                            "
                        />
                    </div>
                    <div
                        v-else
                        class="w-full p-2 text-center"
                    >
                        {{ `No ${title} available.` }}
                    </div>
                </div>
            </span>
        </template>
    </SidebarFilter>
</template>

<script>
    // <!-- API -->
    import { computed, defineComponent, onMounted, toRef } from 'vue';

    // <!-- COMPONENTS -->
    import SidebarFilter from '@/components/sidebar/SidebarFilter.vue';
    import LoadingDots from '@/components/LoadingDots.vue';
    import VariantButton from '@buttons/VariantButton.vue';
    import Treeview from 'vue3-treeview';

    // <!-- COMPOSABLES -->
    import { useLocationFilter } from '@/components/sidebar/hooks/useLocationFilter';

    // <!-- TYPES -->
    /** @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef */

    // <!-- EVENTS -->
    import { TREEVIEW } from '@/components/sidebar/events';
    import { DismissAlertEvent } from '@/components/alerts/events';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'LocationFilter',
        components: {
            SidebarFilter,
            VariantButton,
            LoadingDots,
            Treeview,
        },
        props: {
            /** Filter section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                required: true,
            },
            /** Treeview menu height. */
            menuHeight: {
                /** @type {V.PropType<String>} */
                type: String,
                default: '',
            },
            /** If true, show the top border. */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Alert props. */
            alerts: {
                /** @type {V.PropType<AlertDef[]>} */
                type: Array,
                default: () => [],
            },
            /** Checked limits. */
            checkedLimit: {
                /** @type {V.PropType<Number>} */
                type: Number,
                default: Infinity,
            },
            /** If true, show the buttons. */
            showButtons: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If true, the items are currently loading. */
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            TREEVIEW.ExpandEvent,
            TREEVIEW.CollapseEvent,
            TREEVIEW.CheckAllEvent,
            TREEVIEW.CheckNoneEvent,
            TREEVIEW.NodeOpenedEvent,
            TREEVIEW.NodeClosedEvent,
            TREEVIEW.NodeCheckedEvent,
            TREEVIEW.NodeUncheckedEvent,
            TREEVIEW.NodeToggleEvent,
            TREEVIEW.NodeFocusEvent,
            TREEVIEW.NodeBlurEvent,
            DismissAlertEvent,
        ],
        setup(props, context) {
            // DESTRUCTURE reactive props.

            /** Maximum allowed locations that can be checked at one time. */
            const checkedLimit = toRef(props, 'checkedLimit');

            // DEFINE composable filter behaviour.

            /** Create the location filter state and methods. */
            const filter = useLocationFilter({ checkedLimit });
            const { service, constants, state, properties, methods } = filter;

            // DESTRUCTURE state.
            const { alerts, buttons, tree } = state;

            // DESTRUCTURE conditionals.
            const { isBusy, isEmpty } = properties;

            // DESTRUCTURE methods.
            const {
                getTreeEventHandler,
                initializeAlerts,
                initializeButtons,
                initializeTree,
                onAlertDismissed,
                listen,
            } = methods;

            /**  */

            // // ==== COMPOSE ====
            // const store = useStore();
            // const alerts = useAlerts();
            // const { createAlert, pushAlert, clearAlert, dismissAlert } =
            //     alerts.methods;
            // const {
            //     state,
            //     selected,
            //     actions,
            //     buttons: buttons_,
            //     locations,
            //     initialize,
            // } = useLocationFilter(store, checkedLimit, props.debug);

            // const buttons = computed(() => {
            //     const _buttons = [...buttons_];
            //     const checkAllButtonIndex = _buttons.findIndex(
            //         (b) => b.label === 'Check All'
            //     );
            //     const checkAllButton = _buttons[checkAllButtonIndex];
            //     // @ts-ignore
            //     checkAllButton.label = !Number.isFinite(checkedLimit.value)
            //         ? 'Check All'
            //         : `Check ${checkedLimit.value}`;
            //     _buttons[checkAllButtonIndex] = checkAllButton;
            //     return _buttons;
            // });

            // // ==== STATE ====
            // const capacityAlerts = alerts.state.alerts;

            // // ==== METHODS ====
            // /**
            //  * Handle alert dismissal, or forward it.
            //  * @param {AlertDef} e
            //  */
            // const handleAlertDismissal = (e) => {
            //     if (capacityAlerts.value.map((def) => def.id).includes(e.id)) {
            //         // If capacity alert is present, dismiss it.
            //         dismissAlert(e);
            //         return;
            //     } else {
            //         // Otherwise, dismiss an external alert.
            //         context.emit(DismissAlertEvent, e);
            //     }
            // };

            // // ==== LIFECYCLE ====
            // // Reset the locations tree.
            // // locations.resetCleanData(store);

            // // Register subscribed actions.
            // store.subscribeAction({
            //     after: async ({ type, payload }, rootState) => {
            //         // When a notification is received:
            //         if (['analysis/notifyLocationTree'].includes(type)) {
            //             // Clear the capacity alert.
            //             clearAlert('at-capacity');
            //             // Push the payload.
            //             if (payload?.length > 0) {
            //                 // Refresh the tree state from the Vuex store.
            //                 actions.onLoadStoreTreeState({
            //                     id: TREEVIEW.LoadEvent,
            //                     event: null,
            //                 });
            //                 // Push the alert with the provided message.
            //                 const message = String(payload);
            //                 const alert = createAlert({
            //                     id: `at-capacity`,
            //                     type: `warning`,
            //                     title: `Too many selected`,
            //                     content: message,
            //                     dismissable: true,
            //                 });
            //                 pushAlert(alert);
            //             }
            //         }
            //     },
            // });

            // Initialize buttons before mounting.
            initializeButtons();

            // Run once mounted.
            onMounted(async () => {
                // Trigger fetch requests (if not cached).
                await service.hierarchyIndex.refreshLocationHierarchyIndex(
                    true
                );
                await service.locationIndex.refreshLocationIndex(true);
                // Reset alerts.
                initializeAlerts();
                // Initialize the tree.
                initializeTree();
                // Initialize watchers.
                listen();
            });

            // ==== EXPOSE ====
            return {
                // EVENTS
                TREEVIEW,
                // STATE,
                treeAlerts: alerts,
                buttons,
                // PROPERTIES,
                tree,
                // CONDITIONALS
                isBusy,
                isEmpty,
                // METHODS,
                getTreeEventHandler,
                onAlertDismissed,
                // DEBUG
                state,
                locations: service.locationIndex.locations,
                hierarchies: service.hierarchyIndex.hierarchies,
                unassigned: service.hierarchyIndex.unassigned,
            };
        },
    });
</script>

<style lang="scss" scoped>
    #tree-locations::v-deep {
        .vue-treeselect {
            position: static;
        }

        .vue-treeselect__control {
            display: none;
        }

        .vue-treeselect__menu {
            margin-top: 0;
            border: none;
            box-shadow: none;
            max-height: calc(100vh - 4rem) !important;
            position: static;
        }

        .vue-treeselect__menu-container {
            position: static;
        }
    }

    .level-enter-active,
    .level-leave-active {
        transition: opacity 0.5s ease, transform 0.5s ease-out;
    }

    .level-leave-from,
    .level-enter-to {
        opacity: 1;
        transform: translateX(0px);
    }

    .level-enter-from,
    .level-leave-to {
        opacity: 0;
        transform: translateX(-1rem);
    }
</style>
