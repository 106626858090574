// <!-- UTILITIES -->
import { Metric, Measure } from '@/utils/measures';

// <!-- TYPES -->
/**
 * @typedef MoldRiskMeasureRecord
 * @prop {Measure<'mrf'>} mrf Mold risk factor.
 */

/** @implements {MoldRiskMeasureRecord} */
export class MeasuresOfMoldRisk {
    /**
     * Define resource measure class instance.
     * @param {Partial<MoldRiskMeasureRecord>} [attrs]
     */
    constructor(attrs) {
        this.metric = Metric.MOLD;
        this.mrf = attrs?.mrf?.clone() ?? new Measure({ type: 'mrf' });
    }
}
