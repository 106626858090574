<template>
    <div>
        <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
        >
            <path
                class="st0"
                d="M497.5,407.5L292.1,63.8c-15.7-26.4-55.4-26.4-71.2,0L15.6,407.5C-0.2,433.9,19.5,467,51.1,467h410.8
            C493.5,467,513.2,434,497.5,407.5z"
            />
            <path
                class="st1"
                d="M256,360c-13.2,0-23.1,10.7-23.1,24c0,13.2,10.8,24,23.1,24c13.2,0,23.1-10.8,23.1-24
            C280,370.7,269.3,360,256,360z M256,320c8.8,0,15.1-7.2,15.1-16V160c0-8.8-7.2-16-15.1-16s-16,7.2-16,16v144
            C240,312.8,247.2,320,256,320z M504.3,397.3L304.5,59.4C294.4,42.3,276.2,32,256,32c-20.2,0-38.3,10.2-48.5,27.4L7.6,397.4
            c-10.1,17-10.2,37.3-0.4,54.5C17.3,469.5,35.5,480,56.1,480H456c20.5,0,38.8-10.5,48.8-28.2C514.6,434.7,514.4,414.3,504.3,397.3z
            M476.1,435.1c-3.4,8.4-11.3,12.9-21,12.9h-399c-8.9,0-16.8-4.5-21.1-12c-4.1-7.2-4-15.3,0.2-22.4l199.9-338
            C239.4,68.4,247.2,64,256,64c0,0,0,0,0,0c8.8,0,16.6,4.4,20.9,11.6l199.9,338C480.1,420.7,481.1,428.8,476.1,435.1z"
            />
        </svg>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'RedTriangleIcon',
    });
</script>

<style type="text/css">
    .st0 {
        fill: #fee2e2;
    }
    .st1 {
        fill: #b91c1c;
    }
</style>
