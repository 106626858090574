// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import { promiseTimeout } from '@vueuse/core';

// <!-- MODELS -->
import { CustomReportComponent } from '@/models/v1/reports';

// <!-- ROUTES -->
/**
 * @private @class Report component API route resolver.
 */
class Routes {
    /**
     * Resolve the resource index route.
     * @type {() => string}
     */
    static index = () => `reports/components`;
    /**
     * Resolve the show resource route.
     * @type {(component: number) => string}
     */
    static show = (component) => `reports/components/${component}`;
}

/**
 * Fetch all available report components (site-wide).
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportComponent[], error?: unknown }>}
 */
export const fetchComponents = async (config) => {
    try {
        /** @type {import('axios').AxiosResponse<{ components: CustomReportComponent[] }>} */
        const response = await axios().get(Routes.index(), config ?? {});
        const collection = response.data.components;
        const components = collection.map((c) => new CustomReportComponent(c));
        return {
            data: components,
            error: null,
        };
    } catch (e) {
        console.error(e);
        return {
            data: [],
            error: e,
        };
    }
};

/**
 * Mock call to fetch all available report components (site-wide).
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportComponent[], error?: unknown }>}
 */
export const mockFetchComponents = async (config) => {
    // CALCULATE random delay between 1 and 5 seconds.
    const delay = { ms: 1000 * Math.floor(Math.random() * 5) + 1 };

    // MOCK network connection.
    await promiseTimeout(delay.ms);

    // RETURN mocked components.
    const components = [
        CustomReportComponent.fromAttributes(
            1,
            'Graph - Relative Humidity',
            'RH',
            1
        ),
        CustomReportComponent.fromAttributes(2, 'Graph - Temperature', 'T', 2),
        CustomReportComponent.fromAttributes(3, 'Graph - Dewpoint', 'DP', 3),
        CustomReportComponent.fromAttributes(
            4,
            'Graph - Preservation Index',
            'PI',
            4
        ),
        CustomReportComponent.fromAttributes(
            5,
            'Graph - Time-Weighted Preservation Index',
            'TWPI',
            5
        ),
        CustomReportComponent.fromAttributes(
            6,
            'Graph - Mold Risk Factor',
            'MOLD',
            6
        ),
        CustomReportComponent.fromAttributes(
            7,
            'Graph - % Equilibrium Moisture Content',
            'EMC',
            7
        ),
        CustomReportComponent.fromAttributes(
            8,
            'Graph - Graph - % Dimensional Change',
            'EMC',
            8
        ),
        CustomReportComponent.fromAttributes(9, 'NARA Standard', 'NARA', 9),
        CustomReportComponent.fromAttributes(10, 'Statistics', 'STATS', 10),
    ];

    // RETURN mocked response.
    return {
        data: components,
        error: null,
    };
};

/**
 * Fetch report component by id (site-wide).
 * @param {{ id: number }} params Route parameters.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportComponent, error?: unknown }>}
 */
export const fetchComponentById = async (params, config) => {
    try {
        /** @type {import('axios').AxiosResponse<{ component: CustomReportComponent }>} */
        const response = await axios().get(
            Routes.show(params.id),
            config ?? {}
        );
        const component = new CustomReportComponent(response.data.component);
        return {
            data: component,
            error: null,
        };
    } catch (e) {
        console.error(e);
        return {
            data: null,
            error: e,
        };
    }
};

/**
 * Mock call to fetch report component by id (site-wide).
 * @param {{ id: number }} params Route parameters.
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<{ data?: CustomReportComponent, error?: unknown }>}
 */
export const mockFetchComponentById = async (params, config) => {
    const component = (await mockFetchComponents(config)).data[0];
    component.id = params.id;
    return {
        data: component,
        error: null,
    };
};

// <!-- DEFAULT -->
export default {
    fetchComponents,
    fetchComponentById,
    mockFetchComponents,
    mockFetchComponentById,
};
