<template>
    <AnalysisContainer :currentFilterType="'none'">
        <template #sideBar></template>
        <template #mainContent>
            <RightContainer>
                <PageIntro>
                    <PageDescription>
                        <p>
                            Compare metrics and download a report for multiple
                            locations. Preservation Metrics transform
                            temperature and relative humidity data into
                            quantitative numerical measures for assessing
                            collection decay risk. IPI recommends analyzing at
                            least 30 days of data, therefore Time-Weighted
                            Preservation Index (TWPI), % Dimensional Change
                            (%DC), and % Equilibrium Moisture Content (%EMC)
                            values are not calculated for less than 30 days of
                            data. Sort locations by clicking on a column header.
                            Download
                            <a
                                class="text-primary-700 hover:text-gray-500 inline"
                                :href="Links.PRESERVATION_METRICS"
                                target="_blank"
                            >
                                <span>
                                    IPI’s Preservation Metrics (PDF)
                                    <ExternalLinkIcon
                                        class="inline-block h-4 w-4"
                                    />
                                </span>
                            </a>
                            to learn more.
                        </p>
                    </PageDescription>
                    <DownloadReportButton
                        :busy="isDownloading"
                        reportType="compare"
                        @download:click="download"
                    />
                </PageIntro>
                <DownloadReportServerHealth
                    class="mb-4"
                    :online="isOnline"
                    :available="isGraphingServerAvailable"
                    :busy="isDownloading"
                />
                <div class="pr-1">
                    <!-- <div class="bg-gray-50 p-2 rounded-lg text-xs font-sans">
                        <span class="font-bold">Debug</span>
                        <div class="whitespace-pre-wrap">
                            {{
                                {
                                    // CONDITIONALS
                                    isReady,
                                    isLoading,
                                    isSelectionEmpty,
                                    isResponseEmpty,
                                    // COMPUTED
                                    // rowData: rowData.slice(0, 5),
                                    // columnDefs,
                                    // defaultColumnDef,
                                }
                            }}
                        </div>
                    </div> -->
                    <AgGridVue
                        id="compareGrid"
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="pagination"
                        :paginationPageSize="paginationPageSize"
                        :rowData="rowData"
                        :rowHeight="null"
                        :columnDefs="columnDefs"
                        :defaultColDef="defaultColumnDef"
                        :suppressMultiSort="true"
                        @grid-ready="readyGrid"
                        @column-resized="resizeColumn"
                        overlayNoRowsTemplate="No metrics to display."
                    />
                </div>
                <Transition
                    as="template"
                    name="fader"
                >
                    <div
                        v-if="isSelectionEmpty"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 hover:bg-gray-50 hover:text-black duration-300 rounded-lg mt-2"
                    >
                        <p
                            class="flex flex-col justify-center items-center tracking-tight"
                            :style="{
                                height: '100px',
                            }"
                        >
                            <span class="block">
                                No locations or weather stations are selected.
                            </span>
                        </p>
                    </div>
                    <div
                        v-else-if="isLoading"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 hover:bg-gray-50 hover:text-black duration-300 rounded-lg mt-2"
                    >
                        <p
                            class="flex justify-center items-center tracking-tight"
                            :style="{
                                height: '100px',
                            }"
                        >
                            <span class="flex flex-row">
                                <LoadingSpinner
                                    class="relative h-5 w-5 p-0 mt-0 mb-0 mr-2 top-0.5"
                                />
                                Loading compare metrics for selected resources.
                            </span>
                        </p>
                    </div>
                </Transition>
            </RightContainer>
        </template>
    </AnalysisContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="reportAlerts.alerts.value"
            @dismiss:alert="reportAlerts.forceDismiss"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';

    // <!-- COMPONENTS -->
    import RightContainer from '@/components/RightContainer.vue';
    import AnalysisContainer from '~Analysis/components/wrappers/AnalysisContainer.vue';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import DownloadReportButton from '../components/buttons/DownloadReportButton.vue';
    import DownloadReportServerHealth from '../components/card/DownloadReportServerHealth.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import { ExternalLinkIcon } from '@heroicons/vue/solid';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useActiveRoute } from '~Analysis/hooks/useActiveRoute';
    import { useCompareMetrics } from '~Analysis/hooks/useCompareMetrics';
    import { useReportAlerts } from '~Analysis/hooks/reports/useReportAlerts';
    import { useReportDownload } from '~Analysis/hooks/reports/useReportDownload';
    import { useGraphingServerHeartbeat } from '~Analysis/hooks/reports/useGraphingServerHeartbeat';

    // <!-- ENUMS -->
    import { ReportType } from '@/enums';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Compare',
        components: {
            PageIntro,
            PageDescription,
            RightContainer,
            DownloadReportButton,
            DownloadReportServerHealth,
            BaseAlert,
            ExternalLinkIcon,
            AnalysisContainer,
            AgGridVue,
            LoadingSpinner,
        },
        setup() {
            // CONSTANTS

            const Links = Object.freeze({
                PRESERVATION_METRICS:
                    'https://s3.cad.rit.edu/ipi-assets/publications/ipi_preservation_metrics.pdf',
            });

            // ACTIVE TAB

            /**
             * Hooks for tracking the active route.
             */
            const targetRoute = useActiveRoute({ name: 'Compare Metrics' });

            // COMPARE METRICS

            const { query, grid } = useCompareMetrics();
            const { queryClient } = query;

            // DOWNLOAD PDF

            const reportAlerts = useReportAlerts();
            const reportDownloader = useReportDownload();
            const reportAvailability = useGraphingServerHeartbeat();

            const downloadReport = async (
                /** @type {keyof typeof ReportType['_dictionary']} */ reportType
            ) => {
                reportAlerts.clicked(reportType);
                reportDownloader.download(reportType);
            };

            // LIFECYCLE

            reportDownloader.onSuccess((e) => {
                reportAlerts.success(e.reportType);
            });

            reportDownloader.onFailure((e) => {
                reportAlerts.failure(e.reportType, String(e.error));
            });

            onBeforeMount(async () => {
                reportAvailability.heartbeat.resume();
            });

            onBeforeUnmount(() => {
                reportAvailability.heartbeat.pause();
            });

            // Handle change in current route.
            targetRoute.onTargetEnter(async (event) => {
                console.log(`target::enter`, {
                    event,
                    target: targetRoute.target.value,
                    isActive: targetRoute.isActive.value,
                });
                await queryClient.invalidateQueries(['accounts']);
                await queryClient.refetchQueries(['accounts']);
            });

            // Handle exit from current route.
            targetRoute.onTargetLeave(async (event) => {
                console.log(`target::leave`, {
                    event,
                    target: targetRoute.target.value,
                    isActive: targetRoute.isActive.value,
                });
                await queryClient.cancelQueries(['accounts']);
            });

            return {
                // CONSTANTS
                Links,
                // STATE
                pagination: grid.pagination,
                paginationPageSize: grid.paginationPageSize,
                defaultColumnDef: grid.defaultColumnDef,
                columnDefs: grid.columnDefs,
                // COMPUTED
                rowData: grid.rowData,
                isLoading: query.isLoading,
                isReady: query.isReady,
                isSelectionEmpty: query.isSelectionEmpty,
                isResponseEmpty: query.isResponseEmpty,
                isDownloading: reportDownloader.isBusy,
                isOnline: reportAvailability.isOnline,
                isGraphingServerAvailable: reportAvailability.isAvailable,
                // METHODS
                readyGrid: grid.readyGrid,
                resizeColumn: grid.resizeColumn,
                // REPORT DOWNLOAD
                download: downloadReport,
                reportAlerts,
            };
        },
    });
</script>

<style lang="scss">
    .fader-enter-active,
    .fader-leave-active {
        transition: opacity 0.3s ease;
    }

    .fader-enter-from,
    .fader-leave-to {
        opacity: 0;
    }
</style>
