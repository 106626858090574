<template>
    <AnalysisContainer>
        <template #sideBar></template>
        <template #mainContent>
            <RightContainer>
                <PageIntro>
                    <PageDescription>
                        <div v-if="isNARAEnabled">
                            <span
                                >View summary statistics for multiple
                                locations.</span
                            >
                            <span
                                >&nbsp;Locations will use their assigned NARA
                                Standard to calculate limits.</span
                            >
                            <TeleportTrigger @open="modal.reveal" />
                            <ViewNARAStandardsModal
                                :show="modal.isRevealed.value"
                                @close="modal.hide"
                            />
                        </div>
                        <div v-if="isNARADisabled">
                            View summary statistics for multiple locations. To
                            generate a limit analysis - the time spent within
                            specific temperature, relative humidity, and dew
                            point limits - type the min and max values in the
                            Limits filter on the left and select Show.
                        </div>
                    </PageDescription>
                </PageIntro>
                <div class="pr-1">
                    <div
                        v-if="isSelectionEmpty"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 shadow-sm hover:bg-gray-50 hover:text-black hover:shadow-lg duration-300 rounded-lg"
                    >
                        <div class="mx-auto max-w-2xl px-4 text-center">
                            <p
                                class="flex flex-col justify-center items-center tracking-tight"
                                :style="{
                                    height: '500px',
                                }"
                            >
                                <span class="block">
                                    No locations or weather stations are
                                    selected.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        v-else-if="isResponseEmpty && isLoading"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 shadow-sm hover:bg-gray-50 hover:text-black hover:shadow-lg duration-300 rounded-lg"
                    >
                        <div class="mx-auto max-w-2xl px-4 text-center">
                            <p
                                class="flex flex-col justify-center items-center tracking-tight"
                                :style="{
                                    height: '500px',
                                }"
                            >
                                <span class="block">
                                    <LoadingSpinner />
                                    Loading resources...
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        v-else-if="isResponseEmpty && isReady"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 shadow-sm hover:bg-gray-50 hover:text-black hover:shadow-lg duration-300 rounded-lg"
                    >
                        <div class="mx-auto max-w-2xl px-4 text-center">
                            <p
                                class="flex flex-col justify-center items-center tracking-tight"
                                :style="{
                                    height: '500px',
                                }"
                            >
                                <span class="block">
                                    Unable to load statistics for the selected
                                    resource(s).
                                </span>
                                <span class="block">
                                    Please contact your system administrator.
                                </span>
                            </p>
                        </div>
                    </div>
                    <ul
                        v-else
                        role="list"
                        class="grid grid-cols-1 gap-6 xl:grid-cols-2"
                    >
                        <li
                            v-for="{ type, resource, statistics } in data"
                            :key="`${type}-${resource.id}-statistics`"
                            class="col-span-1 rounded-lg shadow divide-y divide-gray-200 card"
                        >
                            <StatisticsCard
                                :type="type"
                                :resource="resource"
                                :statistics="statistics"
                                :loading="isLoading"
                            />
                        </li>
                    </ul>
                </div>
            </RightContainer>
        </template>
    </AnalysisContainer>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import TeleportTrigger from '@/components/modals/TeleportTrigger.vue';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import AnalysisContainer from '~Analysis/components/wrappers/AnalysisContainer.vue';
    import RightContainer from '@/components/RightContainer.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import ViewNARAStandardsModal from '~Analysis/components/modals/ViewNARAStandardsModal.vue';
    import StatisticsCard from '~Analysis/components/StatisticsCard.vue';

    // <!-- COMPOSABLES -->
    import { useActiveRoute } from '@/features/analysis/hooks/useActiveRoute';
    import { useConfirmDialog } from '@vueuse/core';
    import { useStatistics } from '@/features/analysis/hooks/useStatistics';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Statistics',
        components: {
            TeleportTrigger,
            LoadingSpinner,
            AnalysisContainer,
            RightContainer,
            PageIntro,
            PageDescription,
            ViewNARAStandardsModal,
            StatisticsCard,
        },
        setup(props, context) {
            // STATE

            /**
             * Define simple revealable modal.
             * Use `modal.reveal()` to show and `modal.confirm()` to hide.
             */
            const modal = useConfirmDialog();

            const { isNARAEnabled, isNARADisabled } = useNARAFeature();

            /** Hooks for collecting resource statistics. */
            const {
                data,
                queryClient,
                isSelectionEmpty,
                isResponseEmpty,
                isLoading,
                isReady,
            } = useStatistics();

            /**
             * Hooks for tracking the active route.
             */
            const targetRoute = useActiveRoute({ name: 'Statistics' });

            // LIFECYCLE

            // Handle change in current route.
            targetRoute.onTargetEnter(async (event) => {
                console.log(`target::enter`, {
                    event,
                    target: targetRoute.target.value,
                    isActive: targetRoute.isActive.value,
                });
                await queryClient.invalidateQueries(['accounts']);
                await queryClient.refetchQueries(['accounts']);
            });

            // Handle exit from current route.
            targetRoute.onTargetLeave(async (event) => {
                console.log(`target::leave`, {
                    event,
                    target: targetRoute.target.value,
                    isActive: targetRoute.isActive.value,
                });
                await queryClient.cancelQueries(['accounts']);
            });

            // EXPOSE state.
            return {
                /**
                 * Composed tooltip behavior required to reveal and hide the NARA standards modal.
                 */
                modal: {
                    ...modal,
                    hide: modal.confirm,
                },
                /**
                 * Contains reactive collection of data.
                 */
                data,
                /**
                 * Contains an error.
                 */
                error: null,
                /** Indicates if NARA features are enabled */
                isNARAEnabled,
                isNARADisabled,
                /**
                 * Indicates if the currently selected resources are empty.
                 */
                isSelectionEmpty,
                /**
                 * Indicates if the returned data is empty.
                 */
                isResponseEmpty,
                /**
                 * Indicates if any of the requests are currently loading.
                 */
                isLoading,
                /**
                 * Indicates if all the requests are finished loading or if the page is idle.
                 */
                isReady,
            };
        },
    });
</script>

<style lang="scss">
    @media only screen and (min-width: 820px) {
        .card {
            min-width: 475px;
        }
    }
</style>
