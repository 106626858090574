<template>
    <div>
        <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            clsas="myTriangle"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
        >
            <circle
                class="st2"
                cx="256"
                cy="256"
                r="235"
            />
            <path
                class="st3"
                d="M352,240c8.8,0,16,7.2,16,16c0,8.8-7.2,16-16,16H160c-8.8,0-16-7.2-16-16c0-8.8,7.2-16,16-16H352z M512,256
            c0,141.4-114.6,256-256,256S0,397.4,0,256S114.6,0,256,0S512,114.6,512,256z M256,32C132.3,32,32,132.3,32,256s100.3,224,224,224
            s224-100.3,224-224S379.7,32,256,32z"
            />
        </svg>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'YellowCircleIcon',
    });
</script>

<style type="text/css">
    .st2 {
        fill: #fef3c7;
    }
    .st3 {
        fill: #f59e0b;
    }
</style>
