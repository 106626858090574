<template>
    <SidebarFilter
        :title="title"
        :border="border"
    >
        <template #content>
            <FormKit
                type="form"
                help="Set display time zone."
                v-model="formData"
                :actions="false"
                :config="config"
                #default="context"
            >
                <BaseTooltip
                    v-if="isTimezoneDifferent"
                    target="#tooltip-target"
                    :text="TimezoneFilterTooltips.warning"
                >
                    <template #icon>
                        <p
                            class="block -mt-1 mb-1 text-xs text-yellow-600 cursor-help"
                        >
                            <ExclamationCircleIcon
                                class="inline-block h-6 w-6 sm:h-4 sm:w-4 text-yellow-700"
                                aria-hidden="true"
                            />
                            Multiple location time zones in use!
                        </p>
                    </template>
                </BaseTooltip>
                <div :class="formClasses.section">
                    <!-- INTERVAL: DATE START -->
                    <FormKit
                        v-if="isTimezoneInputEnabled"
                        id="filter-timezone"
                        type="select"
                        name="timezone"
                        validation="required"
                        validation-label="Timezone"
                        :title="displayTimezone?.value"
                        :options="options"
                        :classes="formClasses.select"
                        :preserve="true"
                    />
                    <FormKit
                        v-else
                        id="filter-timezone-display"
                        type="text"
                        name="timezone"
                        validation-label="Timezone"
                        :title="displayTimezone?.value"
                        :value="displayTimezone?.label"
                        :classes="formClasses.display"
                        :readonly="true"
                        :ignore="true"
                    />
                    <!-- MODIFIER: ALL -->
                    <div
                        id="filter-timezone-auto"
                        class="toggle__modifier"
                    >
                        <SwitchGroup
                            as="div"
                            class="flex items-center justify-between pt-2"
                        >
                            <span class="flex">
                                <SwitchLabel
                                    as="span"
                                    class="text-sm font-medium text-gray-900"
                                    passive
                                    >Inherit Account Time Zone
                                </SwitchLabel>
                                <BaseTooltip
                                    target="#tooltip-target"
                                    :text="TimezoneFilterTooltips.inherit"
                                >
                                    <template #icon
                                        ><InformationCircleIcon
                                            class="relative inline-block left-1 -top-1 h-6 w-6 sm:h-4 sm:w-4 text-primary-700 cursor-help"
                                            aria-hidden="true"
                                    /></template>
                                </BaseTooltip>
                            </span>
                            <Switch
                                v-model="formData.useAccountTimezone"
                                :class="[
                                    isAccountTimezoneModifierEnabled
                                        ? 'bg-primary-600'
                                        : 'bg-gray-200',
                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                                ]"
                            >
                                <span
                                    aria-hidden="true"
                                    :class="[
                                        isAccountTimezoneModifierEnabled
                                            ? 'translate-x-5'
                                            : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                    ]"
                                />
                            </Switch>
                        </SwitchGroup>
                    </div>
                </div>
                <!-- DEBUG -->
                <div
                    v-if="!!debug"
                    id="debug__info"
                    class="bg-gray-200 m-2 p-2 indent-0.5"
                >
                    <pre
                        class="text-xs subpixel-antialiased whitespace-pre-wrap break-words"
                        >{{ getDebugInfo(context) }}</pre
                    >
                </div>
            </FormKit>
        </template>
    </SidebarFilter>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        watch,
        ref,
        onMounted,
        onBeforeUnmount,
    } from 'vue';
    import debounce from 'just-debounce-it';

    // <!-- COMPONENTS -->
    import SidebarFilter from '@/components/sidebar/SidebarFilter.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';
    import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

    // <!-- UTILITIES -->
    import { useFormkitDebugger } from '@/utils/FormKitDebugger';

    // <!-- COMPOSABLES -->
    import { useTimezoneFilter } from '@/components/sidebar/hooks/useTimezoneFilter';

    // <!-- TYPES -->
    /** @typedef {import('@formkit/core').FormKitConfig} FormKitConfig */
    // <!-- ICONS -->
    import {
        InformationCircleIcon,
        ExclamationCircleIcon,
    } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'TimezoneFilter',
        components: {
            SidebarFilter,
            Switch,
            SwitchGroup,
            SwitchLabel,
            BaseTooltip,
            InformationCircleIcon,
            ExclamationCircleIcon,
        },
        props: {
            /** Filter section title. */
            title: {
                /** @type {Vue.PropType<String>} */
                type: String,
                required: true,
            },
            /** If true, show the top border. */
            border: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Debug mode. */
            debug: {
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        setup(props, context) {
            /** @type {Vue.Ref<import('vue').WatchStopHandle[]>} */
            const handles = ref([]);
            const filter = useTimezoneFilter();

            // <!-- STATE -->
            const { formID, formConfig, formData, displayTimezone } = filter;

            /** Input classes. */
            const formClasses = {
                section: 'flex flex-col',
                select: {
                    outer: '$reset outer block col-span-4',
                    wrapper: '$reset wrapper block w-full items-baseline pr-2',
                    inner: '$reset inner block w-full items-baseline whitespace-nowrap overflow-x-visible',
                    input: '$reset input appearance-none block w-full px-2 py-2 text-xs rounded-md shadow-sm border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-primary-500 disabled:bg-gray-100 disabled:cursor-not-allowed',
                },
                display: {
                    outer: '$reset outer block col-span-4',
                    wrapper: '$reset wrapper block w-full items-baseline pr-2',
                    inner: '$reset inner block w-full items-baseline whitespace-nowrap overflow-x-visible',
                    input: '$reset input appearance-none block w-full px-2 py-2 text-xs rounded-md shadow-sm border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-primary-500 bg-gray-100 cursor-not-allowed',
                },
                checkbox: {
                    outer: '$reset outer block col-span-1 text-xs sm:text-sm',
                    wrapper:
                        '$reset wrapper w-full py-2 flex flex-row items-center disabled:cursor-not-allowed',
                    label: '$reset label block text-xs sm:text-sm',
                    inner: '$reset inner',
                    input: '$reset input h-3.5 w-3.5 appearance-none mb-0.5 mr-1',
                },
            };

            // <!-- CONDITIONALS -->
            const {
                timezoneOptions,
                isAccountTimezoneModifierEnabled,
                isTimezoneInputEnabled,
                isTimezoneDifferent,
            } = filter;

            // const {
            //     // INPUT LISTENERS
            //     onFocusOutMenuDropdown,
            //     onDateTimeLocalInput,
            //     onDateTimeLocalClicked,
            //     onDateTimeLocalKeyboardEvent,
            // } = handlers;

            // // <!-- WATCHERS -->
            // const {
            //     watchAllDatesToggle,
            //     watchOverlappingDatesToggle,
            //     watchDateRangeInterval,
            //     watchDateRangeModifiers,
            //     watchLocationsFilter,
            //     watchWeatherStationsFilter,
            // } = watchers;

            // watchDisplayTimezone;
            // watchInheritAccountTimezoneToggle;

            // <!-- DEBUG -->
            const { getDebugInfo } = useFormkitDebugger(
                formID,
                /** @ts-ignore */
                { value: filter.DefaultFormData },
                formData
            );

            // <!-- LIFECYCLE -->

            // Register the store actions.
            filter.registerEventListeners();

            // Registers the watcher callbacks.
            onMounted(() => {
                handles.value = [
                    // filter.watchInheritAccountTimezoneToggle(),
                    filter.watchTimezoneInput(),
                ];
            });

            // Removes the watcher callbacks before unmounting.
            onBeforeUnmount(() => {
                handles.value?.forEach((h) => h?.());
                handles.value = [];
            });

            // <!-- INITIALIZATION -->

            // Initializes FormKit and loads in current store data.
            filter.initialize();

            // <!-- EXPOSE -->
            return {
                // STATE
                config: formConfig,
                options: timezoneOptions,
                formData,
                formClasses,
                displayTimezone,
                // CONSTANTS
                TimezoneFilterTooltips: filter.TimezoneFilterTooltips,
                // PROPERTIES
                isAccountTimezoneModifierEnabled,
                isTimezoneInputEnabled,
                isTimezoneDifferent,
                // METHODS
                getDebugInfo,
            };
        },
    });
</script>
