// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import clone from 'just-clone';
import extend from 'just-extend';

// <!-- DEFINE -->
/**
 * @class
 * Support class for defining and overriding existing POJO instances.
 */
export class POJO {
    /**
     * Instantiate a plain JavaScript object.
     * @template {object} [T=object]
     * @param {Readonly<Partial<{ [P in keyof T]: T[P] }>>} props Initial props used to instantiate the new instance.
     * @param {Readonly<T>} defaultRecord Optional default record to use when creating a new instance.
     * @returns {T}
     */
    static create = (props, defaultRecord) => {
        // CLONE the default chart options for a starting point.
        const options = /** @type {T} */ (clone(defaultRecord));
        // OVERRIDE options with values present in the props value.
        return POJO.override(options, props);
    };

    /**
     * Create an overridden instance from a patch applied to a source instance.
     * @template {object} [T=object]
     * @param {Readonly<T>} source Source instance to clone and patch.
     * @param {Readonly<Partial<{ [P in keyof T]: T[P] }>>} [patch] Optional patch to apply to clone of source instance.
     * @param {boolean} [deep] Option to deep copy
     * @returns {T}
     */
    static override = (source, patch, deep = true) => {
        // CHECK if source is nil.
        if (isNil(source)) {
            // IF source is not provided, return undefined.
            return undefined;
        }

        // CLONE source.
        const target = clone(source);

        // CHECK if patch is nil.
        if (isNil(patch)) {
            // IF patch is not provided, clone source.
            return target;
        }

        // EXTEND target.
        const extendedTarget = /** @type {T} */ (extend(deep, target, patch));

        // RETURN the extended target.
        return extendedTarget;
    };

    /**
     * Get value of a property in a source instance.
     * @template {object} [T=object]
     * @template {keyof T} [Key=keyof T]
     * @param {Readonly<T>} source Source instance to read.
     * @param {Key} key Property name.
     * @param {T[Key]} [defaultValue] Default value to return when the source property is undefined.
     * @returns {T[Key]}
     */
    static get(source, key, defaultValue = undefined) {
        if (isNil(source) || isNil(key) || !(key in source)) {
            return defaultValue;
        }
        // RETURN value.
        const value = source[key];
        return value === undefined ? defaultValue : value;
    }

    /**
     * Set value of a property in a source instance.
     * @template {object} [T=object]
     * @template {keyof T} [Key=keyof T]
     * @param {Readonly<T>} source Source instance to clone and mutate.
     * @param {Key} key Property name.
     * @param {T[Key] | undefined} [value] Default value to return when the source property is undefined.
     * @returns {T}
     */
    static set(source, key, value = undefined) {
        if (isNil(source)) {
            return undefined;
        }

        if (isNil(key) || !(key in source)) {
            return clone(source);
        }

        // CREATE the patch.
        /** @type {Partial<T>} */
        const patch = Object.create({ [key]: value });

        // OVERRIDE source.
        return POJO.override(source, patch);
    }

    /**
     * Set value of a property in a source instance to `undefined`.
     * @template {object} [T=object]
     * @template {keyof T} [Key=keyof T]
     * @param {Readonly<T>} source Source instance to clone and mutate.
     * @param {Key} key Property name.
     * @returns {T}
     */
    static drop(source, key) {
        // SET property to undefined and return mutated clone.
        return POJO.set(source, key, undefined);
    }

    /**
     * Get cloned, partial instance of source that contains only the specified property.
     * @template {object} [T=object]
     * @template {keyof T} [Key=keyof T]
     * @param {Readonly<T>} source Source instance to clone and mutate.
     * @param {Key} key Property name.
     * @returns {Pick<T,Key>}
     */
    static pluck(source, key) {
        // GET value.
        const value = POJO.get(source, key);

        // CREATE partial.
        /** @type {Pick<T,Key>} */
        const plucked = Object.create({ [key]: value });

        // RETURN plucked.
        return plucked;
    }
}

// <!-- DEFAULT -->
export default POJO;
