// <!-- UTILITIES -->
import { Metric, Measure, MeasuresOfCenter } from '@/utils/measures';

// <!-- TYPES -->
/**
 * @typedef {Pick<MeasuresOfCenter<'EMC'>, 'min' | 'max' | 'mean'>} EquilibriumMoistureContentMeasureRecord
 */

/** @implements {EquilibriumMoistureContentMeasureRecord} */
export class MeasuresOfEquilibriumMoistureContent {
    /**
     * Define resource measure class instance.
     * @param {Partial<EquilibriumMoistureContentMeasureRecord>} [attrs]
     */
    constructor(attrs) {
        this.metric = Metric.EMC;
        this.min = attrs?.min?.clone() ?? new Measure({ type: 'min' });
        this.max = attrs?.max?.clone() ?? new Measure({ type: 'max' });
        this.mean = attrs?.mean?.clone() ?? new Measure({ type: 'mean' });
    }
}
