// <!-- UTILITIES -->
import { Metric, Measure } from '@/utils/measures';

// <!-- TYPES -->
/**
 * @typedef LastTimeWeightedPreservationIndexMeasureRecord
 * @prop {Measure<'last_twpi'>} last_twpi Last recorded TWPI measure.
 */

/** @implements {LastTimeWeightedPreservationIndexMeasureRecord} */
export class MeasuresOfLastTimeWeightedPreservationIndex {
    /**
     * Define resource measure class instance.
     * @param {Partial<LastTimeWeightedPreservationIndexMeasureRecord>} [attrs]
     */
    constructor(attrs) {
        this.metric = Metric.TWPI;
        this.last_twpi =
            attrs?.last_twpi?.clone() ?? new Measure({ type: 'last_twpi' });
    }
}
