// <!-- API -->
import { ref, computed, nextTick } from 'vue';
import {
    computedEager,
    createEventHook,
    resolveUnref,
    watchDebounced,
} from '@vueuse/core';

// <!-- UTILITIES -->
import { getSupportedTimeZones } from '@/utils/timezone';

// <!-- COMPOSABLES -->
import { Store, useStore } from 'vuex';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- CONSTANTS -->
const SUPPORTED_TIMEZONES = getSupportedTimeZones();

// <!-- HELPERS -->

/**
 * Creates map of formatters.
 * @type {Map<string, Intl.DateTimeFormat>}
 */
const TIMEZONE_FORMATTERS = new Map();

/**
 * Use the current date for 'getting' the timezone part.
 * @type {Date}
 */
const TIMEZONE_DATE = Object.freeze(new Date());

/**
 * Get (or create) timezone formatter for the specified timezone option.
 * @param {TimeZone.Identifier} timezone
 * @param {Partial<Intl.DateTimeFormatOptions>} options
 */
const getOrCreateTimezoneFormatter = (
    timezone,
    options = { timeZoneName: 'short' }
) => {
    const key = `${timezone}-${options.timeZoneName}`;

    if (TIMEZONE_FORMATTERS.has(key)) {
        // Cache hit!
        return TIMEZONE_FORMATTERS.get(key);
    }

    // Create new formatter.
    const formatter = new Intl.DateTimeFormat('en-ca', {
        ...options,
        timeZone: timezone,
    });

    // Cache the formatter.
    TIMEZONE_FORMATTERS.set(key, formatter);

    // Return the instance.
    return formatter;
};

// <!-- FILTER ENGINE -->

/**
 * Engine that drives the timezone filter.
 */
class TimezoneFilter {
    // STATIC UTILITY METHODS

    /**
     * Get the timezone name.
     * @param {TimeZone.Identifier} timezone
     * @returns {string}
     */
    static getTimezoneName = (timezone) => {
        const formatter = getOrCreateTimezoneFormatter(timezone, {
            timeZoneName: 'long',
        });
        const parts = formatter.formatToParts(TIMEZONE_DATE);
        const name = parts.find((part) => part.type === 'timeZoneName');
        return name?.value ?? 'Coordinated Universal Time';
    };

    /**
     * Get the timezone location.
     * @param {TimeZone.Identifier} timezone
     * @returns {string}
     */
    static getTimezoneLocation = (timezone) => {
        // If the timezone identifier contains a location split with a "/", break it down.
        if (timezone.includes('/')) {
            // Format: Region/Sub_Region
            const formatted = timezone.replaceAll('_', ' ');
            return formatted;
        } else {
            // Return identifier in ALL-CAPS if there is no split.
            return TimezoneFilter.getTimezoneName(timezone);
        }
    };

    /**
     * Get the timezone abbreviation.
     * @param {TimeZone.Identifier} timezone
     * @returns {string}
     */
    static getTimezoneAbbreviation = (timezone) => {
        const formatter = getOrCreateTimezoneFormatter(timezone, {
            timeZoneName: 'short',
        });
        const parts = formatter.formatToParts(TIMEZONE_DATE);
        const abbreviation = parts.find((part) => part.type === 'timeZoneName');
        return abbreviation?.value ?? 'UTC';
    };

    /**
     * Get the human-readable timezone abbreviation.
     * @param {TimeZone.Identifier} timezone
     */
    static getTimezoneLabel = (timezone) => {
        // Get the fullname label component.
        const label = TimezoneFilter.getTimezoneLocation(timezone);

        // Get the shortname label component.
        const abbreviation = TimezoneFilter.getTimezoneAbbreviation(timezone);

        // Combine the components.
        const formatted = `${label} (${abbreviation})`;

        // Return the formatted label.
        return formatted;
    };

    // CONSTRUCTOR

    /**
     * Initializes the timezone filter.
     */
    constructor() {
        this.boot();
        this.defineEvents();
        this.defineConstants();
        this.defineReactive();
        this.defineComputed();
        this.defineHooks();
        this.defineTriggers();
        this.defineActions();
        this.defineHandlers();
    }

    // CONTEXT / SERVICES

    boot() {
        /**
         * Set the store.
         * @type {Store<ECNBState>}
         */
        this.store = useStore();
    }

    // EVENTS

    /**
     * Define the event hooks.
     */
    defineEvents() {
        /**
         * Event hooks.
         */
        this.events = {
            /**
             * Hook triggered when the timezone filter is initialized.
             * @type {Vue.EventHook<{ options: TimeZone.Identifier[] }>}
             */
            reset: createEventHook(),
            /**
             * Hook triggered when a timezone option has been selected.
             * @type {Vue.EventHook<SidebarFilter.TimezoneFilterFormData>}
             */
            input: createEventHook(),
            /**
             * Hook triggered when the Use Account Timezone checkbox changes state.
             * @type {Vue.EventHook<{ useAccountTimezone: boolean }>}
             */
            modify: createEventHook(),
        };
    }

    // CONSTANTS

    /**
     * Define filter constants.
     */
    defineConstants() {
        /**
         * Is debug mode?
         * @type {Readonly<boolean>}
         */
        this.IsDebug = process.env.NODE_ENV !== 'production';

        /**
         * @type {Partial<import('@formkit/core').FormKitProps> & Partial<import('@formkit/core').FormKitConfig>}
         */
        this.DefaultFormConfig = {
            delay: 150,
            validationVisibility: 'dirty',
        };

        /**
         * The default timezone option to select, when nothing is available.
         * @type {TimeZone.Identifier}
         */
        this.DefaultTimezone = 'UTC';

        /**
         * The default timezone options array.
         * @type {TimeZone.Identifier[]}
         */
        this.DefaultTimezoneOptions = ['UTC', 'America/New_York'];

        /**
         * Represents the default form data.
         * @type {SidebarFilter.TimezoneFilterFormData}
         */
        this.DefaultFormData = {
            timezone: this.DefaultTimezone,
            useAccountTimezone: true,
        };

        /** Filter tooltips. */
        this.TimezoneFilterTooltips = /** @type {const} */ ({
            inherit:
                'When enabled, the graph will inherit the account time zone.',
            warning:
                'One or more selected locations are assigned a different time zone.',
        });
    }

    // STATE

    /**
     * Define the reactive state.
     */
    defineReactive() {
        /** @type {Vue.Ref<TimeZone.Identifier[]>} */
        this.options = ref([...this.DefaultTimezoneOptions]);

        /** @type {Readonly<Vue.Ref<string>>} */
        this.formID = ref('filter-timezone');

        /** @type {Vue.Ref<Partial<import('@formkit/core').FormKitProps> & Partial<import('@formkit/core').FormKitConfig>>} */
        this.formConfig = ref({ ...this.DefaultFormConfig });

        /** @type {Vue.Ref<SidebarFilter.TimezoneFilterFormData>} */
        this.formData = ref({ ...this.DefaultFormData });
    }

    /**
     * Define the computed state.
     */
    defineComputed() {
        const { store } = this;

        this.isTimezoneDifferent = computed(() => {
            const nodes = store.state.analysis.filters.locations.tree.nodes;
            const locationMap = store.state.cache.locations.index;

            //loop through nodes to select "checked" nodes
            const selectedLocations = Object.values(nodes)
                .filter((node) => {
                    return node.type == 'Location';
                })
                .filter((loc) => {
                    return loc.state.checked;
                });

            //get the location ids
            const selectedLocationIDs = selectedLocations.map((loc) => {
                const id = loc.id.substring(1);
                return Number.parseInt(id);
            });

            const locationModels = selectedLocationIDs.map((id) => {
                return locationMap.get(id);
            });

            const matchedLocationTimezones = locationModels.filter((loc) => {
                return loc.timezone == this.displayTimezone.value?.value;
            });

            return !(locationModels.length == matchedLocationTimezones.length);
        });

        /**
         * Is the account timezone usage being enforced?
         * @type {Vue.ComputedRef<boolean>}
         */
        this.isAccountTimezoneModifierEnabled = computed(() => {
            const { useAccountTimezone } = this.formData.value;
            return useAccountTimezone === true;
        });

        /**
         * Is the timezone input disabled?
         * @type {Vue.ComputedRef<boolean>}
         */
        this.isTimezoneInputEnabled = computed(() => {
            const { options, isAccountTimezoneModifierEnabled } = this;
            return (
                options.value?.length > 0 &&
                !isAccountTimezoneModifierEnabled.value
            );
        });

        /**
         * Labelled timezone options.
         * @type {Readonly<Vue.Ref<{ label: string, value: TimeZone.Identifier }[]>>}
         */
        this.timezoneOptions = computed(() => {
            return (this.options.value ?? []).map((timezone) => ({
                label: TimezoneFilter.getTimezoneLabel(timezone),
                value: timezone,
            }));
        });

        /**
         * The current selected account's timezone.
         */
        this.accountTimezone = computedEager(
            () => store.state.accounts?.account?.timezone ?? 'UTC'
        );

        /**
         * The current display timezone in use.
         */
        this.displayTimezone = computedEager(() => {
            const {
                isAccountTimezoneModifierEnabled,
                formData,
                accountTimezone,
            } = this;

            // Get the value.
            let value = formData.value?.timezone ?? 'UTC';
            if (isAccountTimezoneModifierEnabled.value) {
                value = accountTimezone.value;
            }

            // Get the label.
            const label = TimezoneFilter.getTimezoneLabel(value);

            // Return the label and value.
            return { label, value };
        });
    }

    // METHODS

    /**
     * Define the event listener hooks.
     */
    defineHooks() {
        this.onFilterReset = this.events.reset.on;
        this.onTimezoneSelected = this.events.input.on;
        this.onFilterModified = this.events.modify.on;
    }

    /**
     * Define the event triggers.
     */
    defineTriggers() {
        this.resetFilter = this.events.reset.trigger;
        this.selectTimezone = this.events.input.trigger;
        this.modifyFilter = this.events.modify.trigger;
    }

    /**
     * Define input handlers.
     */
    defineHandlers() {
        // this.watchLocations = (options) => {
        //     return watchDebounced(
        //         () => this.store.state.analysis.filters.locations.tree.nodes,
        //         (current, previous) => {
        //             console.log('changed locations');
        //             console.dir(current);
        //             console.dir(this.isTimezoneDifferent.value);
        //         },
        //         options
        //     );
        // };

        /**
         * Define watcher that tracks the form data input.
         * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
         * @returns {import('vue').WatchStopHandle}
         */
        this.watchTimezoneInput = (options = {}) => {
            return watchDebounced(
                this.displayTimezone,
                (current, previous) => {
                    if (current !== previous) {
                        console.log('[input:timezone]', {
                            type: this.formData.value?.useAccountTimezone
                                ? 'account'
                                : 'custom',
                            value: this.formData.value?.timezone,
                        });

                        // Select the displayed timezone.
                        this.selectTimezone({
                            timezone: current?.value ?? 'UTC',
                            useAccountTimezone:
                                this.formData.value?.useAccountTimezone ===
                                true,
                        });
                    }
                },
                {
                    debounce: 25,
                    maxWait: 5000,
                    flush: 'pre',
                    ...options,
                }
            );
        };

        /**
         * Define watcher that tracks the modifier toggle.
         * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
         * @returns {import('vue').WatchStopHandle}
         */
        this.watchInheritAccountTimezoneToggle = (options = {}) => {
            return watchDebounced(
                this.isAccountTimezoneModifierEnabled,
                (isEnabled, wasEnabled) => {
                    console.log('[toggle:account-timezone]', {
                        type: isEnabled ? 'account' : 'custom',
                        value: this.displayTimezone?.value?.value,
                    });
                },
                {
                    debounce: 25,
                    maxWait: 5000,
                    flush: 'pre',
                    ...options,
                }
            );
        };

        /**
         * Define watcher that tracks the form data input.
         * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
         * @returns {import('vue').WatchStopHandle}
         */
        this.watchTimezoneInput = (options = {}) => {
            return watchDebounced(
                this.displayTimezone,
                (current, previous) => {
                    if (current !== previous) {
                        console.log('[input:timezone]', {
                            type: this.formData.value?.useAccountTimezone
                                ? 'account'
                                : 'custom',
                            value: this.formData.value?.timezone,
                        });

                        // Select the displayed timezone.
                        this.selectTimezone({
                            timezone: current?.value ?? 'UTC',
                            useAccountTimezone:
                                this.formData.value?.useAccountTimezone ===
                                true,
                        });
                    }
                },
                {
                    debounce: 25,
                    maxWait: 5000,
                    flush: 'pre',
                    ...options,
                }
            );
        };

        /**
         * Define watcher that tracks the modifier toggle.
         * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
         * @returns {import('vue').WatchStopHandle}
         */
        this.watchInheritAccountTimezoneToggle = (options = {}) => {
            return watchDebounced(
                this.isAccountTimezoneModifierEnabled,
                (isEnabled, wasEnabled) => {
                    console.log('[toggle:account-timezone]', {
                        type: isEnabled ? 'account' : 'custom',
                        value: this.displayTimezone?.value?.value,
                    });
                },
                {
                    debounce: 25,
                    maxWait: 5000,
                    flush: 'pre',
                    ...options,
                }
            );
        };

        /**
         * Define watcher that tracks the form data input.
         * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
         * @returns {import('vue').WatchStopHandle}
         */
        this.watchTimezoneInput = (options = {}) => {
            return watchDebounced(
                this.displayTimezone,
                (current, previous) => {
                    if (current !== previous) {
                        console.log('[input:timezone]', {
                            type: this.formData.value?.useAccountTimezone
                                ? 'account'
                                : 'custom',
                            value: this.formData.value?.timezone,
                        });

                        // Select the displayed timezone.
                        this.selectTimezone({
                            timezone: current?.value ?? 'UTC',
                            useAccountTimezone:
                                this.formData.value?.useAccountTimezone ===
                                true,
                        });
                    }
                },
                {
                    debounce: 25,
                    maxWait: 5000,
                    flush: 'pre',
                    ...options,
                }
            );
        };

        /**
         * Define watcher that tracks the modifier toggle.
         * @param {import('@vueuse/core').WatchDebouncedOptions} [options]
         * @returns {import('vue').WatchStopHandle}
         */
        this.watchInheritAccountTimezoneToggle = (options = {}) => {
            return watchDebounced(
                this.isAccountTimezoneModifierEnabled,
                (isEnabled, wasEnabled) => {
                    console.log('[toggle:account-timezone]', {
                        type: isEnabled ? 'account' : 'custom',
                        value: this.displayTimezone?.value?.value,
                    });
                },
                {
                    debounce: 25,
                    maxWait: 5000,
                    flush: 'pre',
                    ...options,
                }
            );
        };
    }

    /**
     * Define method actions.
     */
    defineActions() {
        /**
         * Initialize the filter instance.
         */
        this.initialize = () => {
            // Initialize the form with the given options.
            this.resetFilter({ options: [...SUPPORTED_TIMEZONES] });
        };

        /**
         * Update the timezone options.
         * @param {MaybeRef<TimeZone.Identifier[]>} options
         */
        this.updateTimezoneOptions = (options) => {
            const ids = resolveUnref(options);
            this.options.value = [...ids];
        };

        /**
         * Update the form data.
         * @param {Partial<SidebarFilter.TimezoneFilterFormData>} data
         */
        this.updateFormData = (data) => {
            const current = { ...this.formData.value };
            this.formData.value = {
                ...current,
                ...data,
            };
        };
    }

    /**
     * Register the event listener callbacks.
     **/
    registerEventListeners() {
        // Registers the filter logic.
        this.onFilterReset((params) => {
            // Update the timezone options.
            this.updateTimezoneOptions(params.options);
            // Update/restore from the Vuex store state.
            const data = { ...this.DefaultFormData };
            // Get the timezone.
            data.timezone =
                this.store.getters['analysis/Timezone']?.asIdentifier();
            // Get the modifier.
            data.useAccountTimezone =
                this.store.getters[
                    'analysis/TimezoneModifier'
                ]?.inherit.isEnabled();
            // Reset the form data.
            this.formData.value = data;
        });

        // Registers the timezone selection logic.
        this.onTimezoneSelected(async ({ timezone, useAccountTimezone }) => {
            // Await until the next tick.
            await nextTick();
            // Update the modifier.
            await this.store.dispatch(
                'analysis/assignInheritAccountTimezoneModifier',
                useAccountTimezone
            );
            // Update the timezone, if not using the account timezone.
            if (!useAccountTimezone) {
                await this.store.dispatch('analysis/assignTimezone', timezone);
            }
        });
    }
}

// <!-- COMPOSABLE -->
/**
 * Composable feature for managing the timezone filter.
 */
export const useTimezoneFilter = () => new TimezoneFilter();

// <!-- DEFAULT -->
export default useTimezoneFilter;
