<template>
    <div class="flex flex-row min-w-screen min-h-screen h-screen">
        <div class="flex-none h-full">
            <SideBar
                :currentFilterType="currentFilterType"
                @click:zoom="$emit('click:zoom', $event)"
            >
                <slot name="sideBar"></slot>
            </SideBar>
        </div>

        <div
            class="flex-1 min-h-screen overflow-y-auto print:overflow-y-visible h-full"
        >
            <SubNav
                :subTabs="currentTabs"
                :includeExternalLinks="true"
            />
            <RightContainer class="sm:hidden block">
                <PageTitle></PageTitle>
            </RightContainer>
            <slot name="mainContent">T2</slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, onBeforeMount } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SideBar from '@/components/SideBar.vue';
    import SubNav from '@/components/SubNav.vue';
    import PageTitle from '@components/PageTitle.vue';
    import RightContainer from '@components/RightContainer.vue';

    // <!-- COMPOSABLES -->
    import { useTabDefs } from '@/hooks/useTabDefs';
    import { useNARATabsIfEnabled } from '@/hooks/env/useSelectTabs';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- TYPES -->
    /** @typedef {import('@/features/analysis/hooks/useAnalysisChart').AnalysisChartConstants['GraphTypes'][number]} GraphType */
    /** @typedef {Extract<GraphType, 'T' | 'RH' | 'TRH' | 'DP'> | 'all' | ''} FilterType */

    // <!-- CONSTANTS -->
    /** Tabs for the main container. */
    const tabDefinitions = {
        commercial: [
            { name: 'Graph', to: '/analysis', current: false, position: 0 },
            {
                name: 'Statistics',
                to: '/analysis/statistics',
                current: false,
                position: 1,
            },
            {
                name: 'Compare Metrics',
                to: '/analysis/compare',
                current: false,
                position: 2,
            },
            {
                name: 'Reports',
                to: '/analysis/reports',
                current: false,
                position: 4,
            },
        ],
        nara: [
            {
                name: 'NARA Standards',
                to: '/analysis/narastandards',
                current: false,
                position: 3,
            },
        ],
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AnalysisContainer',
        components: {
            SideBar,
            SubNav,
            PageTitle,
            RightContainer,
        },
        emits: [
            'click:zoom',
            'nodeCheckAll',
            'nodeCheckNone',
            'nodeChecked',
            'nodeUnchecked',
            'update:start',
            'update:end',
            'update:range',
        ],
        props: {
            /** Filter type. */
            currentFilterType: {
                /** @type {import('vue').PropType<FilterType>} */
                type: /** @type {any} */ (String),
                default: 'all',
            },
        },
        setup(props, context) {
            // SERVICES
            // Get the router.
            const router = useRouter();
            // Create the tab functionality.
            const { tabs: definedTabs } = useNARATabsIfEnabled(tabDefinitions);
            const { tabs: currentTabs, updateCurrentTabByRoute } = useTabDefs(
                { tabs: definedTabs },
                router
            );

            // Expiration guards.
            const { isSuperUser, isCurrentSubscriptionExpired } =
                useExpiredSubscriptionGate();
            const { isNARADisabled } = useNARAFeature();

            // LIFECYCLE

            // Regsiter lifecycle.
            onBeforeMount(async () => {
                // Update the tab route.
                updateCurrentTabByRoute();

                // Check if the user subscription is expired.
                if (
                    !isSuperUser.value &&
                    isCurrentSubscriptionExpired.value &&
                    isNARADisabled.value
                ) {
                    return await router.push('/expired-subscription');
                }
            });

            // ==== EXPOSE ====
            return {
                currentTabs,
            };
        },
    });
</script>

<style scoped>
    .main-section {
        position: relative;
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
