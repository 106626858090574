// <!-- API -->
import { ref, computed, watch } from 'vue';
import { computedEager } from '@vueuse/core';
import { fetchLocations } from '@/api/v1/accounts/locations';
import { fetchLocationDistributionsById } from '@/api/v1/accounts/locations/standards';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useQueries, useQuery, useQueryClient } from '@tanstack/vue-query';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns';
import { createEventHook, resolveUnref } from '@vueuse/core';
import { zonedTimeToUtc } from 'date-fns-tz';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';
import { LocationResource } from '@/models/v1/locations/Location';
import { DateTimeISO } from '@/utils/datetime';
import { Node, NodeSelector, NodeState } from '@/utils/tree';
import { DateRangeFilter } from '@/utils/filters';

// <!-- TYPES -->

/** @typedef {import('vuex').Store<ECNBState>} Store */

/**  @typedef {import('@/api/v1/accounts/locations/standards').LocationStandardsResponse} LocationStandardsResource */
/**  @typedef {import('@/api/v1/accounts/locations/standards').LocationDistributionsResponse} LocationDistributionsResource */

/**
 * @typedef UseNARAStandardsQueryOptions
 * @prop {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [store] Track an existing store reference, if it was already provisioned.
 * @prop {import('@tanstack/vue-query').QueryClient} [queryClient] Track an existing store reference, if it was already provisioned.
 */

/**
 * @typedef UseNARAStandardsQueryReturn
 * @prop {import('@tanstack/vue-query').QueryClient} queryClient Query client in use.
 * @prop {Readonly<Vue.Ref<LocationDistributionsResource[]>> | Vue.ComputedRef<LocationDistributionsResource[]>} data Computed reference collection of data.
 * @prop {Readonly<Vue.Ref<boolean>> | Vue.ComputedRef<boolean>} isLoading Is the resource data loading?
 * @prop {Readonly<Vue.Ref<boolean>> | Vue.ComputedRef<boolean>} isReady Is the resource data finished loading?
 * @prop {Readonly<Vue.Ref<boolean>> | Vue.ComputedRef<boolean>} isSelectionEmpty Is the user selection empty?
 * @prop {Readonly<Vue.Ref<boolean>> | Vue.ComputedRef<boolean>} isResponseEmpty Is the resource response empty?
 * @prop {import('@vueuse/core').EventHookOn<{ data: LocationDistributionsResource }>} onResponse Callback that can process responses directly.
 * @prop {import('@vueuse/core').EventHookOn<{ error?: unknown }>} onError Callback that can process errors.
 */

/**
 * Define the composable.
 * @param {UseNARAStandardsQueryOptions} props
 * @return {UseNARAStandardsQueryReturn}
 */
export const useNARAStandardsQuery = (props = {}) => {
    // EVENTS

    /** @type {import("@vueuse/core").EventHook<{ data: LocationDistributionsResource }>} */
    const queryResponse = createEventHook();
    const onResponse = queryResponse.on;
    const handleResponse = queryResponse.trigger;

    /** @type {import("@vueuse/core").EventHook<{ error?: unknown }>} */
    const queryError = createEventHook();
    const onError = queryError.on;
    const handleError = queryError.trigger;

    // LIFECYCLE

    onError((param) => {
        if (!!param.error) {
            console.error(param.error);
        }
    });

    onResponse((response) => {
        const { data } = response;
        console.log(`HIT sn::debug::distributions`, data);
        if (!!data?.location?.id) {
            distributionsByLocationID.value = Object.assign(
                distributionsByLocationID.value,
                {
                    [data.location.id]: data,
                }
            );
        } else {
            handleError({
                error: new Error(
                    `Unknown response type: ${JSON.stringify(response)}`
                ),
            });
        }
    });

    // SERVICES

    const store = props?.store ?? useStore();
    const queryClient = props?.queryClient ?? useQueryClient();

    // STATE

    // ==== ACCOUNT ====
    /** @type {Vue.Ref<globalThis.Account.Model>} */
    const account = ref(store?.state?.accounts?.account ?? null);
    const selectedAccountId = computed(() => account.value?.id ?? -1);
    const isAccountSelected = computed(
        () => !!store.state.accounts.account?.id && selectedAccountId.value > 0
    );

    const accountTimezone = computedEager(
        () => store.state.accounts?.account?.timezone ?? 'UTC'
    );
    const customTimezone = computedEager(
        () => store.state.analysis?.filters?.timezone?.timezone ?? 'UTC'
    );
    const useAccountTimezone = computedEager(
        () =>
            store.state.analysis?.filters?.timezone?.useAccountTimezone === true
    );
    const displayTimezone = computedEager(() => {
        return useAccountTimezone.value === true
            ? accountTimezone.value
            : customTimezone.value;
    });

    // ==== DATE RANGE FILTER ====
    const dateRange = computed(
        () => {
            // const { start, end } = store.state.analysis.filters.daterange;
            const { start, end } =
                store.state.analysis.filters.daterange.toFormModel();
            // VALIDATE
            const filterStart =
                is.nullOrUndefined(start) || is.emptyStringOrWhitespace(start)
                    ? Date.UTC(1970, 0, 1, 0, 0, 0, 0)
                    : zonedTimeToUtc(
                          `${start} 00:00:00`,
                          displayTimezone.value
                      );
            const filterEnd =
                is.nullOrUndefined(end) || is.emptyStringOrWhitespace(end)
                    ? new Date()
                    : zonedTimeToUtc(`${end} 23:59:59`, displayTimezone.value);
            // EXPOSE
            return {
                start: filterStart,
                end: filterEnd,
            };
        },
        {
            // onTrigger: (e) => console.log(daterange.value),
        }
    );
    const hasDateRange = computedEager(() => !!dateRange.value);
    const normalizedDates = computed(() => {
        const { minDate, maxDate } = getNormalizedDateRange(dateRange.value);
        return { start: minDate, end: maxDate };
    });

    // ==== LOCATIONS ====
    /** @type {Vue.Ref<LocationResource[]>} */
    const indexLocations = ref([]);

    /** @type {Vue.Ref<Record<number, LocationDistributionsResource>>} */
    const distributionsByLocationID = ref({});

    const datedLocations = computed(() =>
        getLocationsWithinDateRange(indexLocations.value, dateRange.value)
    );
    const checkedLocationNodes = computed(() =>
        getCheckedLocationNodes(store.state.analysis.filters.locations.tree)
    );
    const checkedLocationIDs = computed(() =>
        getCheckedLocationIDs(checkedLocationNodes.value)
    );
    const filteredLocations = computed(() =>
        getFilteredLocations(indexLocations.value, checkedLocationIDs.value)
    );
    const hasFilteredLocations = computed(
        () => filteredLocations.value.length === 0
    );

    /** Create query for account locations. */
    const accountLocations = useQuery({
        queryKey: QueryKeys.locations(selectedAccountId),
        queryFn: ({ queryKey, signal }) => {
            return fetchLocations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexLocations.value = data;
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Define query options for each location. */
    const locationDistributionsOptions = computed(() =>
        filteredLocations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.locationDistributions(
                    selectedAccountId,
                    resource.id,
                    dateRange
                ),
                queryFn: async ({ queryKey, signal }) => {
                    if (
                        !isLocationResourceWithinDateRange(
                            resource,
                            dateRange.value
                        )
                    ) {
                        // Exit early with an empty set of data to allow this location
                        // to appear in the table without bothering the server.
                        return { id: resource?.id, data: {} };
                    }

                    const { minDate, maxDate } = getNormalizedDateRange(
                        dateRange.value
                    );
                    const id = resource?.id;
                    const start_time = Math.trunc(minDate / 1000);
                    const end_time = Math.trunc(maxDate / 1000);
                    const data = await fetchLocationDistributionsById(
                        account.value,
                        resource,
                        { start_time, end_time },
                        { signal }
                    );
                    return { id, data };
                },
                onError: (e) => handleError(e),
                /** @param {{ id: number, data: Awaited<ReturnType<typeof fetchLocationDistributionsById>> }} response */
                onSuccess: (response) => {
                    // GET the id.
                    const location = response.data.location ?? {};
                    location.id = location?.id ?? response?.id;
                    // Trigger the event.
                    handleResponse({
                        data: {
                            account: response.data.account,
                            location,
                            standard: response.data.standard,
                            metrics: response.data.metrics,
                        },
                    });
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel location risks queries. */
    const locationDistributions = useQueries({
        queries: locationDistributionsOptions,
    });

    /** Is the component loading? */
    const isLoading = computed(() => {
        if (
            accountLocations.isLoading.value ||
            accountLocations.isFetching.value
        ) {
            return true;
        }
        if (
            locationDistributions.some((q) => !!q.isLoading || !!q.isFetching)
        ) {
            return true;
        }
        return false;
    });

    /** Is the component ready? */
    const isReady = computed(() => !isLoading.value);

    /**
     * Resolved resource distributions data. Empty to start.
     * @type {Readonly<Vue.Ref<LocationDistributionsResource[]>> | Vue.ComputedRef<LocationDistributionsResource[]>} Computed reference collection of data.
     */
    const data = computedEager(() => {
        // LOCATIONS
        const _locations = {
            get filtered() {
                return filteredLocations.value;
            },
            get distributions() {
                return distributionsByLocationID.value;
            },
            compute() {
                // COMPUTE location resource data.
                const { distributions } = this;
                return this.filtered.map((location) => {
                    const item = { ...distributions[location.id] };
                    item.location = item.location ?? {};
                    item.location.id = item.location?.id ?? location.id;
                    item.location.name = item.location?.name ?? location.name;
                    item.location.path = item.location?.path ?? location.path;
                    item.location.label =
                        item.location?.label ?? location.label;
                    item.location.min_date =
                        item.location?.min_date ?? location.minDate;
                    item.location.max_date =
                        item.location?.max_date ?? location.maxDate;
                    return item;
                });
            },
        };
        // COMPUTE merged resource data.
        return [..._locations.compute()];
    });

    /** Is the treeview selection empty? */
    const isSelectionEmpty = computedEager(
        () => checkedLocationIDs.value.length === 0
    );

    /** Is the resolved data collection empty? */
    const isResponseEmpty = computedEager(
        () => !!data && data?.value?.length === 0
    );

    // WATCHERS

    watch(
        () => store.state.accounts.account,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                account.value = current;
                indexLocations.value = [];
                distributionsByLocationID.value = {};
                // console.log(`sn::debug::watch-account?`, current);
            }
        },
        {
            deep: false,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.locations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.locations(selectedAccountId),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    return {
        data,
        queryClient,
        isLoading,
        isReady,
        isSelectionEmpty,
        isResponseEmpty,
        onResponse,
        onError,
    };
};

/**
 * Query keys used by this composable.
 */
const QueryKeys = Object.freeze({
    all: /** @type {const} */ (['accounts']),
    /** @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account */
    locations: (account) =>
        /** @type {const} */ ([...QueryKeys.all, account, 'locations']),
    /**
     * @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account
     * @param {import('@vueuse/core').MaybeRef<import('@/models/v1/locations/Location').LocationResource['id']>} location
     * @param {import('@vueuse/core').MaybeRef<Interval>} dates
     */
    locationDistributions: (account, location, dates) =>
        /** @type {const} */ ([
            ...QueryKeys.all,
            ...QueryKeys.locations(account),
            location,
            'distributions',
            dates,
        ]),
});

/**
 * Check if location is within the current date range.
 * @param {import('@/models/v1/locations/Location').LocationResource} resource
 * @param {Interval | Vue.Ref<Interval>} dateRange
 */
const isLocationResourceWithinDateRange = (resource, dateRange) => {
    // Resolve interval being checked.
    const filterRange = resolveUnref(dateRange);

    // NORMALIZE
    const start = normalizeStartDate(filterRange.start);
    const end = normalizeEndDate(filterRange.end);
    const min = normalizeStartDate(parseDate(resource?.minDate));
    const max = normalizeEndDate(parseDate(resource?.maxDate));

    // FILTER
    const isMinDateAfterEndBound = isAfter(min, end);
    const isMaxDateBeforeStartBound = isBefore(max, start);

    if (is.nan(min) || is.nan(max)) {
        return true;
    }

    return (
        (is.nan(start) || !isMaxDateBeforeStartBound) &&
        (is.nan(end) || !isMinDateAfterEndBound)
    );
};

/**
 * Get the checked location tree nodes.
 * @param {Treeview.Tree} filter
 */
const getCheckedLocationNodes = (filter) => {
    // const filter = store.state.analysis.filters.locations.tree;
    const nodes = Object.values(filter.nodes);
    return nodes
        .filter(Node.isLocationNode)
        .filter((n) => NodeState.isChecked(n.state));
};

/**
 * Get the checked weather station tree nodes.
 * @param {Treeview.Tree} filter
 * @return {Treeview.Node[]}
 */
const getCheckedWeatherStationNodes = (filter) => {
    // NOTE: Weather stations CANNOT have NARA Standards.
    return [];
};

/**
 * Get the location resource collection within a particular date range.
 * @param {LocationResource[] | Vue.Ref<LocationResource[]>} locations
 * @param {Interval | Vue.Ref<Interval>} dateRange
 */
const getLocationsWithinDateRange = (locations, dateRange) => {
    const data = resolveUnref(locations);
    const filtered = data.filter((r) =>
        isLocationResourceWithinDateRange(r, dateRange)
    );
    return filtered;
};

/**
 * Get the checked location ids.
 * @param {Treeview.Node[] | Vue.Ref<Treeview.Node[]>} checkedNodes
 */
const getCheckedLocationIDs = (checkedNodes) => {
    const nodes = resolveUnref(checkedNodes);
    return nodes
        .map((n) => n.id)
        .map(NodeSelector.readResourceID)
        .map(Number);
};

/**
 * Get the checked weather station ids.
 * @param {Treeview.Node[] | Vue.Ref<Treeview.Node[]>} checkedNodes
 * @return {string[]}
 */
const getCheckedWeatherStationIDs = (checkedNodes) => {
    // NOTE: Weather stations CANNOT have NARA Standards.
    return [];
};

/**
 * Get the filtered locations.
 * @param {LocationResource[] | Vue.Ref<LocationResource[]>} locations
 * @param {number[] | Vue.Ref<number[]>} checkedIDs
 */
const getFilteredLocations = (locations, checkedIDs) => {
    const data = resolveUnref(locations);
    const selectedIDs = resolveUnref(checkedIDs);
    const filtered = data.filter((r) => {
        return selectedIDs.includes(r.id);
    });
    return filtered;
};

/**
 * Calculate valid start and end dates.
 * @param {Interval} range
 */
const getNormalizedDateRange = (range) => {
    // VALIDATE
    const defaultStart = startOfDay(subYears(Date.now(), 1)).valueOf();
    const defaultEnd = endOfDay(Date.now()).valueOf();
    const _start = range?.start?.valueOf() ?? NaN;
    const _end = range?.end?.valueOf() ?? NaN;

    // CLAMP
    const SAFE_MAX_DATE = Date.UTC(3000, 0, 1, 0, 0, 0, 0);
    const SAFE_MIN_DATE = Date.UTC(1, 0, 1, 0, 0, 0, 0);
    const minDate =
        is.nan(_start) || isBefore(_start, SAFE_MIN_DATE)
            ? defaultStart
            : _start;
    const maxDate =
        is.nan(_end) || isAfter(_end, SAFE_MAX_DATE) ? defaultEnd : _end;
    return {
        minDate: Math.round(minDate.valueOf()),
        maxDate: Math.round(maxDate.valueOf()),
    };
};

/**
 * Test if input date is invalid.
 * @param {IDate} value
 * @returns {boolean}
 */
const isDateInvalid = (value) => is.nullOrUndefined(value) || is.nan(value);

/**
 * Test if input date string is invalid.
 * @param {string} value
 * @returns {boolean}
 */
const isDateStringEmpty = (value) =>
    is.nullOrUndefined(value) || is.emptyStringOrWhitespace(value);

/**
 * Normalize start date.
 * @param {IDate} value
 * @returns {IDate}
 */
const normalizeStartDate = (value) => {
    return isDateInvalid(value) ? NaN : startOfDay(value);
};

/**
 * Normalize end date.
 * @param {IDate} value
 * @returns {IDate}
 */
const normalizeEndDate = (value) => {
    return isDateInvalid(value) ? NaN : endOfDay(value);
};

/**
 * Parse resource date string.
 * @param {string} value
 * @returns {IDate}
 */
const parseDate = (value) =>
    isDateStringEmpty(value) ? NaN : DateTimeISO.parse(value);
