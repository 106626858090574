// <!-- API -->

import { ref } from 'vue';
import { refDefault, computedEager, resolveRef } from '@vueuse/core';

// <!-- COMPOSABLES -->

import { useOnline, useTimeoutPoll } from '@vueuse/core';

// <!-- CONTROLLERS -->

import { ReportDownloadRequest } from '../../utils/reports';

// <!-- COMPOSABLE -->
/**
 * Use a report download instance.
 * @param {Combine<{ rate?: MaybeRef<number> }>} [options]
 */
export const useGraphingServerHeartbeat = (options = {}) => {
    // Track online network availability.
    const online = useOnline();

    /**
     * Last known status of the graphing server.
     * @type {Vue.Ref<boolean>}
     */
    const healthy = ref(false);

    /**
     * Rate at which the graphing server should be pinged.
     */
    const rate = refDefault(resolveRef(options.rate), 10000);

    // Alias.
    const isOnline = computedEager(() => online.value);

    // Negated computed property.
    const isOffline = computedEager(() => !online.value);

    // Is it unavailable?
    const isAvailable = computedEager(() => isOnline.value && healthy.value);

    // Is it unavailable?
    const isUnavailable = computedEager(
        () => isOffline.value || !healthy.value
    );

    /** Check if the graphing server is healthy. */
    const checkIfAvailable = async () => {
        if (isOffline.value) {
            healthy.value = false;
            return;
        }
        try {
            const pong = await ReportDownloadRequest.ping();
            console.debug(pong);
            healthy.value = !!pong && pong.uptime > 0;
        } catch (e) {
            healthy.value = false;
        }
    };

    /**
     * Track the heartbeat of the graphing server.
     */
    const heartbeat = useTimeoutPoll(checkIfAvailable, rate, {
        immediate: true,
    });

    return {
        healthy,
        rate,
        isOnline,
        isOffline,
        isAvailable,
        isUnavailable,
        heartbeat,
    };
};

// <!-- DEFAULT -->
export default useGraphingServerHeartbeat;
