// <!-- TYPES -->

/**
 * @typedef ReportComponent
 * @prop {number} id Unique component id.
 * @prop {string} name Display text associated with this template component.
 * @prop {string} code Unique string code representing this template.
 * @prop {number} position Position of the component.
 */

// <!-- CLASS -->

/**
 * Custom report component model.
 * @implements {ReportComponent}
 */
export class CustomReportComponent {
    /**
     * Define "empty" report instance.
     */
    static empty() {
        return new CustomReportComponent({});
    }

    /**
     * Compare components by their positions.
     * @param {ReportComponent} a
     * @param {ReportComponent} b
     */
    static compareByPosition(a, b) {
        return (
            (a.position || Number.MAX_SAFE_INTEGER) -
            (b.position || Number.MAX_SAFE_INTEGER)
        );
    }

    /**
     * Sort an array of components, by their position values.
     * @param {CustomReportComponent[]} components
     */
    static sort(components) {
        // INDEX components (for stable sorting).
        const indexed = components.map((c, index) => ({
            c,
            index,
        }));
        // SORT components by position. Equal position values fallback to the input index values.
        const sorted = indexed.sort((a, b) => {
            // Perform a stable sort, such that equal items do not change locations.
            return (
                CustomReportComponent.compareByPosition(a.c, b.c) ||
                a.index - b.index
            );
        });
        // RETURN sorted components (dereference the index).
        return sorted.map((entry) => entry.c);
    }

    /**
     * Define custom report component instance.
     * @param {number} id Unique component id.
     * @param {string} name Display text associated with this template component.
     * @param {string} code Unique string code representing this template.
     * @param {number} [position] Position of the component.
     */
    static fromAttributes(id, name, code, position = null) {
        return new CustomReportComponent({ id, name, code, position });
    }

    /**
     * Define custom report component instance with a position.
     * @param {Pick<CustomReportComponent, 'id' | 'name' | 'code'>} component Component instance.
     * @param {number} position Position of the component.
     */
    static withPosition(component, position) {
        return new CustomReportComponent({ ...component, position });
    }

    /**
     * Define a custom report component instance.
     * @param {Partial<ReportComponent>} attrs Attributes.
     */
    constructor(attrs = {}) {
        this.id = attrs.id ?? null;
        this.name = attrs.name ?? null;
        this.code = attrs.code ?? null;
        this.position = attrs.position ?? null;
    }

    // ALIASES

    /** @alias name - The chart label is the formatted chart name. */
    get label() {
        return this.name;
    }

    /** @alias code - The chart type is the same as its distinct code. */
    get type() {
        return this.code;
    }
}

// <!-- DEFAULT -->
export default CustomReportComponent;
