// <!-- ENUMS -->
import { MeasureType } from './MeasureType';
import { MeasureLabelByType } from './MeasureLabel';

// <!-- TYPES -->

/**
 * @template {MeasureType} [T=MeasureType]
 * @typedef MeasureAttributes
 * @prop {T} type Measure type. Defaults to `null`.
 * @prop {number} value Numeric value assigned to the measure. Defaults to `NaN`.
 * @prop {MeasureLabelByType[T] | ''} label Display text used to display the measure. Defaults to `''`.
 */

/**
 * @class Represents a labelled, numeric value from a specific domain.
 * @template {MeasureType} [T=MeasureType]
 * @implements {MeasureAttributes<T>}
 */
export class Measure {
    /**
     * Incrementally declare attribute values before model instantiation.
     * @template {MeasureType} [T=MeasureType]
     */
    static get make() {
        /** @type {Partial<MeasureAttributes<T>>} */
        const attrs = {};

        // DEFINE shared context for chainable method invocations.
        const context = Object.freeze(
            /** @type {const} */ ({
                /** @param {MeasureType} type */
                ofType: (type) => {
                    attrs.type = /** @type {T} */ (type);
                    return context;
                },
                /** @param {number} value */
                withValue: (value) => {
                    attrs.value = value;
                    return context;
                },
                /** @param {MeasureLabelByType[MeasureType] | ''} label */
                withLabel: (label) => {
                    attrs.label = /** @type {MeasureLabelByType[T] | ''} */ (
                        label
                    );
                    return context;
                },
                /** Terminal operation that defines the instance. */
                create: () => new Measure(attrs),
            })
        );

        // EXPOSE context.
        return context;
    }

    /**
     * @param {MeasureType} type
     */
    static ofType(type) {
        return Measure.make.ofType(type);
    }

    /** @param {number} value */
    static withValue(value) {
        return Measure.make.withValue(value);
    }

    /**
     * @param {MeasureLabelByType[MeasureType] | ''} label
     */
    static withLabel(label) {
        return Measure.make.withLabel(label);
    }

    /**
     * Define measure instance.
     * @param {Partial<MeasureAttributes<T>>} [attrs]
     */
    constructor(attrs) {
        this.type = attrs?.type ?? null;
        this.value = attrs?.value ?? NaN;
        this.label = attrs?.label ?? MeasureLabelByType[this.type] ?? '';
    }

    /** Clone existing measure instance. */
    clone() {
        return new Measure(this);
    }
}
