// <!-- API -->
import { onBeforeMount, onBeforeUnmount, watch } from 'vue';
import {
    computedEager,
    createEventHook,
    makeDestructurable,
    resolveRef,
    tryOnUnmounted,
} from '@vueuse/core';

// <!-- COMPOSABLES -->
import {
    useRouter,
    useRoute,
    onBeforeRouteLeave,
    onBeforeRouteUpdate,
} from 'vue-router';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';

// <!-- TYPES -->
/**
 * @typedef UseActiveRouteOptions
 * @prop {Router.Router} [router] Track an existing router reference, if it was already provisioned.
 */

/**
 * @typedef {import('@vueuse/core').EventHookOn<{ from?: Router.RouteLocationNormalized }>} OnTargetEnter
 * Fires when the current route first changes to the target route.
 */

/**
 * @typedef {import('@vueuse/core').EventHookOn<{ to?: Router.RouteLocationNormalized }>} OnTargetLeave
 * Fires when the current route first leaves from the target route.
 */

/**
 * @typedef UseActiveRouteReturn
 * @prop {V.Ref<Pick<Router.RouteLocationNormalized, 'name'>>} target
 * @prop {Readonly<V.Ref<boolean>>} isActive
 * @prop {OnTargetEnter} onTargetEnter Fires when the current route first changes to the target route.
 * @prop {OnTargetLeave} onTargetLeave Fires when the current route first leaves from the target route.
 */

/**
 * Define the active route to observe.
 * @param {import('@vueuse/core').MaybeRef<Pick<Router.RouteLocationNormalized, 'name'>>} [initialTarget] Reactive target route reference to track. Creates a new Ref, if one is not provided.
 * @param {UseActiveRouteOptions} [options] See {@link UseActiveRouteOptions}.
 * @returns {UseActiveRouteReturn} See {@link UseActiveRouteReturn}.
 */
export const useActiveRoute = (initialTarget, options = {}) => {
    // EVENTS

    /**
     * Triggered when the current route changes to match the target route.
     * @type {import('@vueuse/core').EventHook<{ from?: Router.RouteLocationNormalized }>}
     */
    const targetEnter = createEventHook();

    /**
     * Triggered when the current route stops matching the target route.
     * @type {import('@vueuse/core').EventHook<{ to?: Router.RouteLocationNormalized }>}
     */
    const targetLeave = createEventHook();

    // LIFECYCLE

    const onTargetEnter = targetEnter.on;

    /** Event fired when the target route is gone. */
    const onTargetLeave = targetLeave.on;

    // UTILS

    /**
     * Compare two normalized route locations by name.
     * @param {Pick<Router.RouteLocationNormalized, 'name'>} a
     * @param {Pick<Router.RouteLocationNormalized, 'name'>} b
     */
    const hasSameRouteRecordName = (a, b) => {
        const nameOfA = a?.name ?? '';
        const nameOfB = b?.name ?? '';
        return nameOfA === nameOfB;
    };

    // STATE

    /** Router instance used to resolve the current route information. */
    const router = options?.router ?? useRouter();

    /** Target route to fire events on. Defaults to current route, if none were provided. */
    const target = resolveRef(initialTarget ?? useRoute());

    // COMPUTED

    /** Indicates if the target route has the same record name as the current route. */
    const isActive = computedEager(() => {
        const _active = router.currentRoute.value;
        const _target = target.value;
        return (
            !!_active && !!_target && hasSameRouteRecordName(_active, _target)
        );
    });

    // WATCHERS

    // WATCH currentRoute for changes. If dirty, fire targetEnter or targetLeave.
    watch(
        router.currentRoute,
        (current, previous, onCleanup) => {
            // DEFINE the active route name (and its dirty status).
            const activeRouteName = {
                current: current?.name ?? '',
                previous: previous?.name ?? undefined,
                get isDirty() {
                    return is.nonEmptyString(previous) && current !== previous;
                },
            };
            // HANDLE behaviour when the route has changed.
            if (activeRouteName.isDirty) {
                if (!!isActive.value) {
                    targetEnter.trigger({ from: previous });
                } else {
                    targetLeave.trigger({ to: current });
                }
            }
        },
        { flush: 'pre' }
    );

    // LIFECYCLE

    onBeforeMount(() => {
        targetEnter.trigger({ from: useRoute() });
    });

    onBeforeUnmount(() => {
        targetLeave.trigger({ to: undefined });
    });

    // Invoked when onUnmounted exists.
    tryOnUnmounted(() => {
        // NOTE: Watchers are automatically disposed with the parent EffectScope.
        // `setup()` in components creates an implicit scope that will be disposed of
        // when the component is unmounted.
    });

    // EXPOSE

    /** Define the destructurable context to return to a consumer. */
    const exposed = makeDestructurable(
        Object.freeze({
            target,
            isActive,
            onTargetEnter,
            onTargetLeave,
        }),
        Object.freeze([target, isActive])
    );
    return exposed;
};

// <!-- DEFAULT -->
export default useActiveRoute;
