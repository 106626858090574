/**
 * @typedef {keyof typeof Metric} MetricType
 * Indicates graph axis described within the dataset.
 */
export const Metric = /** @type {const} */ ({
    T: 'T',
    RH: 'RH',
    DP: 'DP',
    MOLD: 'MOLD',
    PI: 'PI',
    TWPI: 'TWPI',
    DC: 'DC',
    EMC: 'EMC',
});
