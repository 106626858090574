// <!-- TYPES -->
import { Model } from '@/models/v1/resource/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<LocationRisks['toPayload']>} LocationRisksPayload */
/** @typedef {ReturnType<LocationRisks['toResource']>} LocationRisksResource */

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

/**
 * Risk level and data.
 */
export const RISKS = /** @type {const} */ ({
    GOOD: {
        value: 1,
        label: 'GOOD',
        color: '#006500',
    },
    OK: {
        value: 2,
        label: 'OK',
        color: '#000000',
    },
    DANGER: {
        value: 3,
        label: 'RISK',
        color: '#FF0000',
    },
});

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'r_start_date',
    'r_end_date',
    'r_temp_mean',
    'r_rh_mean',
    'r_dp_mean',
    'r_last_twpi',
    'r_dc_max',
    'r_emc_min',
    'r_emc_max',
    'r_mrf',
    'r_dc_span',
    'r_dc_min',
    'r_emc_mean',
]);

/**
 * @class
 */
export class LocationRisks extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    /**
     * @param {number} r_last_twpi
     * @returns {typeof RISKS[keyof RISKS]}
     */
    static getAgeRisk(r_last_twpi) {
        if (isNil(r_last_twpi)) {
            return null;
        }
        if (r_last_twpi < 45) {
            return RISKS.DANGER;
        }
        if (r_last_twpi >= 45 && r_last_twpi < 75) {
            return RISKS.OK;
        }
        return RISKS.GOOD;
    }

    /**
     * @param {number} emc_max
     * @returns {typeof RISKS[keyof RISKS]}
     */
    static getCorrosionRisk(emc_max) {
        if (isNil(emc_max)) {
            return null;
        }
        if (emc_max < 7.0) {
            return RISKS.DANGER;
        }
        if (emc_max >= 7.0 && emc_max < 10.5) {
            return RISKS.OK;
        }
        return RISKS.GOOD;
    }

    /**
     * @param {number} mrf
     * @returns {typeof RISKS[keyof RISKS]}
     */
    static getMoldRisk(mrf) {
        if (isNil(mrf)) {
            return null;
        }
        return mrf <= 0.5 ? RISKS.GOOD : RISKS.DANGER;
    }

    /**
     * @param {number} emc_min
     * @param {number} emc_max
     * @param {number} dc_span
     * @returns {typeof RISKS[keyof RISKS]}
     */
    static getMechanicalRisk(emc_min, emc_max, dc_span) {
        if (isNil(emc_min) || isNil(emc_max) || isNil(dc_span)) {
            return null;
        }
        let type = 0;
        type = emc_max <= 12.5 ? 1 : 3;
        type = emc_min >= 5.0 ? Math.max(type, 1) : 3;
        type =
            dc_span <= 0.5
                ? Math.max(type, 1)
                : dc_span > 0.5 && dc_span <= 1.5
                ? Math.max(type, 2)
                : 3;
        switch (type) {
            case 1:
                return RISKS.GOOD;
            case 2:
                return RISKS.OK;
            case 3:
            default:
                return RISKS.DANGER;
        }
    }

    /**
     * @param {LocationRisksPayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {readonly (readonly [string, any])[]} */
        const entries = [
            [FIELDS.r_start_date, payload[0]],
            [FIELDS.r_end_date, payload[1]],
            [FIELDS.r_temp_mean, payload[2]],
            [FIELDS.r_rh_mean, payload[3]],
            [FIELDS.r_dp_mean, payload[4]],
            [FIELDS.r_last_twpi, payload[5]],
            [FIELDS.r_dc_max, payload[6]],
            [FIELDS.r_emc_min, payload[7]],
            [FIELDS.r_emc_max, payload[8]],
            [FIELDS.r_mrf, payload[9]],
            [FIELDS.r_dc_span, payload[10]],
            [FIELDS.r_dc_min, payload[11]],
            [FIELDS.r_emc_mean, payload[12]],
        ];
        return this.parseArray(entries);
    }

    toPayload() {
        return [
            /** @type {string} */
            this.get(FIELDS.r_start_date),
            /** @type {string} */
            this.get(FIELDS.r_end_date),
            /** @type {number} */
            this.get(FIELDS.r_temp_mean),
            /** @type {number} */
            this.get(FIELDS.r_rh_mean),
            /** @type {number} */
            this.get(FIELDS.r_dp_mean),
            /** @type {number} */
            this.get(FIELDS.r_last_twpi),
            /** @type {number} */
            this.get(FIELDS.r_dc_max),
            /** @type {number} */
            this.get(FIELDS.r_emc_min),
            /** @type {number} */
            this.get(FIELDS.r_emc_max),
            /** @type {number} */
            this.get(FIELDS.r_mrf),
            /** @type {number} */
            this.get(FIELDS.r_dc_span),
            /** @type {number} */
            this.get(FIELDS.r_dc_min),
            /** @type {number} */
            this.get(FIELDS.r_emc_mean),
        ];
    }

    toResource() {
        return {
            /** @type {string} */
            [FIELDS.r_start_date]: this.get(FIELDS.r_start_date),
            /** @type {string} */
            [FIELDS.r_end_date]: this.get(FIELDS.r_end_date),
            /** @type {number} */
            [FIELDS.r_temp_mean]: this.get(FIELDS.r_temp_mean),
            /** @type {number} */
            [FIELDS.r_rh_mean]: this.get(FIELDS.r_rh_mean),
            /** @type {number} */
            [FIELDS.r_dp_mean]: this.get(FIELDS.r_dp_mean),
            /** @type {number} */
            [FIELDS.r_last_twpi]: this.get(FIELDS.r_last_twpi),
            /** @type {number} */
            [FIELDS.r_dc_max]: this.get(FIELDS.r_dc_max),
            /** @type {number} */
            [FIELDS.r_emc_max]: this.get(FIELDS.r_emc_max),
            /** @type {number} */
            [FIELDS.r_emc_min]: this.get(FIELDS.r_emc_min),
            /** @type {number} */
            [FIELDS.r_mrf]: this.get(FIELDS.r_mrf),
            /** @type {number} */
            [FIELDS.r_dc_span]: this.get(FIELDS.r_dc_span),
            /** @type {number} */
            [FIELDS.r_dc_min]: this.get(FIELDS.r_dc_min),
            /** @type {number} */
            [FIELDS.r_emc_mean]: this.get(FIELDS.r_emc_mean),
        };
    }
}
