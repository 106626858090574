<template>
    <ModalButton
        :theme="theme"
        :label="label"
        :busy="busy"
        :disabled="disabled"
        @click.capture.stop="onClicked"
    />
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';
    import { toRefs } from '@vueuse/shared';

    // <!-- UTILITIES -->
    import { Theme, ReportType } from '@/enums';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- TYPES -->
    /** @template [T=any] @typedef {import('vue').PropType<T>} PropType<T> */
    /** @template [T=any] @typedef {import('vue').Ref<T>} Ref<T> */
    /** @template [T=any] @typedef {import('vue').ComputedRef<T>} ComputedRef<T> */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DownloadReportButton',
        components: {
            ModalButton,
        },
        props: {
            /** Determine the report to download using the {@link ReportType} enum. */
            reportType: {
                /** @type {Vue.PropType<ReportType[keyof ReportType['_dictionary']]>} */
                // @ts-ignore
                type: String,
                required: true,
                validator: (reportType) =>
                    reportType === null ||
                    Object.values(ReportType).includes(
                        /** @type {ReportType[keyof ReportType['_dictionary']]} */ (
                            reportType
                        )
                    ),
            },
            /** Property used to specify the label text. Overriden when a slot is available. */
            label: {
                /** @type {Vue.PropType<String>} */
                type: String,
                default: 'Download Report',
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Determine the inner component themes using the {@link Theme} enum. */
            theme: {
                /** @type {Vue.PropType<Theme[keyof Theme['_dictionary']]>} */
                // @ts-ignore
                type: String,
                default: Theme.primary,
                validator: (theme) =>
                    theme === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme['_dictionary']]} */ (theme)
                    ),
            },
        },
        emits: ['download:click'],
        setup(props, { emit }) {
            // PROPS
            const { reportType } = toRefs(props);

            // EVENT LISTENERS
            /**
             * `click` event listener.
             * @param {MouseEvent} e
             */
            const onClicked = (e) => {
                emit('download:click', reportType.value);
            };

            // EXPOSE
            return {
                onClicked,
            };
        },
    });
</script>
