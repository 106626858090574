// <!-- TYPES -->

/**
 * @typedef {'T' | 'RH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC'} Metric
 * Indicates graph axis described within the dataset.
 */

/**
 * @typedef TemperatureDataset
 * @prop {'T'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, temperature: number ]>} data Dataset.
 * @prop {'F' | 'C'} [temperatureScale] Temperature scale that describes this data.
 */

/**
 * @typedef RelativeHumidityDataset
 * @prop {'RH'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, percentage: number ]>} data Dataset.
 */

/**
 * @typedef DewpointDataset
 * @prop {'DP'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, temperature: number ]>} data Dataset.
 * @prop {'F' | 'C'} [temperatureScale] Temperature scale that describes this data.
 */

/**
 * @typedef MoldDataset
 * @prop {'MOLD'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, moldRiskFactor: number ]>} data Dataset.
 */

/**
 * @typedef PreservationIndexDataset
 * @prop {'PI'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, value: number ]>} data Dataset.
 */

/**
 * @typedef TimeWeightedPreservationIndexDataset
 * @prop {'TWPI'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, weighted: number, value: number ]>} data Dataset.
 */

/**
 * @typedef DimensionalChangeDataset
 * @prop {'DC'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, percentage: number ]>} data Dataset.
 */

/**
 * @typedef EquilibriumMoistureContentDataset
 * @prop {'EMC'} metric Metadata associated with this dataset.
 * @prop {Array<readonly [ time: number, percentage: number ]>} data Dataset.
 */

/**
 * @typedef {TemperatureDataset
 * | RelativeHumidityDataset
 * | DewpointDataset
 * | MoldDataset
 * | PreservationIndexDataset
 * | TimeWeightedPreservationIndexDataset
 * | DimensionalChangeDataset
 * | EquilibriumMoistureContentDataset
 * } GraphDataset
 */

/**
 * Resource data collection.
 */
export class ResourceData {
    /**
     * Define "empty" instance.
     * @returns {GraphDataset}
     */
    static empty() {
        return {
            metric: null,
            data: [],
        };
    }

    /**
     * Define temperature dataset.
     * @param {TemperatureDataset['data']} data
     * @param {TemperatureDataset['temperatureScale']} [temperatureScale]
     * @returns {TemperatureDataset}
     */
    static fromTemperatureDataset = (data, temperatureScale = 'F') => ({
        data,
        metric: 'T',
        temperatureScale,
    });

    /**
     * Define relative humidity dataset.
     * @param {RelativeHumidityDataset['data']} data
     * @returns {RelativeHumidityDataset}
     */
    static fromRelativeHumidityDataset = (data) => ({
        data,
        metric: 'RH',
    });

    /**
     * Define dewpoint dataset.
     * @param {DewpointDataset['data']} data
     * @param {DewpointDataset['temperatureScale']} [temperatureScale]
     * @returns {DewpointDataset}
     */
    static fromDewpointDataset = (data, temperatureScale = 'F') => ({
        data,
        metric: 'DP',
        temperatureScale,
    });

    /**
     * Define mold dataset.
     * @param {MoldDataset['data']} data
     * @returns {MoldDataset}
     */
    static fromMoldDataset = (data) => ({
        data,
        metric: 'MOLD',
    });

    /**
     * Define preservation index dataset.
     * @param {PreservationIndexDataset['data']} data
     * @returns {PreservationIndexDataset}
     */
    static fromPreservationIndexDataset = (data) => ({
        data,
        metric: 'PI',
    });

    /**
     * Define time-weighted preservation index dataset.
     * @param {TimeWeightedPreservationIndexDataset['data']} data
     * @returns {TimeWeightedPreservationIndexDataset}
     */
    static fromTimeWeightedPreservationIndexDataset = (data) => ({
        data,
        metric: 'TWPI',
    });

    /**
     * Define dimensional change dataset.
     * @param {DimensionalChangeDataset['data']} data
     * @returns {DimensionalChangeDataset}
     */
    static fromDimensionalChangeDataset = (data) => ({
        data,
        metric: 'DC',
    });

    /**
     * Define equilibrium moisture content dataset.
     * @param {EquilibriumMoistureContentDataset['data']} data
     * @returns {EquilibriumMoistureContentDataset}
     */
    static fromEquilibriumMoistureContentDataset = (data) => ({
        data,
        metric: 'EMC',
    });
}

// <!-- DEFAULT -->
export default ResourceData;
