//TODO: Enhance with global types.
/**
 * @class
 * Support class for creating constant maps, records, and tuples.
 */
export class Constants {
    static get Map() {
        /**
         * Create a map from a set of entries.
         * @template {unknown} [Key=unknown]
         * @template {unknown} [Value=unknown]
         * @param {Readonly<Readonly<[ key: Key, value: Value ]>[]>} entries
         * @returns {Readonly<Map<Key,Value>>}
         */
        const fromEntries = (entries) => {
            const instance = new Map(entries);
            return Object.freeze(instance);
        };
        /**
         * Create a map from a record.
         * @template {string|symbol|number} [Key=string|symbol|number]
         * @template {unknown} [Value=unknown]
         * @param {Readonly<Record<Key,Value>>} record
         * @returns {Readonly<Map<Key,Value>>}
         */
        const fromRecord = (record) => {
            /** @type {Readonly<Readonly<[key: any, value: Value]>[]>} */
            const entries = Object.entries(record);
            return fromEntries(entries);
        };
        // RETURN factory functions.
        return {
            fromEntries,
            fromRecord,
        };
    }
    static get Record() {
        /**
         * Create a record from a set of entries.
         * @template {string | symbol | number} [Key=string | symbol | number]
         * @template {unknown} [Value=unknown]
         * @param {Readonly<Readonly<[ key: Key, value: Value ]>[]>} entries
         * @returns {Readonly<Record<Key,Value>>}
         */
        const fromEntries = (entries) => {
            /** @type {Record<string|symbol|number,Value>} */
            const instance = Object.fromEntries(entries);
            return Object.freeze(instance);
        };
        /**
         * Create a record from another record.
         * @template {string|symbol|number} [Key=string|symbol|number]
         * @template {unknown} [Value=unknown]
         * @param {Readonly<Record<Key,Value>>} record
         * @returns {Readonly<Record<Key,Value>>}
         */
        const fromRecord = (record) => {
            return Object.freeze(record);
        };
        // RETURN factory functions.
        return {
            fromEntries,
            fromRecord,
        };
    }
    static get Tuple() {
        /**
         * Create a tuple from a record's keys.
         * @template {string|symbol|number} [Key=string|symbol|number]
         * @template {unknown} [Value=unknown]
         * @param {Readonly<Record<Key,Value>>} record
         * @returns {Readonly<(keyof Record<Key,Value>)[]>}
         */
        const fromKeys = (record) => {
            /** @type {(keyof Record<any,Value>)[]} */
            const keys = Object.keys(record);
            return Object.freeze(keys);
        };
        /**
         * Create a tuple from a record's values.
         * @template {unknown} [Value=unknown]
         * @param {Readonly<Record<string|symbol|number,Value>>} record
         * @returns {Readonly<(Record<any,Value>[keyof Record<any,Value>])[]>}
         */
        const fromValues = (record) => {
            /** @type {Record<any,Value>[keyof Record<any,Value>][]} */
            const values = Object.values(record);
            return Object.freeze(values);
        };
        // RETURN factory functions.
        return {
            fromKeys,
            fromValues,
        };
    }
}
